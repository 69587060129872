/* body{ margin: 0; padding: 0; font-family: Muli; color: #000808; font-weight:400; }
h1,h2,h3,h4,h5,h6,h7,p{ margin:0; padding:0; font-size: inherit; font-weight: inherit; }
.clear{ clear: both }

::-webkit-scrollbar{
    width: 10px;
}
::-webkit-scrollbar-track{
    background: #7A7A7A;
}
::-webkit-scrollbar-thumb{
    background: #00ABAF;
}
::-webkit-scrollbar-thumb:hover{
    background: #BFEAEB;
} */
a:not([href]) {
  text-decoration: none;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: 1px solid #e2e2e2 !important;
  }
  .insrnceTbl table td:last-child {
    border-bottom: none !important;
  }
}

/* Menu page css */
/* .homeBg {
    min-height: 100vh;
} */
/* .homeBgIner {
    background: #f7f7f7;
    min-height: 100vh; 
} */
/* .MenuLeftUpr {
    background: #fff;
    min-height: 100vh;
    border-right: 2px solid #ececec;
} */
/* .webLogo {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 70px;
} */
/* .menuItems ul {
    padding-left: 0px;
}
.menuItems ul li {
    list-style-type: none;
    text-align: center;
    margin-bottom: 35px;
} */
/* .menuItems ul li a img {
    width: 42px;
    border: 1px solid #eceaea;
    border-radius: 8px !important;
    padding: 8px 8px;
} */
.menuActv img {
  border: none !important;
}
/* .menuItems ul li:nth-child(4) a img{
    padding: 10px 8px !important;
}
.menuItems ul li a {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    border-radius: 8px;
} */
/* .menuItems ul li a span {
    display: block;
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 12px;
} */
.menuItems ul li:last-child {
  margin-bottom: 0px !important;
  margin-top: 132px;
}
a.profilMenu img {
  background-color: #e1f7f7 !important;
  border: none !important;
}
a.menuActv {
  box-shadow: 0 8px 24px 0 rgb(0 171 175 / 32%);
}
/* End of Menu page css */

/* Home page css */
/* .descpCntntLft a {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    display: block;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
    padding: 15px 0px 18px;
}
.descpCntntLft a span {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    display: block;
    margin-top: 12px;
}
.descpInerRght {
    margin-left: 36px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
    border-left: 4px solid #EE5253;
}
.icd_num label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    margin-bottom: 6px;
}
.icd_num a {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
}
.diagnosNote img {
    width: 23px;
    cursor: pointer;
}
.diagnosAwrd img {
    width: 14px;
    cursor: pointer;
}
.diagnosBus img {
    width: 16px;
    cursor: pointer;
}
.diagnosNote span {
    color: #EE5253;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 8px;
}
.diagnosImg a {
    padding-right: 12px;
} */
.vh-section .descpCntntMain {
  padding-left: 70px;
  margin-bottom: 45px;
}
/* .addSpc {
    padding-left: 32px;
    padding-right: 32px;
}
.icd_num {
    margin-top: 18px;
}
.vsblSec {
    text-align: right;
}
.vsblEye img {
    width: 14px;
    cursor: pointer;
} */
.manageVsblEye .vsblEye {
  color: #7f8383 !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-decoration: none !important;
}
/* a.vsblTime img {
    width: 12px;
    cursor: pointer;
}
a.vsblDots img {
    width: 30px;
    cursor: pointer;
}
a.vsblTime {
    margin-left: 5px;
    margin-right: 18px;
}
.vsblEye span {
    vertical-align: middle;
}
.markCntntImg span, .markMDCntntImg span {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 10px;
}
.markCntntImg img {
    width: 16px;
    height: 16px;
    border-radius: 12px;
}
.markMDCntntImg img {
    width: 13px;
    height: 13px;
}
.markCntntMain {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 16px;
}
.detailLbl label {
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 0px;
}
.detailLbl {
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 5px 14px;
    margin-bottom: 16px;
}
.detailCntnt p {
    color: #404646;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}
.detailMark {
    margin-top: 16px;
    position: relative;
}
.detailLbl a img {
    width: 15px;
}
.detailLbl {
    position: relative;
}
.detailLbl a {
    position: absolute;
    right: 14px;
    top: 4px;
}
.imgsFile a img {
    width: 100%;
}
.imgsFile a {
    display: inline-block;
    cursor: pointer;
    margin-right: 25px;
    width: 120px;
    vertical-align: top;
}
.imgsFile label {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    display: block;
    word-break: break-all;
    margin-top: 8px;
    margin-bottom: 0px;
}
.diagnoBy label {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0px;
}
.diagnoBy span {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
}
.detailMark span.Collapsible__trigger {
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 8px 14px;
    margin-bottom: 16px;
    display: block;
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    cursor: pointer;
}
.detailMark .is-closed::after{
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    top: 9px;
    right: 16px;
    background-size: contain;
    background: url("../images/down2.png") no-repeat center center;
    cursor: pointer;
}
.detailMark .is-open::after{
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    top: 9px;
    right: 16px;
    background-size: contain;
    background: url("../images/arw1.png") no-repeat center center; 
    cursor: pointer;
}
.detailMark .Collapsible__contentInner {
    padding-left: 4px;
    padding-right: 4px;
}
.diagnoBy {
    margin-bottom: 5px !important;
}
.diryInerRght {
    margin-left: 36px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
    border-left: 4px solid #EE5253;
}
.diryImg a {
    padding-right: 12px;
}
.diryNote img {
    width: 23px;
    cursor: pointer;
}
.diryNote span {
    color: #EE5253;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 8px;
}
.hideSec {
    text-align: right;
}
.hideEye {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.hideEye img {
    width: 14px;
    cursor: pointer;
}
.hideEye span {
    vertical-align: middle;
}
a.hideDots img {
    width: 30px;
    cursor: pointer;
}
.diryNotes_hedng label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    margin-bottom: 6px;
}
.diryCntntMain {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 16px;
}
.diryCntntImg img {
    width: 16px;
    height: 16px;
    border-radius: 8px;
}
.diryCntntImg span, .diryMDCntntImg span {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 10px;
}
.diryMark {
    margin-top: 16px;
}
.diryNotes_hedng {
    margin-top: 18px;
}
.diryCntntSec p {
    color: #404646;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 8px;
}
.diryCntntSec h3 {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 14px;
}
.diryCntntSec ul li a {
    color: #404646 !important;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}
.diryCntntSec ul {
    padding-left: 27px;
    margin-bottom: 0px;
}
.diryCntntSec ul li {
    padding-left: 9px;
}
.blodPrsurImg a {
    padding-right: 12px;
}
.blodPrsurNote img {
    width: 23px;
    cursor: pointer;
}
.blodPrsurNote span {
    color: #00B0DB;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 8px;
}
.bp_vsblSec {
    text-align: right;
}
.bp_vsblEye {
    color: #7F8383 !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.bp_vsblEye img {
    width: 14px;
    cursor: pointer;
}
.bp_vsblEye span {
    vertical-align: middle;
}
.bp_vsblDots img {
    width: 30px;
    cursor: pointer;
}
.bp_hg {
    margin-top: 18px;
}
.bp_hg label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    margin-bottom: 6px;
}
.bp_hg p {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.bp_hg label span {
    font-size: 16px;
}
.bpJohnMain {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 16px;
}
.bpJohnImg img {
    width: 16px;
    height: 16px;
    border-radius: 12px;
}
.bpJohnImg span {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 10px;
}
.bloodPreBy label {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0px;
}
.bloodPreBy span {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
}
.bp_graph img {
    width: 100%;
}
.bp_graph {
    margin-top: 24px;
}
.bp_graph a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
}
.bp_graph {
    text-align: center;
}
.descpInerBlue {
    border-left: 4px solid #00B0DB;
} */
/* .menuActv img {
    border-radius: 9px;
    box-shadow: 0 8px 24px 0 rgba(0,171,175,0.32);
} */
/* .profileDescp{
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
} */
/* .profilePic img {
    height: 104px;
    width: 104px;
    border-radius: 16px;
    box-shadow: 0 24px 40px 0 rgba(0,8,8,0.08);
    margin-top: -34px;
} */
/* .profilePic {
    display: block;
    text-align: center;
} */
.profileDescp,
.drVisit,
.comeAppoint,
.persBlodMesur,
.bilingDocs,
.newStaffUpr {
  margin-bottom: 32px;
}
/* .profileName{
    text-align: center;
} */
/* .profileName label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 12px;
} */
/* .profileName p {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
}
.profileBtn a {
    border-radius: 8px;
    background-color: #E1F7F7;
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 9px 22px;
    display: inline-block;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
} */
.vh-section .prfilHghtLft label,
.vh-section .prfilHghtRght label {
  color: #00b0db;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
}
.vh-section .prfilHghtLft p,
.vh-section .prfilHghtRght p {
  color: #000808;
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0px !important;
}
.vh-section .prfilHghtLft p span,
.vh-section .prfilHghtRght p span {
  font-size: 14px;
  margin-left: 5px;
}
.vh-section .prfilHght {
  display: flex;
  border-top: 1px solid #f2f2f2;
}
.vh-section .prfilHght:first-child {
  border-top: none;
}
.vh-section .prfilHghtLft,
.vh-section .prfilHghtRght {
  width: 50%;
  padding: 14px 0px;
  padding-left: 18%;
  text-align: center;
}
.vh-section .prfilHghtLft {
  border-right: 1px solid #f2f2f2;
}
.vh-section .drVisit,
.vh-section .comeAppoint,
.vh-section .lstDocs,
.vh-section .persBlodMesur,
.bilingDocs {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 8, 8, 0.04);
  padding: 20px 26px;
}
/* .drVisitImg img {
    width: 100%;
    border-radius: 6px;
} */
.vh-section input:disabled {
  background: #f2f2f2 !important;
  color: #ccc !important;
}
.vh-section .newStaffUpr {
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  border-radius: 12px;
}
.vh-section .newStaffInfo {
  padding: 20px 16px;
  border-bottom: 1px solid #f2f2f2;
}
.vh-section .drVisitData label {
  color: #404646;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 10px;
}
.vh-section .drVisitData p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.vh-section .drVisitData {
  padding-left: 12px;
}
.vh-section .drVisit .MuiGrid-container {
  margin-bottom: 20px;
}
.vh-section .drVisit .MuiGrid-container:last-child {
  margin-bottom: 0px;
}
.vh-section .drVisit h3 {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 30px;
}
/* .upcomView label {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
} */
/* .upcomView a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 14px;
}
.allViewDots img {
    width: 30px;
    cursor: pointer;
}
.allViewDots {
    text-align: right;
}
.oficVisit label {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 0px;
    margin-right: 30px;
}
.oficVisit a {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.oficVisit img {
    width: 16px;
    vertical-align: middle;
    margin-right: 4px;
    border-radius: 10px;
}
.neuroSection h3 {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 10px;
    margin-bottom: 5px;
}
.neuroSection a {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.neuroSection img {
    width: 16px;
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 10px;
}
.oficVisit {
    margin-top: 12px;
}
.lstView label {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
}
.lstView a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 14px;
}
.lstViewDots {
    text-align: right;
}
.lstViewDots img {
    width: 30px;
    cursor: pointer;
}
.presSec h4 {
    color: #00B0DB;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.metroPro h5 {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 8px;
    margin-bottom: 4px;
}
.metroPrOpen {
    text-align: right;
}
.metroPrOpen a{
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
} */
/* .metroDoctor img {
    width: 16px;
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 10px;
}
.metroDoctor a {
    color: #7F8383 !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
a.presSecAncr {
    display: block;
    border-radius: 8px;
    padding: 12px 16px;
    margin-left: -14px;
    margin-right: -14px;
    cursor: pointer;
}
a.presSecAncr:hover {
    background-color: #FAFAFA;
}
.presSec {
    margin-top: 10px;
} */
.vh-section .journalAdd h1 {
  color: #000808;
  font-family: Muli;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
/* .AddEntrynw {
    text-align: right;
}
.AddEntrynw a {
    color: #FFFFFF !important;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    border-radius: 8px;
    background-color: #00ABAF;
    min-width: 139px;
    display: inline-block;
    padding: 16px 20px;
    cursor: pointer;
    text-decoration: none;
} */
.vh-section .journalAdd {
  margin-bottom: 24px;
}
a.bp_vsblDots,
a.hideDots {
  margin-left: 18px;
}
/* a.vsblDots, a.conPainDots, a.bp_vsblDots, a.hideDots {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 6px 3px 9px;
} */
/* .persBlodImg img, .allViewDots img, .lstViewDots img, .billImg img{
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 6px 5px 5px;
} */
.mdclStaffRght img,
.assignRght img {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 2px 2px 1px;
}
.vh-section .descpCntntMain {
  margin-bottom: 35px;
}
/* End of Home page css */

.vh-section .conPainImg a {
  padding-right: 12px;
}
.vh-section .conPainImg a:last-child {
  padding-right: 0px;
}
.vh-section .conPainNote img {
  width: 23px;
  cursor: pointer;
}
.vh-section .conPainNote span {
  color: #ee5253;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 8px;
}
/* .conPainAwrd img {
    width: 14px;
    cursor: pointer;
}
.conPainSec {
    text-align: right;
} */
/* .conPainEye {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 18px;
} */
/* .conPainEye img {
    width: 14px;
    cursor: pointer;
}
.conPainEye span {
    vertical-align: middle;
}
a.conPainTime {
    margin-left: 5px;
    margin-right: 18px;
}
a.conPainTime img {
    width: 12px;
    cursor: pointer;
}
.conPainDots img {
    width: 30px;
    cursor: pointer;
}
.conPain_num {
    margin-top: 18px;
}
.conPain_num label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    display: block;
    margin-bottom: 6px;
}
.conPain_num p {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
} */
/* .conPain_Cntnt {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin-top: 16px;
}
.conPain_Img img {
    width: 16px;
    height: 16px;
    border-radius: 12px;
}
.conPain_Img span, .conPain_MDCImg span {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 10px;
}
.conPain_MDCImg img {
    width: 13px;
    height: 13px;
}
.painTypeBy label {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0px;
} */
/* .painTypeBy span {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
}
.painTypeCntnt p {
    color: #404646;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}
.conPainGraph {
    margin-top: 18px;
}
.conPainArea label {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 14px;
}
.conPainLft img {
    width: 45px;
    margin-right: 22px;
} */
/* .painIntencty label, .condIntencty label{
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 0px;
}
.painIntencty a, .condIntencty a {
    color: #404646;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    display: block;
    margin-top: 14px;
    margin-bottom: 5px;
}
.painIntencty img, .condIntencty img {
    width: 24px;
    margin-right: 15px;
}
.painIntencty {
    margin-bottom: 5px;
} */

/* range pain Slide */
/* .painIntencty input[type=range] {
    height: 20px;
    -webkit-appearance: none;
    margin: 0px;
    width: 100%;
  }
  .painIntencty input[type=range]:focus {
    outline: none;
  } */
/* .painIntencty input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border-radius: 2px;
    border: 0px solid #000000;
  }
  .painIntencty input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #FFFFFF;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }
  .painIntencty input[type=range]:focus::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
  } */
/* .painIntencty input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border-radius: 2px;
    border: 0px solid #000000;
  }
  .painIntencty input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #FFFFFF;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
  }
  .painIntencty input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  .painIntencty input[type=range]::-ms-fill-lower {
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  } */
/* .painIntencty input[type=range]::-ms-fill-upper {
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }
  .painIntencty input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #FFFFFF;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
  } */
/* .painIntencty input[type=range]:focus::-ms-fill-lower {
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
  }
  .painIntencty input[type=range]:focus::-ms-fill-upper {
    background: linear-gradient(90deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
  }

  .condIntencty input[type=range] {
    height: 20px;
    -webkit-appearance: none;
    margin: 0px;
    width: 100%;
  } */
/* .condIntencty input[type=range]:focus {
    outline: none;
  }
  .condIntencty input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border-radius: 2px;
    border: 0px solid #000000;
  }
  .condIntencty input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #FFFFFF;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  } */
/* .condIntencty input[type=range]:focus::-webkit-slider-runnable-track {
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
  } */
/* .condIntencty input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border-radius: 2px;
    border: 0px solid #000000;
  } */
/* .condIntencty input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #FFFFFF;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
  }
  .condIntencty input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  .condIntencty input[type=range]::-ms-fill-lower {
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }
  .condIntencty input[type=range]::-ms-fill-upper {
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }
  .condIntencty input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid #FFFFFF;
    height: 12px;
    width: 12px;
    border-radius: 25px;
    background: #000000;
    cursor: pointer;
  }
  .condIntencty input[type=range]:focus::-ms-fill-lower {
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
  }
  .condIntencty input[type=range]:focus::-ms-fill-upper {
    background: linear-gradient(270deg, #12C238 0%, #72CA2E 18.92%, #FECA57 38.89%, #EEAA52 58.81%, #EE5253 78.46%, #EE5253 100%);
  } */

.persBlodImg,
.billImg {
  text-align: right;
}
.persBlodImg img,
.billImg img {
  width: 30px;
  cursor: pointer;
}
/* .persBlod label {
    color: #00B0DB;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}  */
/* .billLbl label {
    color: #000000;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}
.presureData h3 {
    color: #000808;
    font-family: Muli;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 0px;
}
.presureData h3 span {
    font-size: 14px;
} */
/* .presureData p {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 14px;
}
.presureData {
    margin-top: 5px;
}
.Personal_dash a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}
.Personal_dash img {
    width: 16px;
    margin-right: 10px;
    vertical-align: sub;
}
.Personal_dash {
    text-align: center;
    margin-bottom: 26px;
}
.presureDataGrph img {
    width: 100%;
}
.presureDataGrph {
    margin-left: -28px;
    margin-right: -28px;
} */
.vh-section .srchFilter {
  margin-bottom: 45px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 8, 8, 0.04);
  padding: 22px 20px 8px;
}
.vh-section .allTimeSel .css-yk16xz-control {
  border-radius: 8px !important;
  background-color: #f2f2f2 !important;
  border-color: transparent !important;
  font-family: "Mulish", sans-serif !important;
}
/* .allTimeSel span{
    width: 0px !important;
} */
/* .typeSel .css-yk16xz-control{
    border-radius: 8px !important;
    background-color: #E1F7F7 !important;
    color: #00ABAF !important;
    border-color: transparent !important;
    font-family: 'Mulish', sans-serif !important;
}
.typeSel .css-1wa3eu0-placeholder{
    color: #00ABAF !important;
}
.typeSel span{
    width: 0px !important;
}
.typeSel .css-6q0nyr-Svg{
    color: #00ABAF !important;
} */
.vh-section .comonSel {
  padding-right: 12px !important;
  font-size: 12px !important;
}
/* .clear_filterLft a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
}
.clear_filterRght img {
    height: 32px;
    width: 32px;
    cursor: pointer;
    border-radius: 5px;
}
.clear_filterUpr {
    display: flex;
    align-items: center;
}
.clear_filterLft, .clear_filterRght {
    width: 50%;
}
.clear_filterRght {
    text-align: right;
} */
/* .entrTimeBY {
    width: 75px;
    border-radius: 8px;
    background-color: #E1F7F7;
    outline: none;
    border: none;
    display: inline-block;
    color: #00ABAF;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    padding: 5px 3px;
    margin: 0px 10px;
}
.entrTimeBY::-webkit-input-placeholder {
    color: #00ABAF;
  }
.entrTimeBY::-moz-placeholder {
    color: #00ABAF;
  }
.entrTimeBY:-ms-input-placeholder {
    color: #00ABAF;
  }
.entrTimeBY:-moz-placeholder { 
    color: #00ABAF;
  } */
/* .diagTimeBY {
    width: 90px;
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    border: none;
    outline: none;
} */

/* Start of Home search */
.vh-section .srchFilter {
  margin-bottom: 45px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 8, 8, 0.04);
  padding: 22px 20px 8px;
}
.vh-section .allTimeSel .css-yk16xz-control {
  border-radius: 8px !important;
  background-color: #f2f2f2 !important;
  border-color: transparent !important;
  font-family: "Mulish", sans-serif !important;
}
/* .allTimeSel span{
    width: 0px !important;
}
.typeSel .css-yk16xz-control{
    border-radius: 8px !important;
    background-color: #E1F7F7 !important;
    color: #00ABAF !important;
    border-color: transparent !important;
    font-family: 'Mulish', sans-serif  !important;
}
.typeSel .css-1wa3eu0-placeholder{
    color: #00ABAF !important;
}
.typeSel span{
    width: 0px !important;
}
.typeSel .css-6q0nyr-Svg{
    color: #00ABAF !important;
} */
/* .vh-section .comonSel {
    padding-right: 12px !important;
    font-size: 12px !important;
} */
/* .clear_filterLft a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
} */
/* .clear_filterRght img {
    height: 32px;
    width: 32px;
    cursor: pointer;
    border-radius: 5px;
}
.clear_filterUpr {
    display: flex;
    align-items: center;
}
.clear_filterLft, .clear_filterRght {
    width: 50%;
}
.clear_filterRght {
    text-align: right;
} */
/* .sortBySec {
    border-top: 1px solid #F2F2F2;
    margin-top: 20px;
    margin-left: -6px;
    margin-right: -6px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: right;
}
.sortBySec label {
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 0px;
}
.entrTimeBY {
    width: 75px;
    border-radius: 8px;
    background-color: #E1F7F7;
    outline: none;
    border: none;
    display: inline-block;
    color: #00ABAF;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    padding: 5px 3px;
    margin: 0px 10px;
}
.entrTimeBY::-webkit-input-placeholder {
    color: #00ABAF;
  }
.entrTimeBY::-moz-placeholder {
    color: #00ABAF;
  }
.entrTimeBY:-ms-input-placeholder {
    color: #00ABAF;
  }
.entrTimeBY:-moz-placeholder { 
    color: #00ABAF;
  }
.diagTimeBY {
    width: 90px;
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    border: none;
    outline: none;
}
@media (min-width: 960px){
.faclity_all{
    max-width: 19% !important;
    flex-basis: 19% !important;
  }
  .clear_filter{
    flex-grow: 0;
    max-width: 27.666667% !important;
    flex-basis: 27.666667% !important;
  }
} */
/* End of Home Search */

/* Start tool tip css */
/* .timeIconClas, .icdtxtClas, .yesInfoClas{
        opacity: initial !important;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 8px 24px 0 rgba(0,8,8,0.08);
    }
    .timeIconClas label {
        color: #000808;
        font-family: Muli;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        display: block;
    }
    .timeIconClas p{
      color: #7F8383;
      font-family: Muli;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 12px;
      text-align: center;
    }
    .icdtxtClas h4 {
        color: #000808;
        font-family: Muli;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        margin-bottom: 0px;
    }
    .yesInfo img {
        width: 16px;
        margin-left: 6px;
        cursor: pointer;
        border-radius: 14px;
    }
    .yesInfoClas h4{
        color: #000808;
        font-family: Muli;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        margin-bottom: 10px;
    } */
/* .yesInfoClas p{
        color: #7F8383;
        font-family: Muli;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 12px;
    }
    .moreMenuList {
        display: none;
        min-width: 192px;
        position: absolute;
        left: 44px;
        top: -11px;
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,8,8,0.08);
        z-index: 999;
        padding: 8px 15px;
    } */
/* .moreMenu:hover .moreMenuList {
        display: block;
    }
    .moreMenuList li a {
        color: #404646;
        font-family: Muli;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        text-align: left;
    }
    .moreMenuList ul li {
        margin-bottom: 1px !important;
        text-align: left;
    }
    a.moreMenu {
        position: relative;
    }
    .moreMenuList li a img {
        width: 10px !important;
        margin-right: 10px;
        border-radius: 1px !important;
        border: none !important;
        background-color: transparent !important;
        padding: initial !important;
    }
    .moreMenuList li a:hover {
        color: #000808 !important;
    } */
/* .profilMenuList {
        display: none;
        min-width: 192px;
        position: absolute;
        left: 44px;
        top: -11px;
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 8px 16px 0 rgba(0,8,8,0.08);
        z-index: 999;
        padding: 8px 15px;
    } */
/* .profilMenu:hover .profilMenuList {
        display: block;
    }
    .profilMenuList li a {
        color: #404646;
        font-family: Muli;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        text-align: left;
    }
    .profilMenuList ul li {
        margin-bottom: 1px !important;
        text-align: left;
    }
    a.profilMenu {
        position: relative;
    }
    .profilMenuList li a img {
        width: 10px !important;
        margin-right: 10px;
        border-radius: 1px !important;
        border: none !important;
        background-color: transparent !important;
        padding: initial !important;
    }
    .profilMenuList li a:hover {
        color: #000808 !important;
    }
    .profilMenuList ul li:last-child {
        border-top: 1px solid #F2F2F2;
        margin-top: 10px;
        padding-top: 2px;
    } */
/* End of tool tip css */

/* .webItems svg{
    border: 1px solid #f2f2f2;
    padding: 10px 5px;
    width: 46px;
    height: 46px;
    border-radius: 10px;
} */
/* .getCalBnr {
    margin-bottom: 42px;
}
.MenuMobRght {
    text-align: right;
}
.MenuMobLeft {
    text-align: left;
}
.MenuMob {
    background: #fff;
    display: none;
    width: 100%;
} */
/* .filterMob {
    padding: 0px 14px;
    position: relative;
    margin-bottom: 32px;
}
.filterMob input {
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
    outline: none;
    border: none;
    width: 100%;
    padding: 14px 12px 14px 44px;
}
img.fltrMobIcon {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 16px;
    left: 25px;
}

.filterMob input::-webkit-input-placeholder {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }
.filterMob input::-moz-placeholder {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }
.filterMob input:-ms-input-placeholder {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
  }
.filterMob input:-moz-placeholder { 
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
}
span.srchMobIcon {
    border-left: 2px solid #F2F2F2;
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 0px;
    padding-left: 12px;
    min-height: 100%;
    bottom: 0px;
}
span.srchMobIcon img {
    margin-top: 9px;
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 8px 8px 6px;
}
.filterMob {
    display: none;
} */
/* responsive css */
@media (max-width: 959px) {
  /* .descpCntntLft a, .descpCntntLft2 a, .schdulTimLeft {
    width: 130px;
    margin: 0 auto;
    margin-bottom: 18px;
  } */
  /* .descpInerRght {
    margin-left: 0px !important;
  } */
  .vh-section .descpCntntMain {
    padding-left: 0px;
  }
  /* .MenuLeftUpr, .vh-section .srchFilter {
    display: none;
  }
  .MenuMob, .filterMob {
    display: block !important;
  }
  .menuItems ul li {
    text-align: left !important;
  }
  .menuItems ul li a span {
    display: inline-block !important;
  } */
  /* .profilMenuList {
    min-width: 130px !important;
    padding: 8px 10px !important;
  } */
  /* .descpInerRght2 {
    margin-left: 0px !important;
  } */
  /* .phrmOpinion {
    margin-left: 0px !important;
    padding: 0px 15px !important;
  } */
  /* .todaySrchRght {
    text-align: left !important;
  } */
  .todayMnth {
    margin-bottom: 12px !important;
  }
  .schdulTimRght {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .schdulTim {
    display: block !important;
  }
  /* .presurGraph {
    padding: 45px 15px !important;
  } */
  .latestGrph {
    padding: 38px 0px 38px 0px !important;
  }
  .contactListing a span {
    width: 100% !important;
  }
  .asignStaf {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .nuroTask {
    margin-bottom: 16px;
  }
  .nuroDr {
    margin-top: 16px;
  }
  .invoiceForm,
  .addCstmField {
    padding: 25px 20px !important;
  }
}
@media (max-width: 599px) {
  /* .descpInerRght {
   margin-left: 0px;
  }
  .descpCntntLft a span {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
    margin-left: 10px;
  }
  .AddEntrynw {
    text-align: center;
  } */
  /* .diagnosImg a {
    padding-right: 5px;
  }
  .conPainAwrd {
    padding-right: 0px;
  }
  .movMnth {
    margin: 0px !important;
  } */
  .vh-section .conPainNote span {
    margin-left: 4px;
  }
  .vh-section .conPainImg a {
    padding-right: 0px;
  }
  .vh-section .journalAdd h1 {
    text-align: center;
    margin-bottom: 24px;
  }
}
/* .fltrModelCntnt {
    width: 420px;
    background: #fff;
    margin: 0 auto;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 24px 32px 0 rgba(0,8,8,0.08);
    margin-top: 35px;
}
.fltrModelCourse {
    padding: 18px 20px 18px 20px;
    border-bottom: 1px solid #F2F2F2;
}
.dragfltrModel {
    padding: 18px 24px 18px 24px;
}
.fltrHdngLft a {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
}
.fltrHdngRght a {
    color: #00ABAF;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}
.fltrHdngRght {
    text-align: right;
}
.fltrHdngLft img {
    height: 20px;
    width: 20px;
    margin-right: 12px;
}
.allTimeSelMob {
    margin-bottom: 16px;
}
.allTimeSelMob .css-yk16xz-control {
    border-radius: 8px !important;
    background-color: #F2F2F2 !important;
    border-color: transparent !important;
    font-family: 'Mulish', sans-serif  !important;
}
.allTypSelMob .css-yk16xz-control {
    background-color: #E1F7F7 !important;
}
.allTypSelMob .css-1wa3eu0-placeholder, .allTypSelMob .css-1uccc91-singleValue {
    color: #00ABAF !important;
}
.srtByMob span {
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}
a.srtEvrTim {
    color: #00ABAF;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    border-radius: 8px;
    background-color: #E1F7F7;
    padding: 8px 12px;
    display: inline-block;
    margin: 0px 12px;
}
.srtByMob {
    border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2;
    text-align: center;
    padding: 18px 5px;
}
a.srtDiaTim {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
}
.aplyFltrMob input {
    border-radius: 8px;
    background-color: #00ABAF;
    outline: none;
    border: none;
    width: 100%;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 18px 5px;
    display: block;
}
.aplyFltrMob {
    padding: 19px 18px;
} */

/* Hemburger menu style css */
/* #page-wrap {
  text-align: center;
  overflow: auto;
}
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}
.bm-item:hover {
  color: white;
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-cross {
  background: #bdc3c7;
} */
/* .bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-item-list {
  color: #b8b7ad;
}
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
} */
/* End of Hemburger menu style css */
/* .bm-burger-bars {
    background: #00abaf;
    height: 12% !important;
    width: 24px;
    opacity: 0 !important;
}
.bm-burger-button {
    height: 23px !important;
    left: 21px !important;
    top: 27px !important;
}
.bm-burger-button button {
    outline: none !important;
}
.bm-overlay, .addCstmMenu {
    top: 0px !important;
}
.bm-item {
    outline: none;
}
.bm-menu {
    background-color: #FFFFFF !important;
    padding: 45px 15px 20px 25px !important;
}
.addCstmMenu {
    width: 215px !important;
}
.bm-cross-button {
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    padding: 12px 12px 12px;
    outline: none !important;
    right: 15px !important;
}
.bm-menu nav{
    border-top: 1px solid #dfdfdf;
    padding: 52px 15px 20px 25px !important;
}
.bm-menu{
    background-color: #FFFFFF !important;
    padding: 0px !important;
} */
/* .MenuMobRght{
    padding: 15px 15px;
} */
/* img.MenuImg{
    margin-left: 17px;
} */

a.menuActv img {
  padding: 0px !important;
  border: none;
}
.cmnLftSpc {
  padding-left: 5.5%;
  padding-right: 5.5%;
  padding-top: 40px;
  padding-bottom: 9%;
}
.addFlow h1 {
  color: #000808;
  font-family: Muli;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.addFlowRght a {
  border-radius: 8px;
  background-color: #00abaf;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-align: center;
  padding: 16px 10px;
  min-width: 156px;
  display: inline-block;
  cursor: pointer;
}
.addFlowRght {
  text-align: right;
}
.srchPatient {
  margin-top: 32px;
  margin-bottom: 0px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  width: 100%;
}
.srchLft {
  border-right: 1px solid #f2f2f2;
}
.srchLft input::before,
.srchLft input::after {
  content: initial;
}
.flowArea {
  border-radius: 12px;
  background-color: #f2f2f2;
  min-height: 100vh;
  margin-top: 32px;
  padding: 24px 26px;
}
.receLbl label {
  color: #404646;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px !important;
}
.addPatent {
  text-align: right;
}
.addNwPatnt {
  border-radius: 8px;
  background-color: #e5e6e6;
  font-family: Muli;
  padding: 12px 10px;
  min-width: 181px;
  display: inline-block;
  cursor: pointer;
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  margin-right: 15px;
  text-decoration: none !important;
}
.addPatent a {
  cursor: pointer;
}
.cardioArea {
  display: flex;
  align-items: center;
}
.cardioArea img {
  height: 32px;
  border-radius: 32px;
}
.cardioArea label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 5px;
  display: inline-block;
  font-family: Muli;
  line-height: initial !important;
}
.cardioArea p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.cardioArea a {
  display: inline-block;
  margin-right: 12px;
}
.cardioAreaUpr {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 15px 16px;
  margin-bottom: 18px;
}
.receLbl {
  margin-bottom: 18px;
}
.cardoLbl a {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  padding: 3px 8px;
  border-radius: 6px;
  cursor: pointer;
}
.cardoPink a {
  color: #ee5253 !important;
  background: #fbd4d4;
}
.cardoBlue a {
  color: #0076db !important;
  background: #cce8ff;
}
.cardoViolet a {
  color: #8c00db !important;
  background: #eccaff;
}
.cardoCology a {
  color: #00b0db !important;
  background: #b4ecf8;
}
.cardoRed a {
  color: #ee5253 !important;
  background: #fbd4d4;
}
a.rghtHalf img {
  height: 15px;
  margin-right: 6px;
}
a.rghtHalf span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
a.addSec {
  margin: 0px 14px;
}
.addSec img {
  height: 16px;
}
a.notePoint img {
  height: 16px;
  margin-right: 6px;
}
a.notePoint span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.infoDoc a {
  display: inline-block;
  text-decoration: none !important;
}
.cardioList a img {
  height: 24px;
  border-radius: 12px;
}
a.cardioCount {
  color: #7f8383;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  background-color: #f2f2f2;
  height: 24px;
  width: 24px;
  border-radius: 12px;
}
.cardioList a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.cardioDots a {
  border: 1px solid #eceaea;
  border-radius: 8px !important;
  padding: 12px 5px;
  display: inline-flex;
  cursor: pointer;
}
.cardioData {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardioList {
  margin-right: 25px;
  margin-left: 35px;
}
.receLblUpr {
  margin-bottom: 42px;
}
/* .addPatent a img, .cardioDots img {
    height: 28px;
} */
.cardioDots img {
  height: 28px;
}
.wardInfo a {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-right: 18px;
  text-decoration: none !important;
}
.wardInfo a:last-child {
  margin-right: 0px;
}
.wardNum img {
  height: 16px;
}
a.bedNum img {
  height: 12px;
}
.wardInfo img {
  margin-right: 5px;
  vertical-align: middle;
}
.wardInfo span {
  vertical-align: middle;
}
.srchLft input {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  width: 100% !important;
  padding: 24px 17px;
  font-family: "Mulish", sans-serif !important;
}
.srchLft div {
  width: 100%;
}
.srchLft div::before,
.srchLft div::after {
  content: initial;
}
.srchLft a {
  position: absolute;
  top: 21px;
  right: 18px;
}
.srchLft {
  position: relative;
}
.srchRght a {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
a.srchSort {
  background-color: #f2f2f2;
}
a.horzSort {
  background-color: #e1f7f7;
}
a.lineSort {
  border: 1px solid #eceaea;
}
.srchRght img {
  height: 15px;
}
.srchRght {
  text-align: right;
  padding: 15px 0px;
}
.allSpec {
  font-size: 12px !important;
  width: 136px;
  display: inline-block;
  font-family: "Mulish", sans-serif !important;
  text-align: left;
  margin-right: 8px;
  vertical-align: middle;
}
.srchRght a {
  vertical-align: middle;
  margin-right: 8px;
  border: 1px solid #cfcfcf;
}
.allSpec div {
  min-height: initial !important;
}

@media (max-width: 959px) {
  .srchRght {
    text-align: left !important;
    padding: 0px 0px 16px 17px !important; 
  }
  .srchLft {
    border-right: none !important;
  }
  .cmnLftSpc {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0px;
  }
  .cardoLbl {
    margin: 15px 0px 12px !important;
  }
  .cardioData {
    justify-content: flex-start !important;
    margin-top: 10px !important;
  }
}
@media (max-width: 559px) {
  .addFlowRght {
    text-align: left !important;
    margin-top: 24px !important;
  }
  .wardInfo {
    margin-bottom: 10px !important;
  }
  .addPatent {
    text-align: left;
    margin-top: 12px !important;
  }
}
@media (max-width: 468px) {
  .cardioData {
    display: flex;
    justify-content: space-between !important;
    margin-top: 0px !important;
  }
  .cardioList {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cardioGrupBtn button {
    margin-bottom: 14px;
  }
}
/* ------------- */
.tskTabs {
  margin-top: 45px;
}
.asignStaf {
  margin-top: 45px;
  margin-left: 54px;
  margin-right: 40px;
}
.backFlow img {
  transform: rotateZ(180deg);
  vertical-align: sub;
  margin-right: 12px;
}
.backFlow a {
  color: #00abaf !important;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  cursor: pointer;
}
.backFlow {
  margin-bottom: 42px;
}
.yearSec label {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 0px;
}
/* .yearSec label:first-child {
  margin-right: 22px;
} */
.yearOpen a {
  color: #00abaf !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
}
.yearOpen {
  text-align: right;
}
.issuePrice label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 0px;
  position: relative;
}
.isuLbl:before {
  height: 8px;
  width: 8px;
  background-color: #feca57;
  position: absolute;
  top: 5px;
  left: -16px;
  content: "";
  border-radius: 12px;
}
.isuLbl {
  margin-right: 20px;
}
.issuePrice {
  padding-left: 17px;
}
.yearSecBg {
  margin-top: 8px;
  display: block;
  margin-left: -8px;
  margin-right: -8px;
  padding: 8px 6px;
  border-radius: 6px;
}
.yearSecBg:hover {
  background-color: #fafafa;
}
.yearSec {
  margin-bottom: 3px;
}
.newStaff p {
  text-align: center;
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.newStaff a img {
  border-radius: 15px;
  box-shadow: 0 24px 40px 0 rgb(0 8 8 / 8%);
  width: 104px;
}
.newStaff a {
  display: block;
  text-align: center;
  margin-top: 17px;
}
.newStaff p span {
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}
.newStaff label {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  display: block;
  margin-top: 12px;
  margin-bottom: 10px;
}
.mdclStaffLft label,
.cmpleteTask label,
.assignTo label {
  color: #00b0db;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 8px;
}
.mdclStaffRght,
.assignRght {
  text-align: right;
}
.mdclMembrs a img {
  height: 32px;
  width: 32px;
  border-radius: 23px;
}
.mdclMembrs a {
  display: inline-block;
  margin-right: 3px;
}
.mdclMembrs a:last-child {
  margin-right: 0px !important;
  color: #00abaf !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  height: 36px;
  width: 36px;
  border: 2px solid #ffffff;
  background-color: #e1f7f7;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
}
.mdclMembrs a {
  display: inline-flex;
  margin-right: 3px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
}
.mdclMembrs {
  margin-top: 14px;
}
.mdclStaff,
.cmpleteTask {
  padding: 16px 18px;
  width: 100%;
}
.assignTo {
  padding: 16px 16px;
  width: 100%;
  border-left: 1px solid #f2f2f2;
}
.mdclStaff {
  border-bottom: 1px solid #f2f2f2;
}
.docsFile {
  border-top: 1px solid #f2f2f2;
}
.entrsSec {
  border-left: 1px solid #f2f2f2;
}
.cmpleteTask p {
  color: #000808;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 32px;
}
.cmpleteTask p span {
  font-size: 24px;
}
.docsFile p {
  font-size: 24px !important;
  letter-spacing: initial !important;
}
.NeuroBtn button {
  border-radius: 4px;
  background-color: #eccaff !important;
  box-shadow: initial !important;
  color: #8c00db;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: capitalize;
  padding: 9px 12px;
}
.NeuroBtn {
  margin-top: 15px;
}
.exmBtn a {
  color: #404646 !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  cursor: pointer;
}
.exmBtn img {
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 5px;
}
.roomsNum ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.roomsNum ul li {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  list-style-type: none;
  margin-bottom: 11px;
  position: relative;
}
.roomsNum ul li:nth-child(2) {
  padding-left: 12px;
}
.roomsNum ul li:nth-child(3) {
  padding-left: 30px;
}
.roomsNum ul li:last-child {
  margin-bottom: 0px !important;
}
.roomsNum ul li::before {
  content: "";
  background-image: url("../images/afterBg.jpg");
  background-size: contain;
  height: 18px;
  width: 10px;
  position: absolute;
  top: 22px;
  left: 6px;
  background-repeat: no-repeat;
}
.roomsNum ul li:nth-child(2)::before {
  left: 21px !important;
}
.roomsNum ul li:last-child::before {
  content: initial !important;
}
.roomsNum ul li img {
  width: 16px;
  height: auto;
  margin-right: 8px;
}
.roomsNum {
  margin: 17px 0px 16px;
}
.tskTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.tskTabs button {
  border-radius: 12px !important;
  background-color: #f2f2f2 !important;
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  opacity: initial !important;
  width: initial !important;
  min-width: initial !important;
  margin-right: 26px;
  min-height: 62px;
  padding-left: 18px;
  padding-right: 18px;
}
.tsktabIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
}
.tskTabs .MuiTabs-indicator {
  background-color: transparent !important;
}
.tskTabs {
  margin-bottom: 21px;
}
.entryInfo ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.entryInfo {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 14px;
}
.entryInfo li {
  list-style-type: none;
  display: table-cell;
  width: 25%;
  cursor: pointer;
}
.entryInfo li label {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 12px;
  margin-bottom: 0px !important;
  display: block;
}
.entryInfo li img {
  height: 35px;
  display: inline-block;
  border-radius: 8px;
  padding: 7px;
  border: 1px #cfcfcf solid;
}
.entryInfoActv img {
  box-shadow: 0 8px 24px 0 rgb(0 171 175 / 32%);
  background-color: #00abaf !important;
  border: 1px #00abaf solid !important;
}
.entryInfoActv label {
  color: #00abaf !important;
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .archvOpinionIner td.pivoted {
    padding: 10px !important;
  }
  .billInfoData td {
    padding: 10px !important;
  }
  .billInfoData th {
    padding: 10px !important;
  }
  .taskNum {
    min-width: 100px !important;
  }
}
@media (max-width: 1200px) {
  .asignStaf {
    margin-left: 10px;
    margin-right: 10px;
  }
  .descpInerRght {
    margin-left: 3px;
  }
  .vh-section .srchFilter {
    padding: 15px 10px 8px;
  }
}

/* Patient task css */
/* .presPkgIner1 header.presTabsUpr {
    padding: 12px 12px 0px 12px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
} */
.presPkgIner1 {
  margin-bottom: 28px;
}
.vh-section .presSrch {
  text-align: right;
}
.vh-section .presSrch a {
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 4px 7px;
  margin-top: 2px;
  display: inline-block;
  cursor: pointer;
  width: 32px;
  text-align: center;
}
.vh-section .presSrch a:last-child {
  margin-left: 12px;
}
.vh-section .presOpinionIner {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 8, 8, 0.04);
}
.vh-section .presTabs {
  background-color: #fff !important;
}
.vh-section button.presTabsIner {
  min-width: initial !important;
  outline: none !important;
  padding-bottom: 17px;
  opacity: initial !important;
}
.vh-section .presTabs button span {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: initial !important;
}
.vh-section .presTabs span.MuiTabs-indicator {
  background-color: #00abaf !important;
  height: 4px;
}
.vh-section .presTabs .Mui-selected span {
  color: #00abaf !important;
}
.vh-section .presSrch img {
  height: 17px;
}
.taskAllTab {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 22px 22px;
}
.neuroList {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 14px 16px;
  margin-bottom: 18px;
}
.neuroList:last-child {
  margin-bottom: 0px !important;
}
.nuroTask {
  display: flex;
  align-items: center;
}
.nuroTask img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.nuroTask label {
  color: #8c00db !important;
  background: #eccaff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  padding: 3px 8px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 7px;
  font-family: Muli;
}
.nuroTask p {
  color: #000808;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
}
.nuroTaskRght {
  margin-left: 14px;
}
.noteLink img {
  height: 14px;
  vertical-align: sub;
  margin-right: 8px;
}
.noteLink {
  display: flex;
  justify-content: space-around;
}
.noteLink a {
  color: #404646 !important;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.nuropenBtn {
  text-align: center;
}
.nuropenBtn button {
  border-radius: 4px;
  background-color: #fff7e6;
  color: #c58904;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
  font-family: Muli;
  padding: 9px 12px;
}
span.nuroCrcle {
  height: 8px;
  width: 8px;
  background-color: #feca57;
  border-radius: 12px;
  margin-right: 5px;
}
.nuroDr {
  display: flow-root;
  justify-content: space-around;
  align-items: center;
}
.nuroDr a:first-child img {
  height: 24px;
  border-radius: 12px;
}
.nuroDr a:last-child {
  border: 1px solid #eceaea;
  border-radius: 8px !important;
  padding: 1px 4px;
  cursor: pointer;
}
.nuroDr a:last-child img {
  width: 18px;
}
.nuroTaskDisable p {
  text-decoration: line-through;
  color: #7f8383 !important;
}
.nuroTaskDisable label {
  background-color: #f2f2f2 !important;
  color: #7f8383 !important;
}
.nuroTaskDisable button {
  color: #028b66;
  background-color: #ddf8f1 !important;
}
.nuroTaskDisable span.nuroCrcle {
  background-color: #1dd1a1 !important;
}
/* End of Patient task css */

/* Documents and files */
.vh-section .presOpinionIner {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 8, 8, 0.04);
}
.vh-section .presOpinionIner thead th {
  padding: 16px 25px;
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.vh-section .presOpinionIner tbody tr,
.vh-section .presOpinionIner thead tr {
  border-bottom: 1px solid #f2f2f2;
}
.vh-section .presOpinionIner td.pivoted {
  padding: 14px 20px;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.vh-section .presOpinionIner tr .presImg img {
  width: 16px;
  border-radius: 12px;
  vertical-align: middle;
  margin-right: 10px;
}
.vh-section .presOpinionIner td.presEditDot img {
  border: 1px solid #eceaea;
  border-radius: 8px;
  padding: 11px 5px;
  display: inline-flex;
  cursor: pointer;
  width: 27px;
}
td.presEditDot {
  text-align: right;
}
.docsTitle img {
  margin-right: 8px;
  vertical-align: middle;
}
.dateAdd {
  color: #00abaf !important;
  position: relative;
}
.dateAdd:after {
  content: "";
  height: 11px;
  width: 12px;
  position: absolute;
  right: 5px;
  top: 15px;
  background-size: contain;
  background: url("../images/downArow.png") no-repeat center center;
}
/* End of Documents and Files */

/* personal information */
.vh-section .profilePkgIner2 {
  padding: 22px 28px 30px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
/* .profileInfo h1 {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
} */
/* .profileInfo p {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
}
.profilePkg {
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 8px 0 rgba(0,8,8,0.04);
    margin-left: 70px;
    margin-top: 41px;
}
.profileId {
    border-radius: 12px;
    background-color: #FAFAFA;
}
.profileId label {
    margin-bottom: 0px;
}
.profileId {
    border-radius: 12px;
    background-color: #FAFAFA;
    padding: 10px 15px;
    margin: 0px 0px 18px;
}
.profileIdLft label {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: 12px;
}
.profileIdLft span {
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: 14px;
}
.profileIdLft a {
    margin-right: 15px;
}
.profileIdRght a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    cursor: pointer;
}
.profileIdRght {
    text-align: right;
} */
.vh-section .profileInfoIner label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.vh-section .profileInfoIner input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  width: 100%;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
/* .clear{
    clear: both;
}
.vh-section .profileInfoIner {
    margin-bottom: 10px;
}
.mr_sel .css-yk16xz-control {
    height: 36px !important;
    min-height: initial;
}
span.css-1okebmr-indicatorSeparator {
    background-color: transparent;
}
.mr_sel .css-yk16xz-control div {
    outline: 1px !important;
    border: none !important;
    min-height: initial !important;
} */
/* @media (min-width: 960px){
.titleDegre .MuiGrid-grid-md-4 {
    max-width: 37.333333%;
    flex-basis: 37.333333%;
 }
}
.profileInfoDate .react-date-picker__wrapper {
    border: 1px solid #BFC1C1;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 3px 4px;
    outline: none;
    width: 100%;
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
}
.profileInfoDate {
    margin-bottom: 10px;
}
.profileInfoDate label {
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.profileInfoDate button {
    outline: none !important;
} */
/* .profileInfoDate a {
    border-radius: 8px;
    background-color: #F2F2F2;
    color: #7F8383 !important;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    padding: 10px 12px;
    margin-right: 8px;
    cursor: pointer;
    width: 26%;
    display: inline-block;
} */
/* .profileInfoDate a:first-child {
    background-color: #00ABAF;
    color: #FFFFFF !important;
} */
/* .profileInfoDate a:hover {
    background-color: #00ABAF;
    color: #FFFFFF !important;
} */
/* .cntryDrop .css-yk16xz-control {
    height: 36px !important;
    min-height: initial;
} */
.vh-section .profileInfoIner input {
  min-height: initial !important;
  width: 100% !important;
  height: initial !important;
  line-height: initial !important;
}
.vh-section .profileInfoIner .form-control:focus {
  box-shadow: none !important;
}
.vh-section .insrnceTbl h3 {
  color: #000808;
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 17px;
}
.vh-section .insrnceTbl table th {
  border-top: none;
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  border-bottom: 2px solid #f3f3f3;
}
.vh-section .insrnceTbl table th:last-child {
  text-align: right;
}
.vh-section .insrnceTbl table td:last-child {
  text-align: right;
}
.vh-section .insrnceTbl table th,
.vh-section .insrnceTbl table td {
  padding: 0.75rem;
}
.vh-section .insrnceTbl img {
  height: 24px;
  cursor: pointer;
  border-radius: 5px;
}
.vh-section .insrnceTbl table td {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  border-bottom: 1px solid #f1f1f1;
}
.vh-section .insrnceTbl {
  margin-top: 32px;
  margin-bottom: 28px;
}
.infoSub1 {
  border-radius: 8px;
  background-color: #00abaf;
  border: none;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  /* width: 100%; */
  outline: none;
  margin-left: 10px;
  margin-right: 10px;
}

/* .infoSub input {
    border-radius: 8px;
    background-color: #00ABAF;
    border: none;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    width: 100%;
    padding: 13px 12px;
    outline: none;
}
.profileIdLft img {
    cursor: pointer;
}
.profileTabs {
    background-color: #fff !important;
}
.profileTabsUpr {
    box-shadow: none !important;
} */
/* .profileTabs button span {
    color: #7F8383;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
    text-transform: initial !important;
}
button.Mui-selected span {
    color: #00ABAF;
}
.profileTabs span.MuiTabs-indicator {
    background-color: #00ABAF !important;
    height: 4px;
}
button.aboutTabsIner {
    min-width: initial !important;
    outline: none !important;
} */
/* .qrBoxCntnt {
    width: 420px;
    background: #fff;
    margin: 0 auto;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 24px 32px 0 rgba(0,8,8,0.08);
    box-shadow: 0 24px 32px 0 rgba(0,8,8,0.08);
    margin-top: 35px;
}
.qrCourse {
    padding: 18px 30px 12px 30px;
    border-bottom: 1px solid #F2F2F2;
}
.qrCloseBtn {
    text-align: right;
    margin-bottom: 4px;
}
.qrCloseBtn img {
    width: 26px;
    cursor: pointer;
}
.qrCourse label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 2px;
}
.qrCourse p {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
} */
/* .qrCourseImg {
    text-align: center;
    padding: 34px 30px 28px 30px;
}
.qrCourseImg img {
    height: 159px;
}
.qrCourseImg input {
    outline: none;
    border-radius: 8px;
    background-color: #00ABAF;
    border: none;
    width: 100%;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 16px 0px;
    cursor: pointer;
    margin-top: 40px;
} */
/* .editBoxCntnt {
    width: 420px;
    background: #fff;
    margin: 0 auto;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0 24px 32px 0 rgba(0,8,8,0.08);
    margin-top: 35px;
}
.editCourse {
    padding: 18px 30px 12px 30px;
    border-bottom: 1px solid #F2F2F2;
}
.editCloseBtn {
    text-align: right;
    margin-bottom: 4px;
}
.editCloseBtn img {
    width: 26px;
    cursor: pointer;
} */
/* .editCourse label {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 2px;
}
.editCourse p {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
} */
/* .editPinform {
    padding: 18px 30px 20px 30px;
}
.editPinform label {
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.editPinform input {
    border: 1px solid #BFC1C1;
    border-radius: 4px;
    background-color: #FFFFFF;
    width: 100%;
    padding: 6px 12px;
    outline: none;
} */
/* .editPinform p {
    color: #EE5253;
    font-family: Muli;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 6px;
}
.editField {
    margin-bottom: 8px;
}
.editPinform input[type="submit"] {
    border-radius: 8px;
    background-color: #00ABAF;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    padding: 13px 0px;
    outline: none !important;
    margin-top: 12px;
}
.profilePkgIner1 {
    padding: 22px 28px 0px 28px;
    border-bottom: 1px solid #F2F2F2;
} */
/* .profileInfoDate .react-date-picker {
    display: block !important;
}
.chngPasswrd h2 {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
}
.chngPasswrd p {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
}
.genPass label {
    color: #404646;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 12px;
}
.genPass input[type="password"] {
    border: 1px solid #BFC1C1;
    border-radius: 4px;
    background-color: #FFFFFF;
    width: 100%;
    padding: 7px 4px;
    outline: none;
    color: #000808;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
}
.genPass input[type="submit"] {
    border-radius: 8px;
    background-color: #00ABAF;
    border: none;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    width: 100%;
    padding: 13px 12px;
    outline: none;
    margin-top: 10px;
}
.chngPasswrd {
    margin-bottom: 22px;
}
.genPassInr {
    margin-bottom: 10px;
} */
/* .factorAuth h3 {
    color: #000808;
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
}
.factorAuth p {
    color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
}
.factorAuth {
    margin-top: 50px;
    margin-bottom: 30px;
} */
/* .factorAuthEnbl h4 {
    color: #404646;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
    margin-bottom: 21px;
}
.factorAuthEnbl input[type="submit"] {
    border-radius: 8px;
    background-color: #00ABAF;
    border: none;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    width: 100%;
    padding: 13px 12px;
    outline: none;
    margin-top: 0px;
} */
/* .twofactorAuth {
    margin-bottom: 132px;
} */
/* End of personal information */

.roomMgntLft h1 {
  color: #000808;
  font-family: Muli;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.roomMgntRght span {
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
}
.roomMgntRght img {
  vertical-align: middle;
  margin-right: 8px;
}
.roomMgntRght {
  text-align: right;
}
.roomMgntRght span:first-child {
  margin-right: 25px;
}
.neuroRoom {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 0px 20px;
  margin-top: 36px;
  margin-bottom: 30px;
}
.neuroRoom ul {
  padding-left: 0px;
  margin-bottom: 0px;
  display: flex;
}
.neuroRoom ul li {
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  list-style-type: none;
  margin-right: 35px !important;
  position: relative;
  padding: 18px 0px 15px;
}
.neuroRoom ul li:last-child {
  margin-right: 0px !important;
}
.neuroRoom ul li:last-child::after {
  content: initial !important;
}
.neuroRoom ul li::after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: 19px;
  right: -25px;
  background-size: contain;
  background: url("../images/Right.svg") no-repeat center center;
  cursor: pointer;
}
li.neuroRoomActv {
  border-bottom: 4px solid #00abaf;
  color: #00abaf !important;
}
.drList {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding: 18px 18px;
}
.roomsList {
  border-radius: 12px;
  background-color: #f2f2f2;
  min-height: 358px;
  padding: 28px 28px;
}
.roomNum button {
  border-radius: 4px;
  background-color: #eccaff;
  color: #8c00db;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: capitalize;
  box-shadow: initial !important;
  padding: 9px 18px;
}
.drListRght img {
  width: 35px;
  height: 35px;
  border-radius: 28px;
  margin-right: 10px;
}
.roomNum {
  margin-bottom: 32px;
}
.drListLft span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  display: inline;
  vertical-align: super;
  margin-left: 8px;
}
.drListRght label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  margin-bottom: 0px;
}
.drListLft img {
  width: 18px;
  height: initial !important;
}
.drListRght p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.drRghtIner {
  display: flex;
  align-items: center;
}
.drListMain {
  display: flex;
  margin-bottom: 8px;
}
.drListMain:last-child {
  margin-bottom: 0px !important;
}
.drListMainOpct {
  opacity: 0.6;
}
.drListMain:hover {
  opacity: initial !important;
}
.drListLft {
  width: 25%;
  padding-top: 10px;
}
.drListRght {
  width: 75%;
  padding: 6px 6px;
  border-radius: 4px;
}
.drListRght:hover {
  background-color: #f9f2fd;
}
@media (max-width: 599px) {
  .roomMgntRght {
    text-align: left !important;
    margin-top: 18px;
  }
}
.movHereBtn button {
  border-radius: 6px;
  background-color: #e1f7f7;
  box-shadow: initial;
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  text-transform: initial;
  font-family: Muli;
  padding: 15px 10px;
  width: 100%;
  opacity: initial !important;
}
.movBtnUpr:hover {
  background-color: transparent !important;
}
.movHereBtn button:hover {
  background-color: #daf5f5;
  box-shadow: initial !important;
}

/* Rooms Bed css */
.spcMgnt h1 {
  color: #000808;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.topLeftSpc {
  margin-top: 35px;
  padding-left: 35px;
  padding-right: 35px;
}
.wardsGrup {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.wardsGrup button {
  border-radius: 4px;
  background-color: #fbd4d4;
  color: #ee5253;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  box-shadow: initial !important;
  font-family: Muli;
  text-transform: capitalize;
  padding: 8px 11px;
}
.wardsGrupUpr {
  border-radius: 12px;
  background-color: #f2f2f2;
  padding: 32px 24px;
}
.spcMgntRght {
  text-align: right;
}
.spcMgntRght a {
  border: 1px solid #eceaea;
  border-radius: 8px !important;
  padding: 12px 5px;
  display: inline-flex;
  cursor: pointer;
}
.spcMgntRght a img {
  width: 18px;
}
.roomsNum2 ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.roomsNum2 ul li {
  color: #404646;
  font-family: Muli;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  list-style-type: none;
  margin-bottom: 14px;
  position: relative;
}
.roomsNum2 ul li img {
  width: 16px;
  height: auto;
  margin-right: 8px;
}
.roomsNum2 ul li span {
  color: #000808;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
  padding-left: 24px;
  margin-top: 3px;
}
.roomsNum2 ul li:first-child {
  padding-left: 0px;
  font-size: 14px;
  font-weight: bold;
}
.roomsNum2 ul li:first-child img {
  width: 20px;
}
.roomsNum2 ul li:first-child::after {
  content: "";
  background-image: url("../images/rightArrow.png");
  background-size: contain;
  height: 12px;
  width: 9px;
  position: absolute;
  top: 0px;
  left: initial !important;
  right: 6px !important;
  background-repeat: no-repeat;
}
.roomsNum2 ul li:last-child {
  margin-bottom: 0px;
  font-size: 12px;
}
.roomsNum2 ul li:last-child::before {
  border-left: none;
  height: initial;
  content: "";
}
.roomsNum2 ul li:nth-last-child(2)::before {
  height: initial;
  border-left: none;
}
.roomsNum2 ul li::before {
  content: "";
  height: 42px;
  width: 10px;
  position: absolute;
  top: 32px;
  left: 8px;
  background-repeat: no-repeat;
  color: #d8d8d8;
  border-left: 1px solid #d8d8d8;
}
.roomsNum2 ul li::after {
  content: "-";
  position: absolute;
  top: 0px;
  left: 8px;
  background-repeat: no-repeat;
  color: #d8d8d8;
}
.roomsNum2 {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 20px;
}
.spcMgntUpr,
.roomsNum2 {
  padding: 18px 18px;
}
button.radiologyBtn {
  background-color: #cce8ff;
  color: #0076db;
}
button.neurologyBtn {
  background-color: #eccaff;
  color: #8c00db;
}
button.onlogyBtn {
  background-color: #b4ecf8;
  color: #00b0db;
}
.settingInfo a {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: initial;
}
.settingInfo img {
  height: 16px;
}
.settingInfo {
  text-align: right;
}
.settingInfo a {
  margin-left: 6px;
}
@media screen and (max-width: 959px) {
  .topLeftSpc {
    padding-left: 10px;
    padding-right: 10px;
  }
  .wardsGrupUpr {
    padding: 18px 18px;
  }
  .settingInfo {
    text-align: left;
    margin-top: 42px;
    margin-bottom: 20px;
  }
  .settingInfoMob {
    margin-top: 0px !important;
    text-align: right !important;
  }
  .roomBreadCrumb ul li {
    display: none !important;
  }
  .roomBreadCrumb ul li:first-child {
    display: block !important;
  }
  .instituteInfo a {
    margin: 0px 4px;
  }
}
/* rooms beds rooms */
.breadCrumbUpr {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 14px 21px;
  margin: 25px 0px 30px;
}
.roomBreadCrumb ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.roomBreadCrumb ul li {
  list-style-type: none;
  margin-bottom: 0px;
  display: inline-block;
  padding-right: 45px;
  position: relative;
}
.roomBreadCrumb span {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
}
.roomBreadCrumb ul li label {
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
  position: relative;
}
.roomBreadCrumb ul li:last-child label::after {
  height: 4px;
  width: 100%;
  background-color: #00abaf;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -17px;
  content: "";
}
.roomBreadCrumb ul li::after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: 5px;
  right: 16px;
  background-size: contain;
  background: url("../../assets/images/Right.svg") no-repeat center center;
  cursor: pointer;
}
.roomBreadCrumb ul li:last-child {
  padding-right: 0px !important;
}
.roomBreadCrumb ul li:last-child::after {
  content: initial !important;
}
.roomBreadCrumb li:last-child a label {
  color: #00abaf;
}

.drList2 {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding-bottom: 16px;
  min-height: 300px;
}
.roomsList2 {
  border-radius: 12px;
  background-color: #f2f2f2;
  min-height: 358px;
  padding: 28px 28px;
}
.roomNum2 button {
  border-radius: 4px;
  background-color: #eccaff;
  color: #8c00db;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: capitalize;
  box-shadow: initial !important;
  padding: 8px 15px;
}
.drListRght2 img {
  width: 35px;
  height: 35px;
  border-radius: 28px;
  margin-right: 10px;
}
.roomNum2 {
  padding: 18px 18px;
}
.drListLft2 span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  display: inline;
  vertical-align: super;
  margin-left: 8px;
}
.drListRght2 label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  margin-bottom: 0px;
}
.drListLft2 img {
  width: 18px;
  height: 20px !important;
}
.drListRght2 p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.drRghtIner2 {
  display: flex;
  align-items: center;
  position: relative;
}
.drListMain2 {
  display: flex;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 8px 8px;
  margin-bottom: 8px;
  width: 100%;
}
.drListMain2:last-child {
  margin-bottom: 0px !important;
}
/* .drListMain2:hover {
    opacity: initial !important;
} */
.drListLft2 {
  width: 25%;
}
.drListRght2 {
  width: 75%;
  border-radius: 4px;
}
/* .drListMain2:hover {
    background-color: #f9f2fd;
} */
@media (max-width: 599px) {
  .roomMgntRght2 {
    text-align: left !important;
    margin-top: 18px;
  }
}
.drRmv {
  position: absolute;
  right: -11px;
  top: 5px;
  /* display: none; */
}
.drRmv img {
  height: initial !important;
  cursor: pointer;
  margin-right: 0px;
  width: 100%;
}
/* .drListMain2:hover .drRmv {
    display: block !important;
} */
.bedArnge {
  text-align: right;
}
.bedArnge a {
  border: 1px solid #eceaea;
  border-radius: 8px !important;
  padding: 12px 5px;
  display: inline-flex;
  cursor: pointer;
}
.bedArnge a img {
  width: 18px;
}
.cardioGrupBtn button {
  border-radius: 4px;
  background-color: #f2f2f2;
  box-shadow: initial !important;
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: capitalize;
  padding: 9px 11px;
  margin: 3px 0px;
  margin-right: 6px;
}
.cardioGrupBtn button:last-child {
  margin-right: 0px !important;
}
.cardioGrupBtn button:hover {
  background-color: #eccaff !important;
  color: #8c00db !important;
}
.cardioActv {
  background-color: #eccaff !important;
  color: #8c00db !important;
}
.cardioGrup {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin-bottom: 32px;
}
.cardioTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.cardioTabs button {
  background-color: transparent !important;
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  opacity: initial !important;
  width: initial !important;
  min-width: initial !important;
  margin-right: 20px;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.cardioTabs button:last-child {
  margin-right: 0px !important;
}
.cardiotabIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #8c00db;
  color: #8c00db !important;
}
.cardiotabIner.Mui-selected span {
  color: #8c00db !important;
}
.cardioGrupBtn {
  border-bottom: 1px solid #f2f2f2;
  padding: 18px;
}
.cardioTabUpr {
  padding: 0px 18px;
}
.drListRght2 button {
  border-radius: 6px;
  background-color: #e1f7f7 !important;
  box-shadow: initial !important;
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  text-transform: initial;
  font-family: Muli;
  padding: 10px 10px;
  width: 100%;
  opacity: initial !important;
  border: 1px solid #e1f7f7;
}
/* .drListMain2:hover button {
    border: 1px solid #00abb166 !important;
} */
.drCollection {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding-bottom: 16px;
  min-height: 300px;
}
.drList2,
.drCollection {
  margin-top: 14px;
}
.patientRow2 .drListMain2 {
  margin-bottom: 0px;
}
@media screen and (min-width: 960px) and (max-width: 1100px) {
  .drListRght2 button {
    font-size: 10px !important;
    padding: 10px 4px !important;
  }
  .wardsGrupUpr {
    padding: 20px 20px;
  }
  .topLeftSpc {
    padding-left: 20px;
    padding-right: 20px;
  }
  .MenuWeb .menuItems ul li a span {
    font-size: 10px;
  }
}
.medcalCntr ul li:last-child label::after {
  bottom: -21px;
}
.medcalFZCntnt img {
  height: 56px;
}
.medcalFZCntnt label {
  color: #000808;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 12px;
}
.medcalFZCntnt p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.medcalFZCntnt {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  min-height: 196px;
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px;
}
.medcalFZCntnt a {
  display: block;
}
.extSetting {
  margin-bottom: 36px;
}
.extSetting a {
  color: #00abaf !important;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  cursor: pointer;
}
.extSetting img {
  transform: rotateZ(180deg);
  vertical-align: middle;
  margin-right: 12px;
  height: 14px;
  margin-top: -2px;
}
.extSetting h1 {
  color: #000808;
  font-family: Muli;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.nwSpclSec {
  min-height: 127px;
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: #e5e6e6;
}
.nwSpclSec p {
  font-family: Muli;
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
}
/* setting 3 css */
.breadCrumbUpr3 {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 14px 21px;
  margin: 34px 0px 30px;
}
.roomBreadCrumb3 ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.roomBreadCrumb3 ul li {
  list-style-type: none;
  margin-bottom: 0px;
  display: inline-block;
  padding-right: 45px;
  position: relative;
}
.roomBreadCrumb3 span {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
}
.roomBreadCrumb3 ul li label {
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
  position: relative;
}
.roomBreadCrumb3 ul li:first-child label::after {
  height: 4px;
  width: 100%;
  background-color: #00abaf;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -17px;
  content: "";
}
.roomBreadCrumb3 ul li::after {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 13px;
  background-size: contain;
  background: url("../../assets/images/Right.svg") no-repeat center center;
  cursor: pointer;
}
.roomBreadCrumb3 ul li:last-child {
  padding-right: 0px !important;
}
.roomBreadCrumb3 ul li:last-child::after {
  content: initial !important;
}
.wardsGrup3 {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin-bottom: 12px;
}
.spcMgntUpr3,
.roomsNum3 {
  padding: 18px 18px;
}
.wardsGrup3 button {
  border-radius: 4px;
  background-color: #fbd4d4;
  color: #ee5253;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  box-shadow: initial !important;
  font-family: Muli;
  text-transform: capitalize;
  padding: 8px 11px;
}
.spcMgntRght3 {
  text-align: right;
}
.spcMgntRght3 a {
  border: 1px solid #eceaea;
  border-radius: 8px !important;
  padding: 6px 6px;
  display: inline-flex;
  cursor: pointer;
  background-color: #f2f2f2;
}
.roomsNum3 {
  border-top: 1px solid #f2f2f2;
  padding-bottom: 20px;
}
.roomsNum3 ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.roomsNum3 ul li {
  color: #404646;
  font-family: Muli;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  list-style-type: none;
  margin-bottom: 14px;
  position: relative;
}
.roomsNum3 ul li img {
  width: 16px;
  height: auto;
  margin-right: 8px;
}
.roomsNum3 ul li span {
  color: #000808;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
  padding-left: 24px;
  margin-top: 3px;
}
.roomsNum3 ul li:first-child {
  padding-left: 0px;
  font-size: 14px;
  font-weight: bold;
}
.roomsNum3 ul li:first-child img {
  width: 20px;
}
.roomsNum3 ul li:first-child::after {
  content: "";
  background-image: url("../images/rightArrow.png");
  background-size: contain;
  height: 12px;
  width: 9px;
  position: absolute;
  top: 0px;
  left: initial !important;
  right: 6px !important;
  background-repeat: no-repeat;
}
.roomsNum3 ul li:last-child {
  margin-bottom: 0px;
  font-size: 12px;
}
.roomsNum3 ul li:last-child::before {
  border-left: none;
  height: initial;
  content: "";
}
.roomsNum3 ul li:nth-last-child(2)::before {
  height: initial;
  border-left: none;
}
.roomsNum3 ul li::before {
  content: "";
  height: 42px;
  width: 10px;
  position: absolute;
  top: 32px;
  left: 8px;
  background-repeat: no-repeat;
  color: #d8d8d8;
  border-left: 1px solid #d8d8d8;
}
.roomsNum3 ul li::after {
  content: "-";
  position: absolute;
  top: 0px;
  left: 8px;
  background-repeat: no-repeat;
  color: #d8d8d8;
}
.spcMgntRght3 a img {
  width: 16px;
  height: 16px;
}
.roomBreadCrumb3 ul li:last-child label {
  color: #00abaf;
}
@media screen and (max-width: 599px) {
  .roomBreadCrumb3 ul li {
    margin-top: 6px;
    margin-bottom: 12px;
  }
  .roomBreadCrumb3 ul li:last-child {
    margin-bottom: 0px !important;
  }
  .billSeting {
    text-align: initial !important;
    margin-top: 28px;
    margin-bottom: 18px;
  }
  .newServc button {
    min-width: initial !important;
  }
  .billPaginationRght {
    text-align: left !important;
  }
  .billPaginationRght p a:first-child {
    margin-left: 0px !important;
  }
}
.addSpclSec {
  min-height: 127px;
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: #e5e6e6;
}
.addSpclSec p {
  font-family: Muli;
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
}
.addSpeclContnt {
  width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 3px;
}
.addSpeclContntIner {
  height: 472px;
  /* overflow-y: auto; */
}
.addSpecIner {
  margin-right: 2px;
  height: 550px;
  overflow-y: auto;
}
.addSpeclLbl {
  padding: 20px 28px;
  border-bottom: 1px solid #f2f2f2;
}
.enterSpclMain {
  padding: 38px 28px 12px 28px;
}
.enterSpcl {
  margin-bottom: 0px;
}
.addSpeclClose {
  text-align: right;
}
.addSpeclLbl img {
  width: 24px; 
  cursor: pointer;
  border-radius: 6px;
}
.addSpeclLbl label {
  color: #000808;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
}
.enterSpcl label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  font-family: Muli;
  display: block;
}
@media (max-width: 599px){
  .addSpeclContnt {
    width: 96% !important;
  }
  .addWrnContnt {
    width: 96% !important;
  }
}
.enterSpcl .MuiTextField-root,
.enterSpcl .MuiInput-underline {
  display: block;
  width: 100%;
}
.enterSpcl input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 12px 12px;
  box-sizing: border-box !important;
  min-height: 44px;
}
.enterSpcl .MuiInput-underline::before,
.enterSpcl .MuiInput-underline::after {
  content: initial !important;
}
.enterSpcl input::-webkit-input-placeholder {
  color: #484848;
}
.enterSpcl input::-moz-placeholder {
  color: #484848;
}
.enterSpcl input:-ms-input-placeholder {
  color: #484848;
}
.enterSpcl input:-moz-placeholder {
  color: #484848;
}
.actvColorBtn {
  text-align: center;
}
a.actBtn {
  cursor: pointer;
  height: 42px;
  width: 42px;
  border-radius: 8px;
  /* background-color: #FBD4D4; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* color: #EE5253; */
  position: relative;
}
a.actBtn svg {
  font-size: 17px;
}
ul.subSpclList {
  z-index: 1;
  top: 45px;
  padding-left: 0px;
  margin-bottom: 0px;
  position: absolute;
  right: 0;
  width: 220px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding: 24px 8px 5px 8px;
}
ul.subSpclList li {
  list-style-type: none;
  display: inline-block;
}
ul.subSpclList li a {
  cursor: pointer;
  margin: 7px 7px;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
ul.subSpclList label {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 15px;
}
a.recodRed {
  background-color: #fbd4d4;
  color: #ee5253;
}
a.recodRed:hover {
  border: 1px solid #ee5253;
  color: #ee5253;
}
a.recodLghtRed {
  background-color: #f8ddcc;
  color: #db5600;
}
a.recodLghtRed:hover {
  border: 1px solid #db5600;
  color: #db5600;
}
a.recodYelow {
  background-color: #f8ebcc;
  color: #db9b00;
}
a.recodYelow:hover {
  border: 1px solid #db9b00;
  color: #db9b00;
}
a.recodGren {
  background-color: #f2f8cc;
  color: #bfdb00;
}
a.recodGren:hover {
  border: 1px solid #bfdb00;
  color: #bfdb00;
}
a.recodDrkGren {
  background-color: #e0f8cc;
  color: #64db00;
}
a.recodDrkGren:hover {
  border: 1px solid #64db00;
  color: #64db00;
}
a.recodBlue1 {
  background-color: #ccf8ed;
  color: #00dba4;
}
a.recodBlue1:hover {
  border: 1px solid #00dba4;
  color: #00dba4;
}
a.recodBlue2 {
  background-color: #b4ecf8;
  color: #00b0db;
}
a.recodBlue2:hover {
  border: 1px solid #00b0db;
  color: #00b0db;
}
a.recodBlue3 {
  background-color: #cce8ff;
  color: #0076db;
}
a.recodBlue3:hover {
  border: 1px solid #0076db;
  color: #0076db;
}
a.recodBlue4 {
  background-color: #d2ccf8;
  color: #2000db;
}
a.recodBlue4:hover {
  border: 1px solid #2000db;
  color: #2000db;
}
a.recodViolet {
  background-color: #eccaff;
  color: #8c00db;
}
a.recodViolet:hover {
  border: 1px solid #8c00db;
  color: #8c00db;
}
a.recodLghtViolet {
  background-color: #f8ccf4;
  color: #db00c6;
}
a.recodLghtViolet:hover {
  border: 1px solid #db00c6;
  color: #db00c6;
}
a.recodBlck {
  background-color: #c7c7c7;
  color: #1f2121;
}
a.recodBlck:hover {
  border: 1px solid #1f2121;
  color: #1f2121;
}
.plusWards {
  height: 144px;
  width: 100%;
  border-radius: 12px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0px;
}
.plusWards p {
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
}
.spclSaveBtn button {
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  width: 100%;
  padding: 14px 12px;
  text-transform: capitalize;
}

.addWardsRoom {
  min-height: 262px;
  width: 100%;
  border-radius: 12px;
  background-color: #f2f2f2;
  margin-top: 32px;
  padding: 14px;
}
.addWardsUpr {
  background-color: #ffffff;
  border-radius: 12px;
}
.addWardsIner {
  padding: 24px 18px;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.addWardsIner input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 12px 12px;
  box-sizing: border-box !important;
  min-height: 44px;
}
.addWardsIner .MuiTextField-root,
.addWardsIner .MuiInput-underline {
  display: block;
  width: 100%;
}
.addWardsIner div::before,
.addWardsIner div::after {
  content: initial;
}
.addWardsIner label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  display: block;
}
.addWardsIner .wrdsBtn {
  padding-bottom: 0px;
}
.roomName {
  margin-top: 22px;
  /* overflow: auto; */
  height: 170px;
  overflow-x: hidden;
}
.roomName label {
  margin-bottom: 0px;
}
.roomName .label1 {
  display: none;
}
.roomName .label2 {
  display: none;

}
.roomName-h label {
  margin-bottom: 0px;
}
.roomName-h .MuiGrid-container {
  margin-bottom: 0px;
}
.roomRmv {
  text-align: right;
}
.roomRmv a {
  height: 34px;
  width: 34px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  display: inline-flex;
  align-content: flex-end;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 0px;
}
.roomName .MuiGrid-container {
  margin-bottom: 5px;
}
.add_a_room a {
  color: #00abaf !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}
.wrdsBtn {
  text-align: right;
  margin-bottom: 0px;
}
.wrdsBtn button {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: capitalize;
  padding: 8px 16px;
  color: #7f8383;
  margin-right: 32px;
}
.wrdsBtn button:last-child {
  margin-right: 0px;
}
.wrdsBtnActv {
  border-radius: 8px !important;
  background-color: #00abaf !important;
  color: #ffffff !important;
}
.wrdsBtn {
  text-align: right;
  background: #ffffff;
  border-top: 1px solid #f2f2f2;
  padding: 12px 18px;
}
.saveNclose {
  margin-top: 32px;
}
.wardListSec {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 21px 22px;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin-bottom: 20px;
}
.wrdCollect label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 10px;
}
.wrdEdtDel div {
  display: inline-block;
  margin-right: 20px;
}
.wrdEdtDel div:last-child {
  margin-right: 0px !important;
}
.wrdEdtDel span {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 7px;
}
.wrdEdtDelBtn {
  display: flex;
  justify-content: flex-end;
}
.wrdEdtDelBtn div:last-child {
  margin-left: 10px;
}
.wrdEdtDelBtn button {
  height: 34px;
  width: 34px !important;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 12px;
  min-width: initial;
  margin-right: 12px;
}
.wrdEdtDelBtn button:last-child {
  margin-right: 0px !important;
}
.addSpeclLbl button {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  color: #7f8383;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: initial;
  font-family: Muli;
  padding: 8px 9px;
  vertical-align: middle;
  margin-left: 24px;
}
.addSpeclLbl button img {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}
.addNwWard {
  text-align: center;
}
.addNwWard p {
  color: #00abaf;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-bottom: 7px;
}
.drftDwnload {
  margin-top: 32px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  width: 100%;
  padding: 12px 18px;
}
.draftDateLft label {
  color: #000808;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 0px;
  vertical-align: middle;
}
.draftDateLft span {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  vertical-align: middle;
  position: relative;
  padding-left: 15px;
  margin-left: 18px;
}
.draftDateLft span::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #7f8383;
  position: absolute;
  border-radius: 15px;
  top: 5px;
  left: 0;
}
button.downloadDots {
  height: 34px;
  width: 34px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  min-width: inherit;
  font-family: Muli;
  margin-left: 20px;
}
.downloadDots img {
  height: 4px;
  opacity: 0.5;
}
button.downloadPDF {
  color: #00abaf;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
}
button.downloadPDF img {
  height: 16px;
  margin-right: 8px;
}
button.downloadPDF:hover {
  background: transparent;
}
.draftDateRght {
  text-align: right;
}
.srvcContent {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin-top: 32px;
  margin-bottom: 10px;
}
.xRay-edit button {
  height: 34px;
  width: 34px !important;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 12px;
  min-width: initial;
  margin-right: 12px;
}
.srvcTable h3 {
  padding: 10px 42px;
  color: #000808;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0px;
}
.srvcTable th {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.srvcTable th, .srvcTable td {
  padding: 12px 42px 18px;
}
.srvcTable thead tr {
  border-bottom: 1px solid #d9dada;
}
.srvcTable tbody tr {
  border-bottom: 1px solid #f2f2f2;
}
.srvcTable td {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.srvcTable td label {
  color: #000808;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  display: block;
  margin-top: 2px;
  margin-bottom: 0px;
}
.srvcTable td p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.xRay-edit {
  text-align: right;
}
.invoiceAmnt p {
  color: #000808;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 2px;
}
.invoiceAmnt label {
  color: #000808;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 12px;
  display: block;
}
.invoiceAmnt {
  padding: 30px 42px 30px;
}
.invoiceAmnt div button {
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  text-transform: capitalize;
  padding: 12px 17px;
  margin-right: 12px;
  min-height: 48px;
  width: 154px;
}
.invoiceAmnt div button:first-child {
  color: #ffffff !important;
  background-color: #00abaf;
}
.invoiceAmnt div button:last-child {
  color: #7f8383;
}
.addCstmField div::before,
.addCstmField div::after {
  content: initial !important;
}
.cstmSelect {
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.addCstmField .cstmSelect div {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: initial !important;
  border-radius: 4px;
}
.addCstmField input[type="text"] {
  min-height: 48px;
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 16px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.addCstmField .MuiTextField-root {
  width: 100%;
}
.addCstmField {
  padding: 0px 42px 0px;
  border-bottom: none;
  margin-bottom: 0px;
}
.addCstmField label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
}
.addSrvcBtn {
  text-align: right;
}
.addSrvcBtn button {
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  font-family: Muli;
  height: 48px;
  width: 100%;
  margin-top: 20px;
}
.invoiceForm label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  display: block;
}
.invoiceForm input[type="text"] {
  min-height: 48px;
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 16px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.invoiceForm div::before,
.invoiceForm div::after {
  content: initial !important;
}
.invoiceForm {
  padding: 18px 42px 18px;
  border-bottom: 1px solid #d9dada;
}
.patntImg {
  margin-right: 12px;
}
.patntImg img {
  height: 32px;
  width: 32px;
  border-radius: 17px;
}
.patntDropDwn {
  border-radius: 4px;
  background-color: #e5e6e6;
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
}
.patntDropDwn label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
}
.patntDropDwn p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.patntRmv {
  margin-left: 12px;
}
.patntDropUpr {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  padding: 3px 3px;
}
.invoiceID,
.invoiceID .MuiFormControl-root {
  width: 100%;
}
.patntRmv img {
  cursor: pointer;
}
.actionList {
  display: none;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding-left: 0px;
  margin-bottom: 0px;
  position: absolute;
  left: -135px;
  width: 186px;
  top: 33px;
  z-index: 1;
}
.downloadDots:hover .actionList {
  display: block !important;
}
.actionList ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.actionList li {
  list-style-type: none;
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
  text-transform: initial;
  padding: 10px 20px 10px;
}
.actionList li:last-child {
  margin-bottom: 0px !important;
}
.actionList li img {
  height: 16px;
  opacity: initial;
  margin-right: 10px;
  vertical-align: middle;
}
.actionList li span {
  vertical-align: middle;
}
.setStatus {
  /* border-top: 1px solid #F2F2F2;
    border-bottom: 1px solid #F2F2F2; */
  /* margin-top: 14px; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  margin-bottom: 0px;
  position: relative;
}
.setStatusNxtPart::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 16px;
  background-size: contain;
  background: url("../images/Right.svg") no-repeat center center;
  cursor: pointer;
}
.setStatusNxtPart {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: left;
  text-transform: initial;
  padding: 10px 20px 10px;
}
.setStatus li:last-child {
  padding-top: 10px;
  padding-bottom: 10px;
}
.newServc {
  text-align: right;
}
.newServc button {
  color: #ffffff !important;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #00abaf !important;
  min-width: 160px;
  display: inline-block;
  padding: 16px 20px;
  cursor: pointer;
  text-decoration: none;
  text-transform: initial;
}
.srvcTable3 {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.srvcTable3 th {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.srvcTable3 th,
.srvcTable3 td {
  padding: 18px 18px;
}
.srvcTable3 tbody tr {
  border-bottom: 1px solid #f2f2f2;
}
.srvcTable3 tbody tr:last-child {
  border-bottom: 0px;
}
.srvcTable3 td {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.srvcTable3 td label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 0px !important;
}
.srvcTable3 td p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.srvcDots {
  text-align: right;
}
.srvcDots button {
  height: 24px;
  width: 24px !important;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 12px;
  min-width: initial;
}
.srvcDots img {
  height: 4px;
}
.dataPaginationLft,
.dataPaginationRght {
  padding: 17px 42px;
}
.dataPaginationRght {
  text-align: right;
}
.dataPaginationLft p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.dataPaginationRght p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.dataPaginationRght p a:last-child {
  color: #00abaf;
}
.dataPaginationRght p span,
.dataPaginationRght p a {
  display: inline-block;
  margin: 0px 8px;
}
.bilingTabUpr {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 10px 20px;
  margin: 34px 0px 30px;
}
.billSeting {
  text-align: right;
}
.billSeting a {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: initial;
  margin-left: 14px;
}
.billSeting a:first-child {
  margin-left: 0px !important;
}
.billSeting img {
  height: 16px;
}
.billTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.billTabs button {
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  width: initial !important;
  min-width: initial !important;
  margin-right: 26px;
  min-height: 62px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 4px solid;
  border-color: transparent;
}
.billtabIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
}
.billInfoData {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin-bottom: 62px;
}
.billInfoData thead tr {
  border-bottom: 1px solid #d9dada;
}
.billInfoData th {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  padding: 25px 42px;
  font-family: Muli;
}
.billInfoData td {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  /* padding: 10px 42px; */
  padding: 10px 35px;
  font-family: Muli;
}
.patentPic img {
  height: 18px;
  width: 18px;
  border-radius: 32px;
  margin-right: 8px;
}
.billDots {
  text-align: right;
}
.billDots button {
  height: 24px;
  width: 24px !important;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 12px;
  min-width: initial;
}
.billInfoData tbody tr {
  border-bottom: 1px solid #f2f2f2;
}
.greyDot span {
  height: 8px;
  width: 8px;
  background-color: #7f8383;
  display: inline-flex;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}
.yelowDot span {
  height: 8px;
  width: 8px;
  background-color: #feca57;
  display: inline-flex;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}
.greenDot span {
  height: 8px;
  width: 8px;
  background-color: #1dd1a1;
  display: inline-flex;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}
.redDot span {
  height: 8px;
  width: 8px;
  background-color: #ee5253;
  display: inline-flex;
  border-radius: 8px;
  vertical-align: middle;
  margin-right: 8px;
}
.billDots img {
  height: 4px;
}
.billPaginationLft,
.billPaginationRght {
  padding: 17px 42px;
}
.billPaginationLft p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.billPaginationRght {
  text-align: right;
}
.billPaginationRght p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.billPaginationRght p span,
.billPaginationRght p a {
  display: inline-block;
  margin: 0px 8px;
}
.addPatient h1 {
  color: #000808;
  font-family: Muli;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
}
.addPatientBtn {
  text-align: right;
}
.addPatientBtn button {
  color: #ffffff !important;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #00abaf !important;
  min-width: 160px;
  display: inline-block;
  padding: 16px 20px;
  cursor: pointer;
  text-decoration: none;
  text-transform: initial;
}
.adPatntTabMain {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin: 34px 0px 30px;
}
.adPatntTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.adPatntTabs button {
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  width: initial !important;
  min-width: initial !important;
  margin-right: 26px;
  min-height: 62px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 4px solid;
  border-color: transparent;
}
.adPatnttabIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
}
.adPatntTabUpr {
  border-bottom: 1px solid #d9dada;
}
.userInfoUpr,
.adPatntTabUpr {
  padding: 0px 25px;
}
.userInfo {
  padding: 22px 0px;
  border-radius: 12px;
}
.userInfo h2 {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 10px;
  font-family: Muli;
}
.userInfo h3 {
  color: #7f8383;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: Muli;
}
.userDetail {
  margin-top: 25px;
}
.userDetailIner {
  margin-bottom: 10px;
}
.userDetailIner label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.userDetailIner input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  min-height: initial !important;
  width: 100% !important;
  height: initial !important;
  line-height: initial !important;
}
.vh-section .profileInfoIner h5 {
  color: #000808;
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 28px;
  margin-bottom: 5px;
}
.staticsAmt {
  display: flex;
  padding: 23px 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  margin-right: 28px;
  min-height: 214px;
}
.staticsAmt a {
  height: 48px;
  width: 48px;
  background-color: #e1f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-right: 14px;
}
.staticHeading h1 {
  color: #000808;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 35px;
}
.staticsAmt label {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 2px;
}
.staticsAmt p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.staticsAmtMid {
  margin-right: 22px;
}
.staticsAmtUpr .staticsAmt:last-child {
  margin-right: 0px !important;
}
.modeChng {
  display: flex;
  padding: 24px 32px 24px 32px;
  border-radius: 12px;
  align-items: center;
}
.modeChng a {
  height: 32px;
  width: 32px;
  background-color: #e1f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-right: 14px;
}
.modeChng label {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 2px;
}
.modeChng p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.modeChngUpr .modeChng:last-child {
  margin-right: 0px !important;
}
.modeChngUpr {
  margin-top: 32px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.modeChngUpr h3 {
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
}
.staffAction {
  border-bottom: 1px solid #f2f2f2;
  padding: 28px 20px 18px 20px;
}
.staffLastMnth,
.invoicLastMnth {
  text-align: right;
}
.staffLastMnth a,
.invoicLastMnth a {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 14px;
}
.staffLastMnth label,
.invoicLastMnth label {
  color: #00abaf;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 0px;
}
.staffGraphUpr {
  margin-top: 32px;
  border-radius: 12px;
  background-color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 80px;
}
.invoicHsptal {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.invoicHsptal button {
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  width: initial !important;
  min-width: initial !important;
  margin-right: 26px;
  min-height: 62px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 4px solid;
  border-color: transparent;
}
.invoicHsptalIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
}
.invoicHsptalData img {
  width: 100%;
}
.staffGraph {
  border-bottom: 1px solid #f2f2f2;
  padding: 8px 20px 0px 20px;
}
.staffGraphCntnt {
  padding: 62px 25px 32px 25px;
}
.patntFlow p {
  color: #7f8383;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  padding: 22px 20px 15px 20px;
  border-bottom: 1px solid #f2f2f2;
}
.patntFlow {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  min-height: 214px;
  margin-bottom: 30px;
}
.patntFlowIner {
  border-radius: 12px;
  padding: 10px 22px 15px 22px;
}
.patntFlowIner label {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 9px;
  text-transform: capitalize;
}
.patntFlowLast label {
  margin-bottom: 0px;
}
.patntFlowIner .MuiGrid-grid-md-4 {
  color: #000808;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}
.actvtyFilter a {
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-right: 14px;
}
.actvtyFilter {
  padding: 12px 20px 5px 20px;
}
.tskOverView {
  margin-top: 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 24px 20px;
  border-radius: 15px;
}
/* added by Tanya */
.spcificInfo .MuiTypography-root.MuiTypography-body1 {
  padding: 24px 0px !important;
}
.tskOverView h1 {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 35px;
}
.taskNum label {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 0px;
}
.taskYelow label span {
  height: 8px;
  width: 8px;
  background-color: #feca57;
  border-radius: 12px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: unset;
}
.taskGren label span {
  height: 8px;
  width: 8px;
  background-color: #1dd1a1;
  border-radius: 12px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: unset;
}
.taskNum p {
  color: #000808;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.taskNum {
  width: 100%;
  min-width: 120px;
  border-radius: 12px;
  background-color: #fafafa;
  display: inline-block;
  padding: 10px 18px 14px;
  margin-bottom: 18px;
}
.showArchiv {
  text-align: center;
} 
.showArchiv a {
  color: #00abaf !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 12px;
}
.taskTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.taskTabs button {
  color: #7f8383 !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  width: initial !important;
  margin-right: 26px;
  min-height: 62px;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 4px solid;
  border-color: transparent;
  background-color: #f2f2f2;
  min-width: 110px;
  border-radius: 12px;
}
.taskTabs button:last-child {
  margin-right: 0px !important;
}
.taskTabsIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
}
.addTaskBtn {
  text-align: right;
}
.addTaskBtn button {
  min-height: 48px;
  min-width: 139px;
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  font-family: Muli;
}
.taskSort {
  text-align: right;
}
.taskSort a:first-child {
  margin-left: 0px !important;
}
.taskSort a {
  height: 31px;
  width: 31px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: initial;
  margin-left: 6px;
  margin-top: 4px;
}
.taskSort img {
  height: 16px;
}
.taskCntntMng {
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 0px 20px;
  padding-bottom: 8px;
  margin: 0px 0px 12px;
}
.revwFiles label {
  color: #000808;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  word-break: break-word;
  font-family: Muli;
  margin-bottom: 0px;
}
.revwFiles button {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: capitalize;
  border-radius: 4px;
  line-height: initial;
  padding: 3px 6px;
  margin-bottom: 2px;
}
.revwFiles img {
  height: 32px;
  width: 32px;
  border-radius: 24px;
  background: #f3f3f3;
}
.allInerTabs {
  padding: 16px 15px 40px 15px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.allTabCntnt {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  padding: 14px 14px;
  margin: 12px 22px;
  position: relative;
}
.allInfo label {
  color: #000808;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 4px;
  font-family: Muli;
}
.allInfo p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.revwFiles {
  display: inline-flex;
  align-items: center;
  margin-right: 48px;
}
.revwFiles1 {
  min-width: 132px;
  max-width: 132px;
}
.allInfo {
  display: inline-flex;
  align-items: center;
}
.revwFilesRght,
.allInfoRght {
  margin-left: 4px;
}
.attchNote label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 0px;
  margin-left: 6px;
}
.attchImg img {
  height: 16px;
}
.attchNoteUpr {
  display: inline-flex;
  align-items: center;
}
.attchNote:first-child {
  margin-right: 15px;
}
.attchOpen button {
  border-radius: 4px;
  background-color: #fff7e6 !important;
  color: #c58904;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
  padding: 7px 8px;
}
.attchOpen button label {
  height: 8px;
  width: 8px;
  background-color: #feca57;
  border-radius: 12px;
  margin-right: 7px;
  margin-bottom: 0px;
  font-family: Muli;
}
.userPics {
  min-width: 85px;
  text-align: right;
}
.userPics a {
  display: inline-block;
  text-decoration: none !important;
}
.userPics a img {
  height: 26px;
  width: 26px;
  border-radius: 12px;
}
.userPics a span {
  color: #7f8383;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
}
.userPics a:last-child {
  height: 26px;
  width: 26px;
  background-color: #f2f2f2;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 12px;
}
.attchNoteMain {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.attchOpen {
  margin: 0px 38px;
  padding-left: 0px;
}
.userDots button {
  height: 24px;
  width: 24px !important;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 12px;
  min-width: initial;
}
.userDots img {
  height: 4px;
}
.userDots {
  margin-left: 20px;
}
.allInerTabs .allInerTabs:last-child {
  margin-bottom: 0px !important;
}
.radiologyColor label {
  text-decoration: line-through;
  color: #7f8383 !important;
}
.NeuroColor button {
  color: #8c00db;
  background-color: #eccaff;
}
.radiologyColor button {
  color: #7f8383;
  background-color: #f2f2f2;
}
.OncologyColor button {
  color: #00b0db;
  background-color: #b4ecf8;
}
.rvewFiles { 
  width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding: 8px 2px;
}
.rvewFilesinner {
  height: 537px;
  overflow-y: scroll;
  /* padding: 20px 0px; */
}
.rvewFilesiner {
  padding: 38px 28px 12px 28px;
}
.rvwCadio button {
  color: #ee5253;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: capitalize;
  border-radius: 4px;
  background-color: #fbd4d4;
  line-height: initial;
  padding: 3px 6px;
  margin-bottom: 2px;
}
.rvwCadio h3 {
  color: #000808;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 4px;
}
.rvwCadio p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.newassignLft img {
  height: 26px;
  width: 26px;
  border-radius: 27px;
}
.asignLft img,
.asignRght img {
  height: 32px;
  width: 32px;
  border-radius: 27px;
}
.asignRght img {
  margin-right: 10px;
}
.asignLft div a:last-child {
  height: 32px;
  width: 32px;
  background-color: #f2f2f2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 29px;
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
}
.asignUpr {
  display: flex;
  align-items: center;
  margin-top: 18px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 15px;
  margin-bottom: 0px;
}
.asignLft label,
.asignRghtUpr label {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.asignRght {
  display: flex;
  align-items: center;
}
.asignRght label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.asignRght p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.asignLft, .asignRghtUpr {
  min-width: 142px;
}
.asignRght span {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 3px;
  display: inline-block;
}
.asignRght p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.rvwCadio, .asignUpr, .makeCmpt, .multiDescp, .assignSecUpr {
  padding-left: 10px;
  padding-right: 30px;
}
.assignSec {
  margin-top: 8px !important;
  margin-bottom: 10px !important;
  margin: 0px auto;
  display: table;
}
.markDone label {
  color: #00abaf;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 12px;
  margin-bottom: 0px;
  font-family: Muli;
  cursor: pointer;
}
.markDone img {
  height: 45px;
  width: 45px;
}
.markDone {
  display: flex;
  align-items: center;
}
.makeCmpt,
.multiDescp,
.assignSecUpr {
  margin-top: 18px;
  /* border-bottom: 1px solid #F2F2F2;
    padding-bottom: 24px;
    margin-bottom: 18px; */
}
.cmntUpr {
  margin-top: 18px;
  padding-bottom: 5px;
}
.addDue label {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.addDue button {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
}
.addDueDate .makeCmpt {
  margin-right: 12px !important;
  min-width: 128px !important;
}
.multiDescp label {
  color: #000808;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.multiDescp p {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.assignSec label {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  margin-bottom: 0px;
  font-family: Muli;
  display: block;
  margin-top: 10px;
  margin-left: 0px;
}
.assignSec img {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 8px;
}
.assignSec div {
  display: inline-block;
  min-width: 98px;
  width: 90px;
  text-align: center;
}
.attchFile label {
  color: #000808;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.browseHere {
  min-height: 76px;
  width: 100%;
  border: 2px dashed #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.browseHere a {
  color: #7f8383 !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.browseHere a span {
  color: #00abaf;
}
.browseHere a span {
  color: #00abaf;
  text-decoration: underline;
}
.attchFile p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  margin-top: 6px;
}
.updateDoc label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 14px;
}
.updateInfo p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  padding-left: 12px;
  min-width: 188px;
}
.updateDoc img {
  height: 48px;
  width: 48px;
  border-radius: 8px;
}
.updateInfo {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
a.updateRmv img {
  width: 17px;
  cursor: pointer;
}
.updateDoc .updateInfo:last-child {
  margin-bottom: 0px;
}
.updateDoc {
  margin-top: 20px;
}
.browseHere img {
  vertical-align: middle;
  margin-right: 8px;
}
.cmntMsgs p {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.cmntMsgs {
  display: flex;
}
.cmntMsgs label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: 12px;
}
.cmntMsgs span {
  color: #7f8383;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}
.cmntMsgs img {
  height: 32px;
  width: 32px;
  border-radius: 28px;
  margin-right: 12px;
}
.cmntMsgsCntnt {
  margin-top: 4px;
  margin-bottom: 2px;
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 12px 12px;
}
.cmntMsgs button {
  color: #00abaf !important;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
  padding: 0px !important;
  min-width: initial !important;
  font-family: Muli;
}
.cmntMsgs button:first-child {
  margin-right: 10px;
}
.cmntMsgs button span {
  color: #7f8383 !important;
}
.cmntMsgs button:hover {
  background-color: transparent;
}
.cmntInerBrdr {
  border-top: 1px solid #f2f2f2;
  margin-top: 22px;
  padding-top: 22px;
}
.addComit textarea {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  min-height: 102px;
  resize: none;
  outline: none;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 12px;
}
.addComit button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #00abaf !important;
  font-family: Muli;
  text-transform: capitalize;
  position: absolute;
  right: 6px;
  bottom: 12px;
}
.addComit {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.cmntUpr {
  padding-left: 30px;
  padding-right: 30px;
}
.saveTask button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #00abaf !important;
  font-family: Muli;
  text-transform: capitalize;
  width: 100%;
  padding: 16px 2px;
}
.appTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.appTabs button {
  color: #7f8383 !important;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  width: initial !important;
  margin-right: 26px;
  min-height: 62px;
  border-bottom: 4px solid;
  border-color: transparent;
  background-color: #f2f2f2;
  min-width: 66px;
  border-radius: 12px;
}
.appontTask button {
  min-height: 48px;
  min-width: 217px;
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  font-family: Muli;
}
.appontTask {
  text-align: right;
}
.appTabIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
  border-radius: 12px 12px 7px 7px;
}
.srchPatient2 {
  margin-top: 32px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  width: 100%;
  padding: 0px 14px;
}
.srchRght2 {
  text-align: right;
  padding: 16px 0px;
}
.srchRght2 a {
  vertical-align: middle;
  margin-right: 8px;
}
a.srchSort2 {
  background-color: #f2f2f2;
}
.srchRght2 a {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.srchRght2 img {
  height: 15px;
}
.allSpec2 {
  font-size: 12px !important;
  width: 89px;
  display: inline-block;
  font-family: "Mulish", sans-serif !important;
  text-align: left;
  margin-right: 22px;
}
.allSpec2 .css-yk16xz-control {
  border-radius: 8px !important;
  background-color: #f2f2f2 !important;
  border-color: transparent !important;
}
.allSpec2 div {
  min-height: initial !important;
}
a.lineSort2 {
  border: 1px solid #eceaea;
}
a.horzSort2 {
  background-color: #e1f7f7;
  margin-right: 0px;
}
.showOnly p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle;
  font-family: "Mulish", sans-serif !important;
  margin-right: 20px;
}
.showOnly span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  padding: 0px;
  margin-right: 2px;
}
.showOnly span.MuiIconButton-label {
  color: #bfc1c1;
}
.showOnly label {
  margin-bottom: 0px;
  font-family: Muli;
}
.setDate button {
  height: 32px;
  width: 59px;
  border-radius: 8px;
  background-color: #f2f2f2 !important;
  color: #404646 !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
}
.setDate p {
  color: #404646;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  display: inline-block;
  font-family: Muli;
}
.SelLeft img {
  transition: revert;
  transform: rotate(270deg);
}
.SelRght img {
  transition: revert;
  transform: rotate(90deg);
}
.setDate a {
  display: inline-block;
  margin: 0px 14px;
}
.setDate a span {
  margin: 0px 8px;
}
.showOnly {
  display: inline-block;
  margin-right: 14px;
}
.setDate {
  padding: 18px 0px;
}
.calenderDetails {
  margin-top: 30px;
  margin-bottom: 32px;
}
.calenderDetails img {
  width: 100%;
}
@media (max-width: 600px) {
  .appontTask {
    text-align: left;
    margin-top: 32px;
  }
  .appTabs button {
    margin-right: 14px;
  }
  .movPtnt,
  .addStaff,
  .fltrClear {
    width: 95% !important;
  }
  .fltrTabs button {
    font-size: 12px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .srchRght2 {
    text-align: left;
    padding: 0px 0px 16px;
  }
  /* .revwFiles1 {
    max-width: unset;
    min-width: unset;
  } */
}
.movPtnt {
  width: 420px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding: 5px 2px;
}
.movPtntinner {
  height: 490px;
  overflow-y: auto;
  padding: 20px 0px;
}
.movPtntLbl,
.moveClient,
.goMgnt,
.addStaffLbl,
.addStafMgnt {
  padding-left: 20px;
  padding-right: 20px;
}
.addStaffLbl {
  border-bottom: 1px solid #f2f2f2;
}
.movPtntLbl {
  border-bottom: 1px solid #f2f2f2;
}
.moveClient img {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  box-shadow: 0 24px 40px 0 rgb(0 8 8 / 8%);
}
.movPtntClose {
  text-align: right;
}
.movPtntClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
}
.movPtntLbl label {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
}
.moveClient label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.moveClient p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.moveClient {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.moveClientLft {
  margin-right: 21px;
}
.goMgnt p {
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 12px;
}
.goMgnt {
  margin: 16px 0px 6px;
}
.NoWardLink a {
  cursor: pointer;
  color: #00abaf !important;
  text-decoration: underline !important;
}
.spcldropdown label,
.spcldropdown2 label,
.addStafMgnt label,
.addStafdrop label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.alowLocAces .addStafSelect {
  z-index: 999999 !important;
}
.addStafSelect {
  width: 100%;
  font-family: "Mulish", sans-serif !important;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.srvcContent .addStafSelect div {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: initial !important;
  border-radius: 4px;
}
.darkTheme .srvcContent .addStafSelect div {
  background-color: #192020 !important;
}
.spclSelect {
  font-size: 12px !important;
  width: 100%;
  font-family: "Mulish", sans-serif !important;
  text-align: left;
}
.spclSelect div div div:first-child {
  color: #8c00db;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  min-width: 90px;
  padding: 4px 3px;
  border-radius: 4px;
  background-color: #eccaff;
  text-align: center;
  font-family: "Mulish", sans-serif !important;
}
.spcldropdown,
.spcldropdown2 {
  margin-bottom: 10px;
}
.spclBtn button {
  border-radius: 8px;
  background-color: #00abaf !important;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
  padding: 15px 2px;
  margin-top: 22px;
}
.addStaff {
  width: 420px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding: 5px 2px;
}
.addStaffIner {
  height: 490px;
  overflow-y: auto;
  padding: 20px 0px;
}
.addStaffClose {
  text-align: right;
}
.addStaffClose img {
  height: 16px;
  width: initial;
  cursor: pointer;
}
.addStaffLbl label {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
}
.addStafClient {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.addStafClient {
  padding-left: 20px;
  padding-right: 20px;
}
.addStafClient img {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  box-shadow: 0 24px 40px 0 rgb(0 8 8 / 8%);
}
.addStafClientLft {
  margin-right: 21px;
}
.addStafClient img {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  box-shadow: 0 24px 40px 0 rgb(0 8 8 / 8%);
}
.addStafClient label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.addStafClient p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.addStafMgnt {
  margin-top: 12px;
  margin-bottom: 24px;
}
.stafLst {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.stafLft {
  width: 80%;
}
.stafLft a {
  display: inline-block;
  margin-right: 10px;
}
.stafRght {
  width: 20%;
  text-align: right;
}
.stafLft img {
  height: 40px;
  width: 40px;
  border-radius: 24px;
}
.stafLft span {
  /* color: #000808; */
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.stafRght a {
  height: 34px;
  width: 34px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  display: inline-block;
  text-align: center;
  padding: 2px 0px;
  cursor: pointer;
}
.addStafClos {
  text-align: center;
}
.addStafClos button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #00abaf !important;
  padding: 12px 6px;
  width: 92%;
  font-family: Muli;
  text-transform: initial;
  margin-top: 10px;
}
.fltrLblClose {
  text-align: right;
}
.fltrLblClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
}
.stafLstCntnt {
  padding: 0px 20px;
}
.fltrClear {
  width: 420px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding: 5px 1px 5px 1px;
}
.fltrClearIner {
  height: 445px;
  overflow-y: auto;
  padding: 20px 0px;
}
.fltrLbl label {
  color: #000808;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
}
.fltrTabs button {
  border-radius: 12px !important;
  background-color: #f2f2f2 !important;
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  text-transform: capitalize;
  opacity: initial !important;
  width: initial !important;
  min-width: initial !important;
  margin-right: 7px;
  min-height: 38px;
  padding-left: 18px;
  padding-right: 18px;
}
.fltrtabIner.Mui-selected {
  background-color: #ffffff !important;
  border-bottom: 4px solid #00abaf;
  color: #404646 !important;
}
.fltrTabs {
  background-color: #fafafa !important;
  padding: 13px 20px 4px;
  width: 100%;
  box-shadow: initial !important;
  margin-bottom: 18px;
}
.fltrLbl {
  padding: 0px 20px;
}
.fltrInput {
  margin-bottom: 12px;
}
.fltrInput input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  outline: none;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 11px 12px;
  margin-bottom: 0px;
}
.fltrForm {
  padding: 0px 20px;
}
.addInput {
  position: relative;
}
.addInput img {
  position: absolute;
  right: 6px;
  top: 10px;
}
.addInput .MuiRadio-colorSecondary.Mui-checked {
  color: #00abaf;
}
.addInput span.MuiTypography-root.MuiFormControlLabel-label {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
}
.addInput label.MuiFormControlLabel-root {
  margin-bottom: 0px;
}
.addInput label.MuiFormControlLabel-root:last-child {
  margin-right: 0px;
}
.fltrInput label {
  color: #000808;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  font-family: Muli;
  margin-bottom: 2px;
}
.addInput span {
  padding: 0px 2px 0px 4px;
}
.aplyLft label {
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  margin-bottom: 0px;
}
.aplyFltr {
  display: flex;
  align-items: center;
  border-top: 1px solid #f2f2f2;
  margin-top: 25px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.aplyLft,
.aplyRght,
.aplyRght1 {
  width: 50%;
}
.aplyRght button, .aplyRght1 button {
  border-radius: 8px;
  background-color: #00abaf;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: capitalize;
  padding: 10px 8px;
}
.aplyRght {
  text-align: right;
}
.pantNames li:hover {
  background-color: #e1f7f7 !important;
  background: #e1f7f7 !important;
}
.pantNames li:first-child {
  background: #e1f7f7 !important;
}
.pantNames li {
  color: #00abaf !important;
  font-family: Muli;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.pantNames li input {
  background-color: #e1f7f7 !important;
  background: #e1f7f7 !important;
}
.pantNames div span {
  background: #00abaf !important;
}
.pantNames {
  position: relative;
  margin-bottom: 16px;
}
.pantNames img {
  position: absolute;
  right: 6px;
  bottom: 10px;
}
.pantNames ul {
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
}
.pantNames label {
  color: #000808;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  font-family: Muli;
  margin-bottom: 12px;
}
.searchBox {
  width: 100%;
  outline: none;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 11px 12px;
  margin-bottom: 0px;
}
.inputField {
  margin: 5px;
}
.chips {
  background: red;
}
.optionContainer {
  border: 2px solid;
}
option {
  color: blue;
}
.groupHeading {
  background: red;
}
.fltrForm .search-wrapper {
  padding: 0px;
  min-height: initial;
}
.spclDropUpr {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  padding: 3px 3px;
  position: relative;
}
.spclDropDwn {
  border-radius: 4px;
  background-color: #e5e6e6;
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
}
.spclImg {
  margin-right: 12px;
}
.spclImg img {
  height: 32px;
  width: 32px;
  border-radius: 17px;
}
.spclDropDwn label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
}
.spclDropDwn p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.spclRmv {
  margin-left: 12px;
}
.spclRmv img {
  cursor: pointer;
}
.spclDropMain .spclDropLbl {
  color: #000808;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  font-family: Muli;
  margin-bottom: 12px;
}
.spclDropMain {
  margin-bottom: 16px;
}
img.spclDropImg {
  position: absolute;
  right: 6px;
  bottom: 13px;
}
.neuroColor {
  border-radius: 4px;
  background-color: #eccaff;
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
}
.neuroColor label {
  color: #8c00db;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
  font-family: Muli;
}
.fltrLblBrdr {
  padding: 0px 20px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 18px;
}
.billFltr {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 22px;
  padding-bottom: 8px;
}
.delSpcl a {
  color: #ee5253 !important;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  border: 1px solid #ee5253;
  display: inline-block;
  padding: 6px 8px;
  border-radius: 8px;
  margin-left: 22px;
}
.delSpcl a img {
  height: 15px;
  width: initial !important;
  vertical-align: top;
  margin-right: 5px;
}
.addWrnContnt {
  width: 516px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.addWrnLbl {
  padding: 14px 28px;
  border-bottom: 1px solid #f2f2f2;
}
.addWrnClose {
  text-align: right;
}
.addWrnLbl img {
  width: 24px;
  cursor: pointer;
}
.enterWrnMain {
  padding: 32px 30px 22px 30px;
}
.addWrnLbl label {
  color: #ee5253;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
  margin-bottom: 0px;
}
.enterWrnMain label {
  color: #000808;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  font-family: Muli;
  margin-bottom: 0px;
  margin-right: 0px;
}
.enterWrnMain img {
  height: 22px;
  margin-right: 21;
}
.enterWrnMain .warnImg {
  min-width: 38px;
  display: inline-block;
}
/* .enterWrnMain .MuiFormControlLabel-label {
    color: #404646;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
} */
.enterWrnMain .MuiCheckbox-colorSecondary {
  margin-right: 14px;
  margin-left: 0px;
  padding: 0px;
}
.wrnUndr {
  margin-bottom: 18px;
}
.wardLine {
  margin-bottom: 12px;
}
.wardLine:last-child {
  margin-bottom: 0px;
}
.wardLine .MuiCheckbox-root {
  color: #bfc1c1;
}
.selectWarn button {
  color: #7f8383;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  text-transform: capitalize;
  min-height: 42px;
  margin-right: 15px;
  padding: 0px 12px;
}
.selWarnBtn {
  border-radius: 8px;
  background-color: #ee5253 !important;
  color: #ffffff !important;
  min-height: 42px;
  padding: 0px 14px !important;
}
.selectWarn button:hover {
  background-color: #ee5253 !important;
  color: #ffffff !important;
}
.selectWarn button:last-child {
  margin-right: 0px !important;
}
.selectWarn {
  margin-top: 32px;
}
.confirmActn {
  border-radius: 8px;
  background-color: #feca57;
  padding: 12px 18px;
  margin-top: 20px;
}
.confirmActn p {
  color: #976901;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: Muli;
}
.addFlowContnt {
  width: 443px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.addFlowLbl {
  padding: 20px 28px;
  border-bottom: 1px solid #f2f2f2;
}
.addFlowClose {
  text-align: right;
}
.creatLblClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #cacaca !important;
  padding: 4px;
}
.addFlowClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #cacaca !important;
  padding: 4px;
}
.addWrnClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #cacaca !important;
  padding: 4px;
}
.addFlowLbl label {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
  margin-bottom: 0px;
}
.patentInfo input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 12px 12px;
  box-sizing: border-box !important;
  min-height: 44px;
  color: #000808;
}
.patentInfo .MuiInput-underline::before,
.patentInfo .MuiInput-underline::after {
  content: initial !important;
}
.patentInfo input::-webkit-input-placeholder {
  color: #484848;
}
.patentInfo input::-moz-placeholder {
  color: #484848;
}
.patentInfo input:-ms-input-placeholder {
  color: #484848;
}
.patentInfo input:-moz-placeholder {
  color: #484848;
}
.patentInfo {
  padding: 20px 28px 28px;
}
.patentInfo label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.patentInfoTxt {
  margin-bottom: 12px;
}
.patentInfoTxt:last-child {
  margin-bottom: 12px;
}
.patentInfoBtn button {
  height: 48px;
  width: 100%;
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
}
.patentInfoBtn {
  margin-top: 25px;
}
.sendSpecific {
  width: 420px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding: 5px 1px 5px 1px;
}
.sendSpecificIner {
  height: 490px;
  overflow-y: auto;
  padding: 20px 0px;
}
.sendSpecificBtm {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 22px;
  padding-bottom: 8px;
  padding: 0px 20px 15px;
}
.sendSpecifiClose {
  text-align: right;
}
.sendSpecifiClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
}
.sendSpecificBtm select {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
  border: none;
  outline: none;
}
.sendSpecificBtm select option {
  color: #000808;
}
.sendSpecificBtm label {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 0px;
  color: #7f8383;
}
.sendSpecInfo label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.spcificInfo {
  padding: 0px 22px;
}
.spcificTabs {
  background-color: transparent !important;
  box-shadow: initial !important;
}
.spcificTabs button {
  color: #7f8383 !important;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  font-family: Muli;
  text-transform: initial !important;
  border: 1px solid #bfc1c1;
  border-radius: 0 4px 4px 0;
  background-color: #ffffff;
  width: 50%;
  min-height: 42px;
}
.spcificTabs button.Mui-selected {
  background-color: #00abaf;
  color: #ffffff !important;
  border: 1px solid #00abaf;
}
.spcificTabs button:first-child {
  border-radius: 4px 0 0 4px;
}
.spcificTabs button:last-child {
  border-radius: 0px 4px 4px 0px;
}
.spcificTabs button.Mui-selected span {
  color: #ffffff;
}
.spcificInfo .MuiTabs-fixed {
  margin-bottom: 10px;
}
.tabHeading label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.sendSpecInfo div {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
.specBtm {
  margin-bottom: 12px;
}
.sendSpecInput div {
  width: 100%;
}
.sendSpecInput input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 12px 12px;
  box-sizing: border-box !important;
  min-height: 38px;
  color: #000808;
}
.sendSpecInput .MuiInput-underline::before,
.sendSpecInput .MuiInput-underline::after {
  content: initial !important;
}
.publishProm button {
  margin-top: 14px;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
}
.sendSpecInput {
  position: relative;
}
img.ckImg {
  width: 95%;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 53px;
  margin: 0 auto;
}
.cke_contents {
  min-height: 320px;
}
.endPst label {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  font-family: Muli;
  margin-right: 0px;
  margin-bottom: 0px;
}
.endPst span {
  padding: 0px;
  margin-right: 4px;
  font-family: Muli;
}
.endPst {
  padding-left: 10px;
}
.endPst .MuiCheckbox-colorSecondary.Mui-checked {
  color: #00abaf;
}
.sendDropUpr {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  padding: 3px 3px;
  position: relative;
}
.sendDropDwn {
  border-radius: 4px;
  background-color: #e5e6e6;
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
}
.sendImg {
  margin-right: 12px;
}
.sendImg img {
  height: 32px;
  width: 32px;
  border-radius: 17px;
}
.sendDropDwn p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.sendDropDwn label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
}
.sendRmv {
  margin-left: 12px;
}
.sendRmv img {
  cursor: pointer;
}
img.sendDropImg {
  position: absolute;
  right: 6px;
  bottom: 13px;
}
.profileMain {
  padding: 18px 15px;
  width: 538px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
}
.profileMain .profileSec:last-child {
  margin-bottom: 0px;
}
.profileSecLft label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
}
.profileSecLft span {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.profileSecRght span {
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  margin-left: 4px;
}
.profileSecRght img {
  height: 13.33px;
  width: 13.33px;
}
.profileSec {
  display: flex;
  margin-bottom: 12px;
}
.profileSecLft,
.profileSecRght {
  width: 50%;
}
.profileSecLft label {
  margin: 0px 10px;
}
.profileSecRght a:first-child {
  margin-right: 12px;
}
.profileSecRght {
  text-align: right;
}
.profileSecRght a:first-child img {
  border-radius: 18px;
}
.receptnLink label {
  color: #404646;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
}
.receptn {
  /* width: 264px; */
  border-radius: 12px;
  background-color: #f2f2f2;
  padding: 16px 18px;
  margin-bottom: 18px;
}
.receptnLink img {
  height: 26px;
  width: 26px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #ffffff;
}
.receptnLink {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.receptnRght {
  text-align: right;
}
.receptnLink div {
  width: 50%;
}
.cardioBtn button {
  color: #8c00db;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  border-radius: 4px;
  background-color: #fbd4d4 !important;
  text-transform: initial;
  padding: 4px 5px;
}
.cardioUpr {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.cardioNumRght label {
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 5px;
  display: block;
}
.cardioNumLft img {
  height: 32px;
  width: 32px;
  border-radius: 40px;
}
.cardioNumRght p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.cardioNum {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.cardioNumRght {
  padding-left: 12px;
}
.cardioInfo {
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 10px;
}
.cardioCount img {
  width: 16px;
  height: initial;
}
.cardioCount a,
.cardioCount span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
}
.cardioCount {
  padding: 12px 10px;
}
.cardioCount a img {
  width: 16px;
  height: initial;
  margin-right: 8px;
  vertical-align: sub;
}
.cardioCount span {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 16px;
}
.neuroCount img {
  width: 16px;
  height: initial;
}
.neuroCount a,
.cardioCount span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
}
.neuroCount a img {
  width: 16px;
  height: initial;
  margin-right: 8px;
  vertical-align: sub;
}
.neuroCount span {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 16px;
}
.neuroCntUpr {
  display: flex;
  align-items: center;
  padding: 12px 10px;
}
.neuroCntRght a {
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 2px;
}
.neuroCntRght a:last-child {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  color: #7f8383;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  border-radius: 14px;
  margin-right: 0px;
}
.neuroCntRght a img {
  width: 100%;
}
.neuroCntUpr div {
  width: 50%;
}
.neuroCntRght {
  text-align: right;
}
.neuroCountRm a:first-child {
  margin-right: 14px;
}
.neuroCountRm {
  margin-bottom: 8px;
}
.movPopup {
  height: 161px;
  width: 277px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  margin-bottom: 18px;
}
.movHeadMid label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin-bottom: 0px;
}
.movHeadRght img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.movHead {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 8px 0px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.movHead div {
  width: 33.3%;
}
.movHeadRght {
  text-align: right;
}
.movHeadLft img {
  transform: rotateZ(270deg);
}
.movHeadMid {
  text-align: center;
}
.positionDrop label {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.dischargeSec {
  width: 578px;
  margin-bottom: 18px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
}
.dischargeHead h5 {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.dischargeInfo p {
  color: #000808;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 7px;
  margin-bottom: 14px;
}
.headercont {
  background-color: white;
  padding: 15px 60px;
  border-radius: 20px;
  margin-left: 20px;
}
.dischargeInfo label {
  color: #000808;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}
button.creatInvoic {
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
  height: 48px;
  min-width: 166px;
  padding: 0px 20px;
}
button.dischrgInvoic {
  border-radius: 8px;
  background-color: #e1f7f7 !important;
  color: #00abaf !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
  height: 48px;
  min-width: 166px;
  padding: 0px 20px;
}
button.dischrgCncl {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  text-transform: initial;
  background: transparent;
  background-color: transparent !important;
}
.dischargeInfo div button {
  margin-right: 12px;
  margin-bottom: 10px;
}
.dischargeInfo div button:last-child {
  margin-right: 0px;
}
.dischargeHead {
  padding: 15px 20px;
  border-bottom: 1px solid #f2f2f2;
}
.dischargeHead img {
  height: 24px;
  width: 24px;
  padding: 3px;
  border: 1px solid #656969;
  border-radius: 5px;
}
.dischargeHead a {
  display: block;
  text-align: right;
}
.dischargeInfo {
  padding: 15px 20px;
}
.deleteStep {
  width: 480px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-bottom: 0px;
}
.deleteStepLbl label {
  color: #ee5253;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
}
.deleteStepInfo label {
  color: #000808;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  font-family: Muli;
  margin-bottom: 10px;
}
.deleteStepInfo button:first-child {
  height: 48px;
  min-width: 171px;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: #ee5253;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
  margin-right: 12px;
  margin-bottom: 5px;
}
.deleteStepInfo button:last-child {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: initial;
  background-color: transparent !important;
  background: transparent !important;
}
.deleteStepInfo p {
  margin-top: 0px;
  margin-bottom: 14px;
  color: #000808;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.deleteStepLbl img {
  height: 24px;
  width: 24px;
}
.deleteStepLbl a {
  display: block;
  text-align: right;
}
.deleteStepLbl {
  border-bottom: 1px solid #f2f2f2;
}
.deleteStepLbl, .deleteStepInfo {
  padding: 15px 20px;
}
.deleteStep .deleteStepInfo {
  padding-top: 20px;
  padding-bottom: 20px;
}
.deleteStep .deleteStepInfo label {
  margin-bottom: 20px;
  display: block;
}
.dark-confirm .deleteStepLbl {
  border-bottom: 1px solid #7c7c7c;
}
.receptn2 {
  width: 480px;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 16px 18px;
  margin-bottom: 18px;
}
.receptnLink2 {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.receptnLink2 .receptnLink2Lft,
.receptnLink2 .receptnLink2Rght {
  width: 50%;
}
.receptnLink2 label {
  color: #404646;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
}
.receptnLink2 .linkDots2 img {
  height: 26px;
  width: 26px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
}
.movPopup2 {
  height: 300px;
  width: 277px !important;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  margin-bottom: 18px;
}
.movHead2 {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 10px;
  margin-bottom: 12px;
}
.movHead2 div {
  width: 33.3%;
}
.movHeadLft2 img {
  transform: rotateZ(270deg);
}
.movHeadMid2 {
  text-align: center;
}
.movHeadMid2 label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin-bottom: 0px;
}
.movHeadRght2 {
  text-align: right;
}
.movHeadRght2 img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.receptnLink2Rght {
  position: relative;
}
.btmSpace {
  padding-bottom: 40%;
}
a.linkDots2 {
  display: block;
  text-align: right;
}
.usrAction li {
  list-style-type: none;
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 14px;
}
.usrAction li:last-child {
  margin-bottom: 0px;
}
.usrAction {
  width: 194px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding: 18px 12px;
}
.usrAction ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.usrAction li:first-child img {
  width: 12px;
}
.usrAction li span {
  min-width: 34px;
  display: inline-block;
  text-align: center;
}
.dupAction {
  width: 194px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  padding: 18px 12px;
}
.dupAction ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.dupAction li {
  list-style-type: none;
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 14px;
}
.dupAction li:last-child {
  margin-bottom: 0px !important;
}
.dupAction li span {
  min-width: 34px;
  display: inline-block;
  text-align: center;
}
.dupAction ul li img {
  height: 21px;
}

.statsDrft {
  width: 277px !important;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
  margin-bottom: 18px;
}
.statsDrftHead {
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 10px;
  margin-bottom: 12px;
}
.statsDrftHead div {
  width: 33.3%;
}
.statsDrftMid label {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  margin-bottom: 0px;
}
.statsDrftLft img {
  transform: rotateZ(270deg);
}
.statsDrftRght {
  text-align: right;
}
.statsDrftRght img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.colorDots li {
  color: #000808;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: Muli;
  margin-bottom: 14px;
  list-style-type: none;
  position: relative;
}
.colorDots li:last-child {
  margin-bottom: 0px !important;
}
.colorDots li:before {
  position: absolute;
  top: 6px;
  left: -17px;
  height: 8px;
  width: 8px;
  background-color: #1dd1a1;
  content: "";
  border-radius: 12px;
}
.greyDrft li:before {
  background-color: #7f8383 !important;
}
.yelowIssue li:before {
  background-color: #feca57 !important;
}
.redOvr li:before {
  background-color: #ee5253 !important;
}
button.crdoBtn {
  color: #ee5253;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #fbd4d4 !important;
}
button.crdorng {
  color: #db5600;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #f8ddcc !important;
}
button.crdoYelw {
  color: #b58000;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #f8ebcc !important;
}
button.crdoGren {
  color: #5e6c00;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #e5edb1 !important;
}
button.crdoLghtGren {
  color: #4da800;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #e0f8cc !important;
}
button.crdoLghtBlu1 {
  color: #0088a9;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #b4ecf8 !important;
}
button.crdoLghtBlu2 {
  color: #0076db;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-transform: initial;
  height: 32px;
  width: 86px;
  border-radius: 4px;
  background-color: #cce8ff !important;
}
.buttonColect button {
  margin-right: 12px;
  margin-bottom: 12px;
}
.checkDots label {
  color: #404646;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  margin-bottom: 0px;
}
.detailInfo {
  border-radius: 12px;
  background-color: #f2f2f2;
  min-height: 792px;
  padding: 4px 8px;
  margin: 0px 6px;
  margin-top: 45px;
  margin-bottom: 10px;
}
.checkDots div {
  width: 50%;
}
.checkDots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkDotsRght {
  text-align: right;
}
.dragdrop-horizontal .checkDotsRght {
  display: inline-block;
}
.flowInfoBtn button {
  color: #ee5253;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  background-color: #fbd4d4;
  text-transform: initial;
  padding: 4px 8px;
}
.flowInfo {
  margin-top: 8px;
  margin-bottom: 12px;
  /* min-height: 127px; */
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%);
}
/* Adding by ankit */

.labelChoice {
  text-align: left;
}

.taskDescp textarea {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  width: 100%;
  color: #000808;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 121px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #006400 !important;
}

.cnfrmDiaMain {
  padding: 22px 25px 0px 24px !important;
}

.questionnairedesc {
  box-sizing: border-box;
  height: 121px;
  width: 518px;
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
}

.questionfieldprop {
  margin-top: 20px;
}
.infoSub2 {
  text-align: center;
}
.infoSub2 button:hover {
  background-color: #00abaf;
  color: #fff;
}
.infoSub2 button {
  width: 90%;
  padding: 14px 0px;
  border-radius: 8px;
  background-color: #00abaf;
  border: none;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  outline: none;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0px;
}
.infoSub2 button {
  color: #ffffff;
  background-color: #00abaf !important;
}

.add_a_choice {
  text-align: center;
}

.checkboxquestion {
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.checkboxques {
  background-color: #d3d3d3;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.MuiFormControlLabel-root {
  margin-left: 0px !important;
}
.addServContnt {
  max-width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-top: 6px;
  padding-bottom: 30px;
  padding-right: 1px;
}
.specbutton1 {
  font-weight: bold !important;
}
.servSaveBtn button {
  border-radius: 8px;
  background-color: #00abaf !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  /* width:90%; */
  width: 100%;
  padding: 14px 12px;
  /* margin-left: 30px; */
  text-transform: capitalize;
}
.enterServMain {
  padding: 25px 0px 12px 0px;
  margin-left: 30px;
  margin-right: 30px;
}
.nwModCloseBtn {
  text-align: right;
  margin-bottom: 4px;
  /* margin-right: 4px; */
}

.tasksection {
  width: 97%;
  margin-bottom: 20px;
  font-weight: bold;
}

.checkboxprop {
  background-color: #d3d3d3;
  margin-right: 20px;
  border-radius: 4px;
}
.required:after {
  content:" *";
  color: red;
}
.checkboxprop label {
  margin-left: 0px !important;
}
.checkboxprop .MuiCheckbox-root {
  color: #808080 !important;
  margin-top: 0px !important;
}
.flowInfo1 {
  margin-top: 8px;
  margin-bottom: 12px;
  /* min-height: 127px; */
  border-radius: 8px;
  background-color: #ffffff;
  /* box-shadow: 0 8px 16px 0 rgb(0 8 8 / 8%); */
}
.ptntFlowSpc {
  padding-left: 4%;
  padding-right: 4%;
}
.flowProfil .imgProfile {
  height: 35px;
  width: 35px;
  border-radius: 28px;
}
.flowProfil label {
  color: #000808;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  margin-bottom: 0px;
}
.flowProfil p {
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.flowProfil { 
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.flowProfilRght {
  padding-left: 10px;
}
.flowInfoInr {
  border-bottom: 1px solid #f2f2f2;
  padding: 12px 12px;
}
.flowInfoInr2 {
  padding: 8px 12px 12px;
}
.dtlCntUpr {
  display: flex;
  align-items: center;
}
.dtlCountRm a:first-child {
  margin-right: 14px;
}
.dtlCount a, .dtlCount span {
  color: #404646;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
}
.dtlCount a img {
  width: 16px;
  height: initial;
  margin-right: 8px;
  vertical-align: sub;
}
.dtlCount span {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 16px;
}
.dtlCount img {
  width: 16px;
  height: initial;
}
.newassignLft a {
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-right: 2px;
}
.dtlCntRght a img {
  width: 100%;
}
.newassignLft a:last-child {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  color: #7f8383;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  border-radius: 50%;
  margin-right: 0px;
}
.dtlCntUprNw .dtlCntLft {
  width: 60%;
}
.dtlCntUprNw .dtlCntRght {
  text-align: right;
  width: 40%;
}
.RadiologyClr button {
  color: #0076db;
  background-color: #cce8ff;
}
.dtlCntUprNw {
  margin-top: 6px;
}
.NeurologyClr button {
  color: #8c00db;
  background-color: #eccaff;
}
.darkTheme .newAddStepBtn button {
  background-color: #404646;
}
.newAddStepBtn button {
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
  border-radius: 8px;
  background-color: #e5e6e6;
  width: 100%;
  padding: 14px 0px;
  cursor: pointer !important;
  min-width: 320px;
}
.nwPatentAdd button {
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
  border-radius: 8px;
  background-color: #e5e6e6;
  width: 100%;
  min-height: 127px;
  cursor: pointer !important;
}
.nwPatentAdd button:hover {
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(0, 8, 8, 0.04);
  color: #00abaf;
}
.plusNwPatent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.plusNwPatent label {
  color: #00abaf;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  margin-bottom: 0px;
}
.bgGreyTxt {
  background-color: #e5e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: initial;
}
.bgGreyTxt label {
  color: #7f8383;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  font-family: Muli;
  margin-bottom: 0px;
}
.taskTabsMob {
  display: none !important;
}
.breadCrumbUprMob {
  display: none;
}
.cardoLblMob,
.srchPatientMob {
  display: none;
}
.tskTabsMob,
.TabContainerMob {
  display: none;
}
.LeftPatientDataWeb {
  display: block;
}
@media (max-width: 468px) {
  .staticHeading h1 {
    text-align: center;
  }
  .staticsAmtMid {
    margin-right: 40px;
  }
  .staffLastMnth,
  .invoicLastMnth {
    text-align: left;
    margin-top: 18px;
    margin-bottom: 22px;
  }
  .modeChngUpr h3 {
    margin-bottom: 12px;
  }
  .modeChng {
    width: 100%;
  }
  .staffGraphUpr {
    margin-bottom: 0px;
  }
  .tskOverWeb {
    display: block;
  }
  .taskTabsMob,
  .taskTabsMob {
    display: block !important;
  }
  .taskTabs button {
    background-color: transparent;
    margin-left: 10px;
    margin-right: 10px;
    min-width: initial;
  }
  .taskViewMob {
    margin-top: 0px;
    padding: 0px;
  }
  .taskDetailMob{
    padding-left: 10px;
    padding-right: 10px;
  }
  .taskTabsIner.Mui-selected {
    background-color: transparent !important;
    border-radius: 0px;
  }
  .taskTabs {
    background-color: #ffffff !important;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #dadada;
  }
  .addTaskBtn { 
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .taskNum {
    display: block;
  }
  .tskOverView {
    min-height: initial;
  }
  .allInerTabs {
    box-shadow: initial !important;
    padding: 20px 20px;
  }
  .allTabCntnt {
    background: #ffffff;
  }
  .rvewFiles {
    width: 98%;
  }
  .userDots {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .attchOpen {
    margin: 0px 15px;
  }
  .allInfo {
    display: flex;
    margin: 12px 0px;
  }
  .allInfo2 {
    padding-left: 5px !important;
  }
  .attchNoteMain {
    justify-content: flex-start;
    margin-top: 10px;
  }
  .breadCrumbUprWeb {
    display: none;
  }
  .breadCrumbUprMob {
    display: block !important;
    margin-top: 0px;
    border-radius: 0px !important;
  }
  .addFlow h1 {
    text-align: center;
  }
  .addFlowRght {
    text-align: center !important;
  }
  .srchPatientMob .srchLft,
  .srchPatientMob a.srchSort {
    display: none;
  }
  a.lineSort, a.horzSort {
    margin-top: 15px;
  }
  a.lineSort { 
    margin-left: 12px;  
  }
  .srchRght {
    padding: 16px 0px 16px 17px !important;
  }
  .srchPatient {
    margin-top: 0px;
  }
  .addFlow {
    margin: 12px 0px 38px;
  }
  .srchPatientSrch .allSpec,
  .srchPatientSrch a.lineSort,
  .srchPatientSrch a.horzSort {
    display: none;
  }
  .srchPatientSrch .srchLft input {
    display: none;
  }
  .srchPatientSrch .srchRght a {
    margin-top: -27px;
  }
  .cardioAreaMob {
    border-bottom: 1px solid #f2f2f2;
  }
  .cardioDots {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .cardioAreaUpr {
    position: relative;
  }
  .addPatent {
    display: flex;
    justify-content: space-between;
  }
  .cardoLblMob {
    display: block;
  }
  .cardoLblWeb {
    display: none;
  }
  .cardioAreaMob .cardioArea {
    padding-bottom: 15px;
  }
  .cardioAreaMob .cardoLbl {
    margin-top: 0px !important;
  }
  .tskTabsMob {
    background: #ffffff !important;
    display: block;
  }
  .tskTabs button {
    border-radius: 0px !important;
    background-color: transparent !important;
  }
  .tskTabsMob,
  .TabContainerMob {
    display: block;
  }
  .LeftPatientDataWeb {
    display: none;
  }
  .tskTabsweb,
  .TabContainerWeb {
    display: none;
  }
  .MidSectionWeb {
    display: none;
  }
  .tskTabs {
    margin-top: 0px;
    border-top: 1px solid #e8e8e8;
  }
  .topLeftSpc {
    margin-top: 0px;
  }
  .topLeftSpc {
    padding-left: 0px;
    padding-right: 0px;
  }
  .roomBreadCrumbMob ul li {
    display: inline-block !important;
  }
  .roomBreadCrumbMob ul li:first-child {
    display: none !important;
  }
  .spcMgnt h1 {
    text-align: center;
    margin-top: 28px;
    margin-bottom: 32px;
  }
  .addTaskBtn.addAssignBtn1 {
    display: block !important;
  }
  .addTaskBtn.addAssignBtn1 button {
    margin-bottom: 14px !important;
  }
}
.spcLeft {
  margin-top: 45px;
  margin-left: 54px;
}
.newStaffNew img {
  margin-top: -90px;
}
.asignStafNew {
  margin-top: 90px;
}
.QuesMrkt div:first-child img {
  width: 162.6px;
  height: initial;
}
.QuesMrkt div:last-child img {
  width: 162.6px;
  height: initial;
}
.QuesMrkt div:last-child img {
  height: 24px;
  width: 24px;
}
.QuesMrkt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.QuesMrktUpr {
  min-height: 332px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  padding: 30px 28px;
  border-top: 3px solid #00abaf;
  margin-bottom: 35px;
}
/* added by tanya */
.otherBrdrUpr input[type="text"] {
  border: none;
  border-bottom: 1px solid grey;
  margin-left: 20px;
}
.QuesMrktUpr h1,
.QuesMrktUpr .quesitonPattient {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 32px;
  margin-bottom: 10px;
}
.onlineBox label {
  margin-right: 0px;
  margin-bottom: 0px;
}
.onlineBox label span {
  font-family: Muli;
}
.onlineBox .MuiCheckbox-colorPrimary.Mui-checked {
  color: #00abaf;
}
.otherBorder {
  border-bottom: 1px solid #bfc1c1;
  width: 35%;
  display: inline-flex;
  height: 29px;
  margin-left: 30px;
}
.asnswerSbmt button {
  height: 40px;
  width: 167px;
  border-radius: 8px;
  background-color: #e1f7f7 !important;
  color: #00abaf;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-family: Muli;
  text-transform: initial;
}
.asnswerSbmt {
  margin-top: 16px;
}
.alert-btn {
  color: red;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.QuesMrktUpr p {
  color: #404646;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 25px;
  font-family: Muli;
}
.rateExp h3 {
  color: #000808;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  font-family: Muli;
  margin-top: 15px;
  margin-bottom: 25px;
}
.rateExp ul {
  padding-left: 0px;
}
.rateExp ul li {
  cursor: pointer;
  list-style-type: none;
  display: inline-flex;
  height: 34px;
  width: 34px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.rateExp ul li:last-child {
  margin-right: 0px !important;
}
.rateExp ul li a {
  color: #bfc1c1 !important;
  font-family: Muli;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
.rateExp ul li:hover {
  background-color: #00abaf;
  box-shadow: 0 8px 24px 0 rgba(0, 171, 175, 0.32);
}
.rateExp ul li:hover a {
  color: #ffffff !important;
}
.hintsLbl h2 {
  color: #000808;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.hintsLbl label {
  color: #2700db;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 30px;
  margin-bottom: 5px;
  display: block;
  font-family: Muli;
}
.onlineVideo img {
  width: 100%;
}
.asnswerSbmtCenter {
  text-align: center;
}
.onlineVideo {
  margin: 15px 0px 15px;
}
.questionnareMrkt {
  border: 1px solid #e5e6e6;
  padding: 14px 14px;
}
@media (max-width: 960px) {
  .spcLeft {
    margin-top: 30px;
    margin-left: 0px;
  }
  .QuesMrktUpr {
    min-height: initial;
  }
  .otherBorder {
    width: 60%;
  }
  .srchLft input {
    font-size: 11px;
  }
  .detailInfo {
    min-height: initial;
  }
  .dragdrop-vertical ul li {
    width: 100% !important;
  }
}
.wardsGrup .roomsNum2:last-child {
  border-bottom: none;
}
/* Added by Ankita */
.service-head{
    font-size: 19px;
    font-weight: 600;
}
.addSrvcBtn3 a {
  color: #00abaf !important;
  font-size: 14px;
  font-weight: 600;
  float: right;
} 
.edtdelservice img {
  width: 22px;
  margin-right: 10px;
}
.edtdelservice img:last-child {
  margin-right: 0px;
}
@media (max-width: 500px) {
  .informStatus {
    padding: 11px !important;
    width: 100% !important;
  }
}
@media (max-width: 1400px) {
  .informStatus {
    width: 100% !important;
  }
}
.informStatus {
  padding: 0px;
  text-align: right;
  width: 22px;
}
.taskViewMob {
  padding-bottom: 70px;
}
@media (max-width: 959px) {
  .vh-section .prfilHghtLft p span,
  .vh-section .prfilHghtRght p span {
    display: block;
  }
  .srchLft input::-webkit-input-placeholder {
    overflow: hidden;
    width: 87%;
  }
  .srchLft input::-moz-placeholder {
    overflow: hidden;
    width: 87%;
  }
  .srchLft input:-ms-input-placeholder {
    overflow: hidden;
    width: 87%;
  }
  .srchLft input:-moz-placeholder {
    overflow: hidden;
    width: 87%;
  }
  .tskOverView {
    min-height: initial;
  }
  .tskOverView h1 {
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .medcalFZCntnt {
    margin: 0px;
    margin-bottom: 17px;
  }
  .addSrvcBtn button {
    margin-top: 0px !important;
  }
  .invoiceAmnt {
    padding: 0px 24px 38px !important;
  }
  .enterSpcl input, .addStafSelect {
    margin-bottom: 0px !important;
  }
  .srvcTable {
    margin-bottom: 5px !important;
  }
  .MuiGrid-root.hideTask {
    margin-top: 10px;
  }
}
.spec-bed-profile {
  word-break: break-word;
}
.showOnPrint {
  display: none;
}
.darkTheme .dateFormateSec .ant-picker {
  padding: 0px;
  background: #404646;
}
.disabledCrd {
  border: 2px solid #ff6f00 !important;
}
.darkTheme .headercont {
  background: #3f4646;
}
.blood-sec {
  line-height: 30px !important;
}
.setPositionMob {
  position: relative !important;
}
.setPositionMob .flag-select {
  height: 44px;
}
ul.addpatientoption li {
  cursor: pointer;
  list-style: none;
  color: #00abaf;
  text-align: center;
}
ul.addpatientoption {
  padding: 10px;
}
ul.addpatientoption li:hover {
  text-decoration: underline;
}
.filterPatlistInner button {
  background: #00abaf;
  margin: 7px 7px 7px 0px;
  padding: 1px 15px;
}
.filterPatlist {
  position: relative;
}
.filterPatlist img {
  width: 12px;
  margin-left: 7px;
}
.darkTheme .filterPatlistInner {
  background: #3f4141;
}
.filterPatlistInner {
  position: absolute;
  width: 244px;
  background: #e7e5e5;
  padding: 15px;
  top: 18px;
  left: 65px;
  z-index: 9999999;
  border-radius: 5px;
}
.alowLocAces1 .addStafSelect {
  z-index: 999999 !important;
}
.alowLocAces1 .alowLocAces1Inner {
  height: 100%;
}
.darkTheme .alowLocAces1 {
  background-color: #404646 !important;
}
@media (max-width: 959px) {
  .alowLocAces1 {
    width: 96% !important;
  }
}
@media (max-width: 599px) {
  .alowLocAces1 .srchAccessLoc {
    overflow-y: auto;
  }
}
.alowLocAces1 {
  width: 945px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgba(0, 8, 8, 0.08);
  margin-top: 35px;
  padding: 0px 2px 6px 2px;
}
.alowLocAces1.\32 2 .srchKm:after {
  bottom: 29px;
}
.darkTheme .tskTabsMob header {
  color: #fff;
  background-color: #192020 !important;
}
.movetoPage input {
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  width: 40px;
}
.darkTheme .vh-section .movetoPage input,
.homeBgDrk .movetoPage input {
  background-color: #192020 !important;
}
.homeBgDrk .movetoPage,
.darkTheme .movetoPage {
  color: #fff !important;
}
@media (max-width: 768px) {
  .attchOpen,
  .attchDone,
  .attchDecline,
  .date-secTask,
  .allInfo1 {
    margin: 5px 0px !important;
    padding: 0px !important;
  }
  .attchNotePart {
    display: block !important;
  }
}
.setAssignedToupper {
  min-width: 83px;
}
@media (max-width: 368px) {
  .flowfilter {
    float: right;
    margin-top: 24px;
  }
}
.searchonLMS {
  border-radius: 8px;
  border: none;
  background: #f2f2f2;
  padding: 9px;
  width: 100%;
}

@media (max-width: 959px) {
  .profilMenuList,
  .moreMenuList {
    top: 30px;
    left: 0px;
  }
  .moreMenuList a {
    display: flex !important;
  }
  .moreMenuList a img {
    width: 17px;
    height: 17px;
  }
}
.setRedColor {
  border: rgb(233, 29, 29) 2px solid;
}
.stepchange-input {
  height: 32px;
  border: 1px SOLID #cfcfcf;
  border-radius: 4px;
  padding: 10px;
}

.darkTheme .allSpeces div {
  background-color: #192020;
  color: #cfcfcf;
} 
@media (max-width: 640px) {
  .vh-section .presOpinionIner td.pivoted img.openScnd {
    margin-top: -15px;
  }
  .spcMgntRght7 {
    text-align: left !important;
  }
  .srvcTable3 th, .srvcTable3 td {
    padding: 15px 10px;
  }
  .newServc {
    margin-top: 0px;
  }
}
.dragdrop-vertical .wrapperdnd,
.dragdrop-vertical .wrapperdnd-light {
  min-width: 320px !important;
}
.dragdrop-horizontal .wrapperdnd,
.dragdrop-horizontal .wrapperdnd-light {
  width: 100%;
}
.quote-list {
  min-height: 10px;
}
.innerdivfordrag {
  margin: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.maindivfordrag {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  height: auto !important;
}
.stepTdotupper ul {
  top: 20px !important;
}
.allInfo1 {
  min-width: 150px;
}
.newassignLft {
  display: flex;
  justify-content: end;
}
.attchNotePart {
  display: flex;
  align-items: center;
}
.setAssignedTo:hover span {
  display: block;
}
.setAssignedTo {
  position: relative;
}
.setAssignedTo span {
  min-width: 92px;
  z-index: 1;
  font-weight: 700 !important;
  text-align: center;
  top: -43px;
  position: absolute;
  left: -35px;
  display: none;
  padding: 5px 6px;
  color: #e5e6e6;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  border-radius: 8px;
  background-color: rgba(0, 8, 8, 0.9);
  box-shadow: 0 8px 24px 0 rgb(0 8 8 / 8%);
}
.showAllAssignedInner {
  padding: 6px 0px;
}
.homeBgDrk .detailCntnt p span {
  color: #d9dada !important;
}
.darkTheme .vh-section .infoSub input,
.darkTheme .genPass input[type="submit"],
.darkTheme .kycSaveChng input[type="submit"],
.darkTheme .timDatSubmit input[type="submit"] {
  background-color: #00abaf !important;
}
.room-img-move img {
  padding: 2px;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  width: 25px;
  height: 25px;
  margin-left: 20px;
}
.setinFullidrh {
  width: 100% !important;
}
@media (max-width: 568px) {
  .demOverView img {
    width: 100%;
    height: auto;
  }
}
.dragdrop-vertical ul li,
.dragdrop-horizontal ul li {
  text-align: left !important;
}
.darkTheme a.academy_ul.stepTdotupper ul {
  background: #192020 !important;
}
.displayBlogCase {
  display: block !important;
}
.dark-confirm button.dischrgInvoic span,
button.dischrgInvoic span {
  color: #00abaf !important;
}
.dark-confirm .dischargeHead h5,
.dark-confirm .dischargeInfo label {
  color: #dbdcdc;
}

.homeBgDrk .langInerFooter.dropdown-menu.show {
  background: #404646 !important;
  color: #cfcfcf !important;
}
.cmntMsgs .editBtn-comment span {
  color: #fff !important;
  padding: 5px;
}
.homeBg.darkTheme.homeBgDrk .profileInfoIner input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dfdfdf !important;
}
.homeBg.darkTheme.homeBgDrk .profileInfoIner input::-moz-placeholder {
  /* Firefox 19+ */
  color: #dfdfdf !important;
}
.homeBg.darkTheme.homeBgDrk .profileInfoIner input:-ms-input-placeholder {
  /* IE 10+ */
  color: #dfdfdf !important;
}
.homeBg.darkTheme.homeBgDrk .profileInfoIner input:-moz-placeholder {
  /* Firefox 18- */
  color: #dfdfdf !important;
}

.activesecRating {
  background-color: #00abaf !important;
  box-shadow: 0 8px 24px 0 rgb(0 171 175 / 32%) !important;
  color: #fff !important;
}
.SApratQuestion h1 {
  margin-bottom: 15px;
}
.darkTheme .asnswerSbmt button span {
  color: #00abaf !important;
}
.SApratQuestion {
  margin: 20px 0px !important;
  background: #e5e5e5;
  padding: 30px;
  border-radius: 18px;
}
.darkTheme .SApratQuestion {
  background: #4046467a !important;
}
.darkTheme .SApratQuestion .QuesMrktUpr {
  background: #404646 !important;
  color: #cfcfcf !important;
}
.darkTheme .err_message,
.homeBgDrk .err_message,
.darkTheme .err_message2,
.homeBgDrk .err_message2 {
  color: #ff6262 !important;
}
.darkTheme .success_message,
.homeBgDrk .success_message {
  color: #00abaf !important;
}
.darkTheme .addServContnt {
  background-color: #404646 !important;
}
@media (max-width: 1300px) {
  .tskOverView2 {
    display: grid !important;
  }
}
.pattern-sec a button:hover {
  background-color: #00abaf;
}
.pattern-sec a .appliedbutton {
  background-color: #00abaf !important;
}
span.ApplyPattern {
  margin-left: 30px;
}
span.ApplyPattern:hover {
  color: #00abaf;
}
span.AppliedPattern {
  margin-left: 30px;
  color: #00abaf;
}
.pattern-sec {
  text-align: center;
}
.pattern-sec a {
  text-align: center;
  font-family: "Muli";
  font-weight: 600;
}
.pattern-sec a button {
  width: 95%;
}
.darkTheme .pattern-sec a button {
  background-color: #404646;
  color: #fff;
}
.darkTheme .pattern-sec a {
  color: #cfcfcf !important;
}
.darkTheme .demOverView {
  background-color: #404646;
}
.darkTheme .demOverView h1 {
  color: #cfcfcf !important;
}
.darkTheme .vh-section input:disabled {
  color: #404646;
}
.darkTheme .vh-section input {
  color: #dfdfdf !important;
  background: transparent !important;
}
.rrSysto3 label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
}
.darkTheme .rrSysto3 label {
  color: #bfc1c1 !important;
}
.darkTheme .cstmSelect1 div,
.homeBgDrk .cstmSelect1 div {
  color: #bfc1c1;
  box-shadow: initial !important;
  outline: none !important;
  background-color: #404646 !important;
}
.darkTheme .addCustom {
  color: #00abaf !important;
}
.darkTheme .editField h5 {
  color: #cfcfcf !important;
}
.darkTheme .rrSysto2 .painAreasimg .marker {
  background: red !important;
}
option {
  color: #000000;
}
.darkTheme option,
.homeBgDrk option {
  color: #cfcfcf;
}
.darkTheme .fillDia .rrSysto div {
  background-color: #404646 !important;
}
.darkTheme .cnfrmDiaMain .fillDia .rrSysto div {
  color: #cfcfcf !important;
}
.cnfrmDiaMain .fillDia .rrSysto div {
  color: #000808;
  font-family: Muli;
}
.homeBgDrk .entryBoxCntnt,
.darkTheme .entryBoxCntnt {
  background-color: #202323 !important;
}
.setStatus a {
  padding-top: 3px;
  padding-bottom: 5px;
  width: 100%;
  display: block;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
}
.infoSub2 label {
  color: #fff !important;
}
.homeBgDrk .emergency-head h1 {
  color: #fcfcfc !important;
}
.darkTheme .dashBoxCntnt,
.darkTheme .alowLocAces,
.darkTheme .slotBoxMain,
.darkTheme .srvcBoxCntnt,
.darkTheme .crtListCntnt,
.darkTheme .wishListCntnt,
.darkTheme .editBoxCntnt,
.darkTheme .trstBoxCntnt,
.darkTheme .qrBoxCntnt,
.darkTheme .dataBoxCntnt,
.darkTheme .infoBoxCntnt,
.darkTheme .rejectBoxCntnt,
.darkTheme .paraBoxCntnt,
.darkTheme .slotBoxCntnt,
.darkTheme .nwDiaCntnt,
.darkTheme .entryBoxCntnt {
  background-color: #404646 !important;
}
.ant-picker-suffix > * {
  vertical-align: top;
  margin-left: -20px !important;
}
.filterAtright {
  text-align: right;
  margin-top: 10px !important;
  margin-bottom: -20px !important;
}
.darkTheme .cntryDrop div:first-child:hover {
  background-color: 404646 !important;
}
img.academyDots {
  padding: 10px 2px !important;
  border: 1px solid #7e8282 !important;
  width: 25px !important;
  border-radius: 8px !important;
  height: auto !important;
}
img.manage-size {
  width: 44px;
  padding: 12px;
}
.darkTheme .insuranceHint,
.darkTheme .profile-language.css-2b097c-container div,
.homeBgDrk .profile-language.css-2b097c-container div,
.darkTheme .profile-language.css-14jk2my-container div,
.homeBgDrk .profile-language.css-14jk2my-container div {
  background-color: #404646 !important;
  color: #fff !important;
}
.darkTheme .cntryDropTop,
.homeBgDrk .cntryDropTop {
  /* border: 1px solid #5d5d5d; */
  border-radius: 2px;
  border: 1px solid #dfdfdf;
}
.cntryDropTop div {
  box-shadow: initial !important;
}
a.addNewPlus img {
  height: 50px;
  width: 50px !important;
  /* margin-left: 9px; */
}
.darkTheme .rrSysto2 div {
  background-color: #404646 !important;
}
.vh-section .srchFilter .comonSel {
  padding-right: 0px !important;
}
.darkTheme .activeOntask img {
  background-color: #00abaf !important;
}
.activeOntask img {
  background-color: #00abaf !important;
}
.addCustom {
  color: #00abaf;
  font-weight: 700;
}
.darkTheme .creatTaskModel {
  background-color: #404646 !important;
}
.darkTheme .creatTaskModel2 {
  background-color: #404646 !important;
}
img.vdoCalNow {
  margin-left: 9px;
}
.homeBg.darkTheme.homeBgDrk .profileInfoIner input {
  background-color: transparent !important;
  color: #dfdfdf !important;
}
a.newacademyul ul li img {
  height: 20px !important;
  padding: 5px;
}

.darkTheme .profilMenuList2 li a img {
  width: 13px !important;
}
.patentInfoTxt {
  width: 100%;
}
.allSpeces {
  width: 100% !important;
}
.allSpeces .css-g1d714-ValueContainer {
  padding: 0px 8px !important;
}
.darkTheme .fillDias .css-yk16xz-control {
  background-color: #202323 !important;
  border: 1px solid #7d7d7d !important;
}
.darkTheme .fillDias div {
  color: #c3c3c3 !important;
  border-color: #404646 !important;
}
.darkTheme .shrtRejctMsg textarea {
  background-color: transparent !important;
}
@media (max-width: 959px) {
  .addFlowRght a {
    margin: 5px 10px !important;
  }
}
.addFlowRght a {
  margin: 0px 10px;
}
.apponTaskhos button {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
}
.darkTheme .serchInput,
.homeBgDrk .serchInput {
  color: #cfcfcf;
  background: #192020;
}
.institute_logo img {
  height: 200px;
  width: auto;
  border-radius: 20px;
}
.darkTheme .medcalFZCntnt {
  background-color: #192020 !important;
  color: #cfcfcf !important;
}
.renameHouseh2 {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  padding: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0px;
  position: relative;
}
.RenameInstitute label {
  padding: 10px;
}
.RenameInstitute button {
  margin: 5px;
  width: 100%;
}
.darkTheme .renameHouseh2,
.darkTheme .RenameInstitute label {
  color: #cfcfcf;
}
.darkTheme p.more-change-staff-img2 {
  color: #c3c3c3 !important;
}
p.more-change-staff-img2 {
  font-family: Muli;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  cursor: pointer;
}
.newAddStepBtn {
  margin-top: 45px;
}
.modAppTabs .MuiTabs-scroller {
  padding: 10px;
}
.darkTheme .modAppTabs .MuiTabs-scroller {
  background: #0c1010;
}

.darkTheme .hideTask .MuiFormControlLabel-label {
  color: #7d8080;
}
.darkTheme .checkboxques {
  background-color: transparent;
}
.backFlow1 {
  margin: 20px 70px !important;
}
.more-change-staff-img2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #404646 !important;
}
.more-change-staff-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.darkTheme .attchDone button span {
  color: #028b66 !important;
}
.darkTheme .TaskSearch {
  border: 1px solid #939393;
  background-color: #192020;
  color: #fafafa;
}
.TaskSearch {
  border: 1px solid #bfc1c1;
  border-radius: 0px;
  background-color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  height: 32px;
  box-sizing: border-box !important;
  color: #000808;
  vertical-align: top;
  padding: 6px 7px;
  width: 34.66%;
  margin-bottom: 0px;
  min-height: 40px;
}
.darkTheme .hideTask {
  background-color: #192020;
  color: #c6dada !important;
}
.darkTheme .rrSysto div .css-12jo7m5 {
  color: #c6dada;
}
.stepTdotupper img {
  padding: 3px !important;
}
.darkTheme a.srchSort {
  border: #f2f2f2 1px solid;
  background: transparent;
}
.darkTheme .allSpec div {
  height: initial !important;
  min-height: initial !important;
}
.allSpec {
  border: none !important;
  outline: none !important;
  height: initial !important;
  min-height: initial !important;
  color: #00abaf !important;
  background: #e1f7f7 !important;
  background-color: #e1f7f7 !important;
  font-weight: 600;
  border-radius: 12px;
}
.allSpec div,
.allSpec div input {
  border: none !important;
  outline: none !important;
  box-shadow: initial !important;
}
.allSpec div {
  color: #00abaf !important;
  background-color: #e1f7f7 !important;
  background: #e1f7f7 !important;
  font-weight: 600;
  padding: 2.3px 2px;
}
.newsec div {
  font-weight: 700 !important;
}
.detailInfo1 {
  padding: 12px;
  margin: 45px 6px 0;
}
.officVisit img {
  margin-left: 15px;
}
.filtersec {
  color: #00abaf;
  font-weight: 700;
  margin: auto 10px;
}
.addFlowIner {
  height: 75vh;
  overflow: auto;
}
.renameButton {
  background-color: #00abaf !important;
  padding: 12px;
  border-radius: 8px;
}
.renameButton span {
  color: #fff !important;
  font-weight: 500;
}
.serchInput {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 6px 6px;
  outline: none;
  margin-right: 0px;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  vertical-align: top;
}
.recaptchaMargin {
  margin-top: 12px;
  margin-bottom: 12px;
}
.addTimeTask1 {
  position: relative !important;
  padding-left: 10px !important;
}
.addTimeTask1span {
  position: absolute !important;
  color: #00abaf !important;
  font-weight: 500;
}
.adminMenuRghtUpr a.academy_ul ul {
  min-width: 200px !important;
}
.darkTheme .fillDia .addStafSelect div {
  background-color: #192020;
}
.movHeadMid {
  width: 60% !important;
}
.movHead div {
  width: 19.3%;
}
.actionList {
  top: 25px !important;
}
.add-group-padding {
  padding-top: 20px;
}
.nwEntrCntntIner {
  height: 75vh !important;
}
.medcalFZCntnt .openScndhrf img {
  height: 20px;
}
.manage-dark-back {
  padding: 9px 8px !important;
  width: 42px !important;
}
.more-open-detail {
  background-image: url("../images/openDetails.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-open-detail {
  background-image: url("../images/openDetails2.png");
}
.more-new-entry {
  background-image: url("../images/addTask.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-new-entry {
  background-image: url("../virtual_images/plusIcon.png");
}
.more-change-staff {
  background-image: url("../images/changeStaff.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-change-staff {
  background-image: url("../images/changeStaff2.png");
}
.more-add-task {
  background-image: url("../images/addTask.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-add-task {
  background-image: url("../virtual_images/plusIcon.png");
}
.more-move-patient {
  background-image: url("../images/openDetails.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-move-patient {
  background-image: url("../images/openDetails2.png");
}
.more-new-speciality {
  background-image: url("../images/openDetails.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-new-speciality {
  background-image: url("../images/openDetails2.png");
}
.more-assign-room {
  background-image: url("../images/openDetails.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-assign-room {
  background-image: url("../images/openDetails2.png");
}
.more-discharge-patient {
  background-image: url("../images/dischargePatient.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-discharge-patient {
  background-image: url("../images/dischargePatient2.png");
}
.more-remove-entry {
  background-image: url("../images/removePatient.png");
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  width: initial;
  min-width: 22px;
  display: inline-block;
}
.darkTheme .more-remove-entry {
  background-image: url("../images/removePatient2.png");
}

.attchDone {
  margin: 0px 38px;
}
.attchDone button {
  border-radius: 4px;
  background-color: #ddf8f1;
  color: #028b66;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
  padding: 7px 8px;
}
.attchDone button label {
  height: 8px;
  width: 8px;
  background-color: #1dd1a1;
  border-radius: 12px;
  margin-right: 7px;
  margin-bottom: 0px;
  font-family: Muli;
}
.que-mbottom button {
  margin-bottom: 10px;
}
.dtlCountRm a:last-child {
  margin-left: 14px;
}
.setSpcSpecList {
  margin: 10px;
}
/* Start of Create a Task */
.createTask {
  margin-left: 12px !important;
}
.creatTaskModel {
  width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-right: 2px;
  padding-top: 18px;
  padding-bottom: 16px;
  border-radius: 12px;
}
.creatLbl {
  padding: 0px 20px;
  border-bottom: 1px solid #dedcdc;
}
.creatLblClose {
  text-align: right;
}
.creatLblClose img {
  height: 26px;
  width: initial;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #cacaca;
  padding: 4px;
}
.creatLbl label {
  color: #000808;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  font-family: "Mulish", sans-serif;
  margin-bottom: 12px;
}
.creatDetail {
  padding: 18px 24px 0px;
}
.creatInfoIner label {
  color: #404646;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
}
.creatInfoIner input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  width: 100%;
  color: #000808;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 44px;
}
.dueOn {
  padding: 8px;
}
.dueOn .react-date-picker {
  width: 100%;
}
.dueOn .ant-picker {
  margin: 0px !important;
  padding: 14px;
}
.dueOn .react-date-picker__wrapper {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  color: #000808;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 44px;
  width: 100%;
}
.creatInfoIner input::-webkit-input-placeholder {
  color: #bfc1c1;
}
.creatInfoIner input::-moz-placeholder {
  color: #bfc1c1;
}
.creatInfoIner input:-ms-input-placeholder {
  color: #bfc1c1;
}
.creatInfoIner input:-moz-placeholder {
  color: #bfc1c1;
}
.createDegre {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  font-family: "Mulish", sans-serif;
}
.taskDescp textarea {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  width: 100%;
  color: #000808;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  min-height: 121px;
}
.specialFor {
  position: relative;
}
.specialFor img {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.hideTask label {
  margin-bottom: 0px;
  margin-right: 0px;
}
.hideTask .MuiCheckbox-colorSecondary {
  padding: 0px 8px;
}
.hideTask .MuiFormControlLabel-label {
  font-family: "Mulish", sans-serif;
  color: #404646;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}
.hideTask span.MuiIconButton-label {
  color: #00abaf;
}
.dueOn input {
  min-height: initial !important;
  padding: 0px !important;
  border: none !important;
}
.attachmentDrag div {
  justify-content: center;
  min-height: 116px;
  width: 100%;
  border: 2px dashed #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}
.attachmentDrag p {
  color: #7f8383;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0px;
}
.attachmentDrag span {
  color: #00abaf;
  margin-left: 9px;
  text-decoration: underline;
}
.attachmentDrag h4 {
  color: #7f8383;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  margin-top: 8px;
}
.saveTasks button {
  background-color: rgb(0 171 175) !important;
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  width: 100%;
  text-transform: inherit;
  min-height: 40px;
  margin-top: 0px;
}
.addTime button {
  height: 44px;
  width: 78px;
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 28px;
  margin-left: 6px;
  color: #7f8383;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  font-family: "Mulish", sans-serif;
  text-transform: initial;
  padding: 0px;
}
.acitveButton {
  color: #7f8383 !important;
  background-color: #f2f2f2 !important;
}
.positionDrop .spcMgntUpr {
  padding: 3px 3px !important;
} 
.flowProfilRght {
  padding-left: 10px;
  width: 85%;
}
.nwModelCloseBtn {
  text-align: right;
  /* margin-bottom: 4px; */
  margin-right: 20px;
  margin-top: 20px;
}
.assignSec img {
  cursor: pointer;
}
.tasklistName img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 2px;
}
ul.patientHint {
  z-index: 9;
  position: relative;
  padding-left: 0px;
  border-radius: 7px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 13px 0 rgba(0, 0, 0, 0.19);
  overflow: auto;
  background: #fff;
  margin-top: 5px;
  padding: 3px 0px;
}
ul.patientHint li {
  padding: 8px 5px 8px 12px;
}
/* .flowInfo {
    padding: 10px;
} */

.checkDots ul div {
  width: 300px !important;
}

.stepTdotupper ul li {
  width: 100% !important;
  min-width: 100% !important;
}
.stepTdotupper ul {
  min-width: 270px !important;
}
.stepTdot {
  width: 27px;
  border-radius: 7px;
}
.sevicessection {
  width: 100%;
  margin-bottom: 20px;
  font-weight: bold;
}
.sevicessection .mr_sel .css-yk16xz-control {
  height: 50px !important;
  min-height: initial;
}
.spacemanageDel img {
  height: 17px;
}
.deleteimg {
  width: 17px;
  height: 15px;
  background: url("../virtual_images/bin.svg") no-repeat;
  display: inline-block;
}
.spacemanageDel:hover > .deleteimg {
  background: url("../virtual_images/binred.svg") no-repeat !important;
}
.spacemanageDel:hover {
  border: 1px solid #ee5253 !important;
  color: #ee5253 !important;
}
.spacemanageDel {
  font-size: 12px;
  padding: 8px;
  border: 1px solid #d0d1d1;
  border-radius: 5px;
  margin-left: 20px;
  color: #d0d1d1;
  cursor: pointer;
}
.sevicessection .mr_sel input {
  min-height: initial !important;
  height: initial !important;
}
.vh-menu .menuItems ul li:last-child {
  margin-top: 0px !important;
}
.c-pointer {
  cursor: pointer;
}
.active-menu img {
  background-color: #00abaf !important;
}
.specialuty-more {
  float: right;
}
.openScndhrf a {
  color: inherit;
}
.spcMgntRght7 {
  text-align: right;
}
/* drag drop */
.dragdrop-vertical ul li {
  list-style: none;
  min-width: 320px;
  display: inline-grid;
  margin-bottom: 15px;
}
.dragdrop-vertical ul {
  padding-left: 0px;
  padding-right: 0px;
}
.dragdrop-horizontal ul li {
  list-style: none;
}
.dragdrop-horizontal ul {
  padding-left: 15px;
  padding-right: 15px;
}
.dragdrop-horizontal .detailInfo {
  min-height: 200px;
}
.list-z-index {
  z-index: 9999 !important;
}
.dragdrop-horizontal ul {
  padding-left: 15px;
  padding-right: 15px;
  background: #f2f2f2;
  border-radius: 12px;
}
.vhfield-add {
  width: 100%;
}
.specialitybutton {
  border-radius: 4px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  box-shadow: initial !important;
  font-family: "Mulish", sans-serif;
  text-transform: capitalize;
  padding: 8px 4px;
  word-break: break-all;
  width: 122px;
  text-align: center;
  margin-right: 5px;
}
.specialitybutton-parent {
  text-align: center;
}
/* Dark theme css */
.MenuWeb .menuItems ul {
  padding-left: 0px;
}
.MenuWeb .menuItems ul li {
  list-style-type: none;
  margin-bottom: 35px;
}
.MenuWeb .menuItems ul li a img {
  background: transparent;
  width: 42px;
  border: 1px solid #eceaea;
  border-radius: 8px !important;
  padding: 8px 8px;
}
.MenuWeb .menuActv img {
  border: none !important;
}
/* .MenuWeb .menuItems ul li:nth-child(4) a img{
    padding: 10px 8px !important;
} */
.MenuWeb .menuItems ul li a {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
}
.MenuWeb .menuItems ul li a span {
  display: block;
  color: #7f8383;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 12px;
}
.MenuWeb .menuItems ul li:last-child {
  margin-bottom: 0px !important;
  margin-top: 40px;
}
.flag-select__option:not(.flag-select__option--placeholder):hover,
.flag-select__option:not(.flag-select__option--placeholder):focus {
  background: transparent !important;
}
.wrdEdtDel div:last-child img {
  height: 13px;
}
.wrdCollect img {
  vertical-align: text-bottom;
}
@media (max-width: 568px) {
  .roomName .MuiGrid-root.MuiGrid-container div {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .roomName .MuiGrid-root.MuiGrid-container label {
    display: none;
  }
  .roomName .MuiGrid-root.MuiGrid-container .label1 {
    color: #404646;
    font-size: 13px;
    font-weight: 600;
    display: block;
    padding-bottom: 7px;
  }
  .roomName .MuiGrid-root.MuiGrid-container .label2 {
    color: #404646;
    font-size: 13px;
    font-weight: 600;
    display: block;
    padding-bottom: 7px;
  }
  .roomName .MuiGrid-root.MuiGrid-container input {
    margin-bottom: 0px !important;
  }
  .roomName .MuiGrid-container {
    margin-bottom: 22px !important;
  }
  .addSpeclClose {
    margin-bottom: 10px;
  }
}
.darkTheme .MenuLeftUpr {
  background: #404646;
  border-right: 2px solid #00abaf;
}
.darkTheme .srchPatient {
  background-color: #404646;
}
.darkTheme .flowArea {
  background-color: #404646;
}
.darkTheme .addFlow h1 {
  color: #fafafa;
}
.darkTheme .srchLft input {
  color: #bfc1c1;
}
.darkTheme .srchLft input::-webkit-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .srchLft input::-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .srchLft input:-ms-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .srchLft input:-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .cardioAreaUpr {
  border-radius: 8px;
  background-color: #2f3535;
}
.darkTheme .cardioArea label {
  color: #bfc1c1;
}
.darkTheme a.rghtHalf span,
.darkTheme a.notePoint span {
  color: #bfc1c1;
}
.darkTheme .srchLft {
  border-right: 1px solid #828282;
}
.darkTheme .wardInfo a {
  color: #bfc1c1;
}
.darkTheme .cardioArea p {
  color: #a2a2a2;
}
.darkTheme .receLbl label {
  color: #bfc1c1;
}
.darkTheme .spcMgnt h1 {
  color: #fafafa;
}
.darkTheme .breadCrumbUpr {
  background-color: #404646;
}
.darkTheme .roomBreadCrumb span {
  color: #bfc1c1;
}
.darkTheme .roomBreadCrumb ul li label {
  color: #bfc1c1;
}
.darkTheme .cardioGrupBtn {
  border-bottom: 1px solid #545454;
}
.darkTheme .wardsGrupUpr {
  background-color: #404646;
}
.darkTheme .wardsGrup {
  background-color: #192020;
}
.darkTheme .roomsNum2 ul li {
  color: #bfc1c1;
}
.darkTheme .spcMgntRght a {
  border: 1px solid #bfc1c1;
}
.darkTheme .roomsNum2 ul li span {
  color: #bfc1c1;
}
.darkTheme .roomsNum2 ul li::after {
  color: #00abaf;
}
.darkTheme .roomsNum2 ul li::before {
  border-left: 1px solid #00abaf;
}
.darkTheme .roomsNum2 {
  border-bottom: 1px solid #444444;
}
.darkTheme .bm-menu {
  background-color: #404646 !important;
}
.darkTheme .MenuMob {
  background: #404646;
}
.darkTheme .cardioGrup {
  background: #192020;
}
.darkTheme .cardiotabIner.Mui-selected {
  background-color: transparent !important;
}
.darkTheme .drList2 {
  background-color: #192020;
}
.darkTheme .drListLft2 span {
  color: #bfc1c1;
}
.darkTheme .drListMain2:hover {
  background-color: #404646;
}
.darkTheme .drListRght2 label {
  color: #00abaf;
}
.darkTheme .drListRght2 p {
  color: #bfc1c1;
}
.darkTheme .drCollection {
  background-color: #192020;
}
.darkTheme .medcalFZCntnt {
  background-color: #192020;
}
.darkTheme .medcalFZCntnt label {
  color: #00abaf;
}
.darkTheme .journalAdd h1 {
  color: #fafafa;
}
.darkTheme .newStaffUpr {
  background-color: #404646;
}
.darkTheme .newStaff p span {
  color: #bfc1c1;
}
.darkTheme .entryInfo li label {
  color: #bfc1c1;
}
.darkTheme .roomsNum ul li {
  color: #bfc1c1;
}
.darkTheme .newStaff label {
  color: #fafafa;
}
.darkTheme .cmpleteTask p {
  color: #bfc1c1;
}
.darkTheme .exmBtn a {
  color: #00b0db !important;
}
.darkTheme .newStaff p {
  color: #bfc1c1;
}
.darkTheme .bilingDocs {
  background-color: #404646;
}
.darkTheme .billLbl label {
  color: #bfc1c1;
}
.darkTheme .yearSec label {
  color: #bfc1c1;
}
.darkTheme .issuePrice label {
  color: #bfc1c1;
}
.darkTheme .profileDescp {
  background-color: #404646;
}
.darkTheme .prfilHghtLft p,
.darkTheme .prfilHghtRght p {
  color: #bfc1c1;
}
.darkTheme .persBlodMesur {
  background-color: #404646;
}
.darkTheme .presureData h3 {
  color: #bfc1c1;
}
.darkTheme .presureData p {
  color: #bfc1c1;
}
.darkTheme .drVisit {
  background-color: #404646;
}
.darkTheme .drVisit h3 {
  color: #bfc1c1;
}
.darkTheme .drVisitData label {
  color: #bfc1c1;
}
.darkTheme .cardioCount a {
  color: #00abaf !important;
}
.darkTheme .neuroCntLft a {
  color: #00abaf !important;
}
.darkTheme .cardioInfo {
  border-bottom: 1px solid #757575;
}
.darkTheme .drVisitData p {
  color: #bfc1c1;
}
.darkTheme .comeAppoint {
  background-color: #404646;
}
.darkTheme .upcomView label {
  color: #bfc1c1;
}
.darkTheme .oficVisit label {
  color: #bfc1c1;
}
.darkTheme .oficVisit a {
  color: #bfc1c1 !important;
}
.darkTheme .neuroSection h3 {
  color: #bfc1c1;
}
.darkTheme .neuroSection a {
  color: #bfc1c1 !important;
}
.darkTheme .lstDocs {
  background-color: #404646;
}
.darkTheme .lstView label {
  color: #bfc1c1;
}
.darkTheme .metroPro h5 {
  color: #bfc1c1;
}
.darkTheme .metroDoctor a {
  color: #bfc1c1 !important;
}
.darkTheme a.presSecAncr:hover {
  background-color: #192020;
}
.darkTheme .srchFilter {
  background-color: #404646;
}
.darkTheme .descpInerRght {
  background-color: #404646;
}
.darkTheme .icd_num label,
.darkTheme .bp_hg label,
.darkTheme .diryNotes_hedng label {
  color: #bfc1c1;
}
.darkTheme .icd_num a,
.darkTheme .bp_hg p {
  color: #bfc1c1 !important;
}
.darkTheme .markCntntImg span,
.darkTheme .markMDCntntImg span {
  color: #bfc1c1;
}
.darkTheme .detailMark span.Collapsible__trigger {
  background: #192020;
  color: #bfc1c1;
}
.darkTheme .diagnoBy label,
.darkTheme .painTypeBy label,
.darkTheme .bloodPreBy label {
  color: #efefef;
}
.darkTheme .diagnoBy span,
.darkTheme .painTypeBy span,
.darkTheme .bloodPreBy span {
  color: #bfc1c1;
}
.darkTheme .detailCntnt p {
  color: #bfc1c1;
}
.darkTheme .imgsFile label {
  color: #bfc1c1;
}
.darkTheme .conPain_num label {
  color: #bfc1c1;
}
.darkTheme .conPain_num p {
  color: #bfc1c1;
}
.darkTheme .conPain_Img span,
.darkTheme .conPain_MDCImg span,
.darkTheme .bpJohnImg span {
  color: #bfc1c1;
}
.darkTheme .conPainArea label {
  color: #bfc1c1;
}
.darkTheme .painIntencty label,
.darkTheme .condIntencty label {
  color: #bfc1c1;
}
.darkTheme .painIntencty a,
.darkTheme .condIntencty a {
  color: #bfc1c1;
}
.darkTheme .painIntencty input[type="range"],
.darkTheme .condIntencty input[type="range"] {
  background: transparent;
}
.darkTheme .painTypeCntnt p {
  color: #bfc1c1;
}
.darkTheme .diryCntntImg span,
.darkTheme .diryMDCntntImg span {
  color: #bfc1c1;
}
.darkTheme .diryCntntSec p {
  color: #bfc1c1;
}
.darkTheme .diryCntntSec h3 {
  color: #bfc1c1;
}
.darkTheme .diryCntntSec ul li,
.darkTheme .diryCntntSec ul li a {
  color: #bfc1c1 !important;
}
.darkTheme .descpCntntLft a {
  background-color: #404646;
  color: #00abaf !important;
}
.darkTheme .descpCntntLft a span {
  color: #bfc1c1 !important;
}
.darkTheme .roomBreadCrumb3 ul li label {
  color: #bfc1c1;
}
.darkTheme .roomBreadCrumb3 span {
  color: #bfc1c1;
}
.darkTheme .nwSpclSec {
  background: #192020;
}
.darkTheme .nwSpclSec p {
  color: #bfc1c1;
}
.darkTheme .addSpclSec {
  background: #192020;
}
.darkTheme .addSpclSec p {
  color: #bfc1c1;
}
.darkTheme .wardsGrup3 {
  background: #192020;
}
.darkTheme .roomsNum3 {
  border-top: none !important;
}
.darkTheme .tskTabs button {
  background-color: #404646 !important;
  color: #fafafa !important;
  /* border: 1px solid #00686bad !important; */
}
.darkTheme .presPkgIner1 header.presTabsUpr,
.darkTheme .presTabs {
  background-color: #404646 !important;
}
.darkTheme .presTabs button span {
  color: #bfc1c1 !important;
}
.darkTheme .taskAllTab {
  background-color: #404646;
}
.darkTheme .neuroList {
  background-color: #192020;
  border: 1px solid #585858;
}
.darkTheme .nuroTask p {
  color: #bfc1c1;
}
.darkTheme .noteLink a {
  color: #bfc1c1 !important;
}
.darkTheme .roomsNum3 ul li {
  color: #bfc1c1;
}
.darkTheme .roomsNum3 ul li span {
  color: #bfc1c1;
}
/* For dark theme model css */
.darkTheme .addSpeclContnt {
  background: #404646;
}
.darkTheme .addSpeclLbl label {
  color: #bfc1c1;
}
.darkTheme .enterSpcl input::-webkit-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .enterSpcl input::-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .enterSpcl input:-ms-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .enterSpcl input:-moz-placeholder {
  color: #bfc1c1;
}

.darkTheme .creatInfoIner input::-webkit-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .creatInfoIner input::-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .creatInfoIner input:-ms-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .creatInfoIner input:-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .enterSpcl input,
.darkTheme .creatInfoIner input,
.darkTheme .taskDescp textarea,
.darkTheme .creatInfoIner .ant-picker {
  background: #192020;
  color: #bfc1c1;
  border: 1px solid #8e8e8e8a;
}
.darkTheme .creatInfoIner ul.patientHint {
  box-shadow: initial !important;
  background: #404646 !important;
}
.darkTheme .plusWards {
  background: #192020;
}
.darkTheme .addWardsRoom {
  background-color: #192020;
}
.darkTheme .addWardsUpr {
  background-color: #404646;
}
.darkTheme .addWardsIner label,
.darkTheme .roomName label {
  color: #bfc1c1;
}
.darkTheme ul.subSpclList {
  border: 1px solid #7d7d7d;
  background-color: #192020;
}
.darkTheme .addWardsIner input {
  background-color: #192020;
  color: #fafafa;
  border: 1px solid #7b7b7b;
}
.darkTheme .wrdsBtn {
  background: #404646;
}
.darkTheme .wrdsBtn {
  border-top: 1px solid #00abaf;
}
.darkTheme .roomRmv a {
  border: 1px solid #bfc1c1 !important;
  background-color: #404646 !important;
}
.darkTheme ul.subSpclList label {
  color: #bfc1c1;
}
.darkTheme .wardListSec {
  background-color: #404646;
}
.darkTheme .wrdCollect label,
.wrdEdtDel span {
  color: #bfc1c1;
}
.darkTheme .enterSpcl input {
  color: #fafafa;
}
.darkTheme .wrdEdtDelBtn button {
  border: 1px solid #656565;
  background-color: #192020;
}

/* .questionborder {
    border-style: ridge;
    border-width: 2px;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
  } */

/* .add_a_question a {
    color: #00ABAF !important;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    margin-left: 10px;
} */

.remove_a_question a {
  color: #ff0000 !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-left: 10px;
}
.colorBtnUpr div:first-child {
  float: right;
}
.darkTheme .rvewFiles {
  background-color: #404646;
}
.darkTheme .rvwCadio h3 {
  color: #fafafa;
}
.darkTheme .rvwCadio p {
  color: #bfc1c1;
}
.darkTheme .asignLft label,
.asignRghtUpr label {
  color: #bfc1c1;
}
.darkTheme .asignRght span {
  color: #fafafa;
}
.darkTheme .asignRght p {
  color: #bfc1c1;
}
.darkTheme .addDue button {
  background: #192020 !important;
  border: 1px solid #676767 !important;
}
.darkTheme .addDue label {
  color: #bfc1c1;
}
.darkTheme .multiDescp p {
  color: #bfc1c1;
}
.darkTheme .multiDescp label {
  color: #fafafa;
}
.darkTheme .assignSec img {
  border: 1px solid #676767;
  background-color: #192020;
}
.darkTheme .assignSec label {
  color: #bfc1c1;
}
.darkTheme .browseHere {
  border: 2px dashed #676767;
  background-color: #0c1010;
}
.darkTheme .browseHere a {
  color: #fafafa !important;
}
.darkTheme .attchFile p {
  color: #bfc1c1;
}
.darkTheme .updateInfo p {
  color: #fafafa;
}
.darkTheme .comentLbl label {
  color: hsl(0, 0%, 98%);
}
.darkTheme .cmntMsgs label {
  color: #fafafa;
}
.darkTheme .cmntMsgs span {
  color: #bfc1c1;
}
.darkTheme .cmntMsgs p {
  color: #bfc1c1;
}
.darkTheme .cmntMsgsCntnt {
  background-color: #0c1010;
}
.darkTheme .addComit textarea {
  color: #bfc1c1;
  background-color: #192020;
}
.darkTheme .attchFile label {
  color: #fafafa;
}
.darkTheme .asignLft div a:last-child {
  background-color: #0c1010;
  color: #fafafa;
}
.darkTheme .movPtnt {
  background: #404646;
}
.darkTheme .movPtntLbl label {
  color: #fafafa;
}
.darkTheme .moveClient label {
  color: #e0e0e0;
}
.darkTheme .moveClient p {
  color: #fafafa;
}
.darkTheme .spclSelect div,
.darkTheme .spclSelect2 div {
  background: #0c1010;
  color: #dfdfdf;
  border-color: #676565;
}
.darkTheme .spcldropdown label,
.spcldropdown2 label {
  color: #e0e0e0;
}
.darkTheme .addStaff {
  background: #404646;
}
.darkTheme .stafRght a {
  background-color: #192020;
  border: 1px solid #696969;
}
.darkTheme .addStaffLbl label {
  color: #fafafa;
}
.darkTheme .addStafClient p,
.darkTheme .stafLft span {
  color: #fafafa;
}
.darkTheme .addStafClient label {
  color: #e0e0e0;
}
.darkTheme .addStafSelect div {
  background: #192020;
  color: #dfdfdf;
  border-color: #7d7d7d;
  font-family: Muli;
  box-shadow: initial !important;
  outline: none !important;
}
.darkTheme input[type="submit"]:disabled {
  background-color: #1aabae !important;
  color: #fff !important;
}
.darkTheme .fltrClear {
  background: #404646;
}
.darkTheme .clspopup {
  text-align: right;
}
.darkTheme .clspopup a {
  border: 1px solid #909090;
  padding: 8px 8px;
  border-radius: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.darkTheme .clspopup img {
  height: 15px;
  width: initial;
}
.darkTheme header.fltrTabs {
  background-color: #0c1010 !important;
}
.darkTheme .fltrLbl label {
  color: #fafafa;
}
.darkTheme .fltrInput label,
.pantNames label,
.spclDropMain .spclDropLbl {
  color: #bfc1c1;
}
.darkTheme .fltrTabs button,
.fltrtabIner.Mui-selected {
  background-color: #333333 !important;
}
.darkTheme .addInput span.MuiTypography-root.MuiFormControlLabel-label,
.darkTheme .addInput span {
  color: #00abaf;
}
.darkTheme .fltrInput input {
  border: 1px solid #676767;
  color: #dfdfdf;
  background-color: #0c1010;
}
.darkTheme .fltrForm .search-wrapper {
  border: 1px solid #676767;
  color: #dfdfdf;
  background-color: #0c1010;
}
.darkTheme .aplyFltr {
  border-top: 1px solid #989898;
}
.darkTheme .spclDropDwn {
  background-color: #0c1010;
}
.darkTheme .spclDropUpr {
  border: 1px solid #676767;
}
.darkTheme .spclDropDwn label {
  color: #fafafa;
}
.darkTheme .spclDropDwn p {
  color: #bfc1c1;
}
.darkTheme .addWrnContnt {
  background: #404646;
}
.darkTheme .profileMain {
  background-color: #0c1010;
  border: 1px solid #737272;
}
.darkTheme .addFlowLbl img {
  border-radius: 6px;
}
.darkTheme .addFlowLbl {
  border-bottom: 1px solid #bfc1c1;
}
.darkTheme .patentInfo label {
  color: #bfc1c1;
}
.darkTheme.addFlowContnt {
  background: #404646;
}
.darkTheme .patentInfo input {
  border: 1px solid #8e8e8e8a;
  background-color: #192020;
  color: #fafafa;
}
.darkTheme .addFlowLbl label {
  color: #c5c5c5;
}
.darkTheme .presOpinionIner {
  background-color: #404646;
}
.darkTheme .presOpinionIner thead th {
  color: #fafafa;
}
.darkTheme .presOpinionIner td.pivoted {
  color: #bfc1c1;
}
.darkTheme .roomMgntLft h1 {
  color: #bfc1c1;
}
.darkTheme .neuroRoom {
  background-color: #404646;
}
.darkTheme .neuroRoom ul li {
  color: #bfc1c1;
}
.darkTheme .roomsList {
  background-color: #404646;
}
.darkTheme .drList {
  background-color: #192020;
}
.darkTheme .drListRght label {
  color: #fafafa;
}
.darkTheme .drListRght p {
  color: #bfc1c1;
}
.darkTheme .drListLft span {
  color: #00abaf;
}
.darkTheme .drListRght:hover {
  background-color: #404646;
}
.darkTheme .profilePkgIner2 {
  background-color: #404646;
}
.darkTheme .profileId {
  background-color: #192020;
}
.darkTheme .profileIdLft label {
  color: #fafafa;
}
.darkTheme .profileIdLft span {
  color: #bfc1c1;
}
.darkTheme .profileInfoIner input {
  background-color: #192020;
  border: none;
  color: #fafafa;
  min-height: 36px !important;
}
.darkTheme .react-toggle-track {
  border: 1px solid #00abaf;
}
.darkTheme .mr_sel div:first-child {
  background: transparent !important;
  border: none;
  height: initial !important;
  min-height: initial !important;
  color: #fafafa;
}
.darkTheme .mr_sel div {
  box-shadow: initial !important;
  outline: none !important;
}
.darkTheme .cntryDrop div:first-child,
.darkTheme .languageSpk div:first-child {
  background: #404646 !important;
  border: none;
  height: initial !important;
  min-height: initial !important;
  color: #fafafa;
}
.darkTheme .profileInfoDate .react-date-picker__wrapper {
  background-color: #192020;
  color: #fafafa;
  border: none;
}
.darkTheme .profileInfoDate .react-date-picker__wrapper svg {
  stroke: #ffffff;
}
.darkTheme .profileInfoDate .react-date-picker__inputGroup__input {
  color: #fafafa;
}
.darkTheme .profileInfo input::-webkit-input-placeholder {
  color: #484848;
}
.darkTheme .profileInfo input::-moz-placeholder {
  color: #484848;
}
.darkTheme .profileInfo input:-ms-input-placeholder {
  color: #484848;
}
.darkTheme .profileInfo input:-moz-placeholder {
  color: #484848;
}
.darkTheme .react-tel-input .flag-dropdown {
  background-color: #242b2b;
  border: 1px solid #676767;
}
.darkTheme .profileInfoDate a {
  background-color: #192020;
}
.darkTheme .profileInfoIner label {
  color: #00abaf;
}
.darkTheme .insrnceTbl h3 {
  color: #fafafa;
}
.darkTheme .insrnceTbl table th {
  color: #00abaf;
  /* font-size: 15px; */
}
.darkTheme .insrnceTbl table td {
  color: #bfc1c1;
}
.darkTheme .QuesMrktUpr {
  background-color: #404646;
}
.darkTheme .QuesMrktUpr .quesitonPattient,
.darkTheme .QuesMrktUpr h1 {
  color: #bfc1c1;
}
.darkTheme .onlineBox span {
  color: #bfc1c1;
}
.darkTheme .QuesMrktUpr p {
  color: #bfc1c1;
}
.darkTheme .rateExp h3 {
  color: #bfc1c1;
}
.darkTheme .hintsLbl h2 {
  color: #bfc1c1;
}
.darkTheme .rateExp ul li {
  border: 1px solid #828282;
  background-color: #192020;
}
.darkTheme .rateExp ul li:hover {
  background-color: #00abaf;
}
.darkTheme .drftDwnload {
  background-color: #404646;
}
.darkTheme .draftDateLft label {
  color: #bfc1c1;
}
.darkTheme .draftDateLft span {
  color: #bfc1c1;
}
.darkTheme button.downloadDots {
  border: 1px solid #949494;
  background-color: transparent;
}
.darkTheme .srvcContent {
  background-color: #404646;
}
.darkTheme .invoiceForm input[type="text"] {
  background-color: #192020;
  color: #bfc1c1;
}
.darkTheme .patntDropDwn {
  background-color: #192020;
  border: 1px solid #01696b;
}
.darkTheme .patntDropDwn label {
  color: #bfc1c1;
}
.darkTheme .invoiceForm label {
  color: #fafafa;
}
.darkTheme .cstmSelect div {
  background-color: #192020 !important;
  color: #bfc1c1;
  box-shadow: initial !important;
  outline: none !important;
}
.darkTheme .cstmSelect {
  background-color: #192020 !important;
  border: 1px solid #8e8e8e8a;
}
.darkTheme .cstmSelect div {
  border: none !important;
}
.darkTheme .srvcTable h3 {
  color: #fafafa;
}
.darkTheme .srvcTable td p {
  color: #bfc1c1;
}
.darkTheme .srvcTable th {
  color: #fafafa;
}
.darkTheme .srvcTable td {
  color: #bfc1c1;
}
.darkTheme .srvcTable td label {
  color: #fafafa;
}
.darkTheme .xRay-edit button {
  border: 1px solid #949494;
  background-color: transparent;
}
.darkTheme .addCstmField input[type="text"] {
  background-color: #192020;
  color: #bfc1c1;
  border: 1px solid #8e8e8e8a;
}
.darkTheme .addCstmField input[type="text"]::-webkit-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .addCstmField input[type="text"]::-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .addCstmField input[type="text"]:-ms-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .addCstmField input[type="text"]:-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .invoiceAmnt p {
  color: #bfc1c1;
}
.darkTheme .invoiceAmnt label {
  color: #fafafa;
}
.darkTheme .invoiceAmnt div button:last-child {
  color: #00abaf;
}
.darkTheme .staticHeading h1 {
  color: #fafafa;
}
.darkTheme .staticsAmt {
  background-color: #404646;
}
.darkTheme .staticsAmt label {
  color: #bfc1c1;
}
.darkTheme .staticsAmt p {
  color: #bfc1c1;
}
.darkTheme .patntFlow {
  background-color: #404646;
}
.darkTheme .patntFlow p {
  color: #fafafa;
}
.darkTheme .patntFlow p {
  border-bottom: 1px solid #686868 !important;
}
.darkTheme .patntFlowIner label {
  color: #bfc1c1;
}
.darkTheme .modeChngUpr {
  background-color: #404646;
}
.darkTheme .staffLastMnth a,
.darkTheme .invoicLastMnth a {
  color: #fafafa !important;
}
.darkTheme .patntFlowIner .MuiGrid-grid-md-4 {
  color: #00abaf;
}
.darkTheme .modeChng label {
  color: #bfc1c1;
}
.darkTheme .modeChng p {
  color: #bfc1c1;
}
.darkTheme .staffGraphUpr {
  background-color: #404646;
}
.openScandhrf1 img {
  margin-top: 20px;
}
.darkTheme .invoicHsptalIner.Mui-selected {
  background-color: transparent !important;
}
.darkTheme button.invoicHsptalIner span {
  color: #fafafa;
}
.darkTheme button.Mui-selected span {
  color: #00abaf !important;
}
.darkTheme .cardioGrupBg {
  background: #404646;
}
.darkTheme .srvcTable3 {
  background-color: #404646;
}
.darkTheme .srvcTable3 th,
.darkTheme .srvcTable3 td {
  color: #fafafa;
}
.darkTheme .srvcTable3 td label {
  color: #fafafa;
}
.darkTheme .srvcTable3 td p {
  color: #bfc1c1;
}
.darkTheme .settingInfo a,
.homeBgDrk .settingInfo a {
  background-color: transparent;
  border: 1px solid #7f8383;
}
.darkTheme .dataPaginationLft p {
  color: #fafafa;
}
.darkTheme .srvcTable3 tbody tr {
  border-bottom: 1px solid #6b6b6b;
}
.darkTheme .dataPaginationRght p span,
.darkTheme .dataPaginationRght p a {
  color: #fafafa !important;
}
.darkTheme .dataPaginationRght p a:last-child {
  color: #00abaf !important;
}
.darkTheme .srvcTable3 thead tr {
  border-bottom: 1px solid #989898;
}
.darkTheme .srvcDots button {
  border: 1px solid #6b6b6b;
  background-color: transparent;
}
.darkTheme .actionList {
  background-color: #192020;
}
.darkTheme .actionList li {
  color: #bfc1c1;
}
.darkTheme .actionList {
  border: 1px solid #777777;
}
.darkTheme .setStatus li:first-child {
  border: 1px solid #777777;
}
.darkTheme .addPatient h1 {
  color: #bfc1c1;
}
.darkTheme .adPatntTabMain {
  background-color: #404646;
}
.darkTheme .profileInfoDate label {
  color: #00abaf;
}
.darkTheme .adPatnttabIner.Mui-selected {
  background-color: transparent !important;
}
.darkTheme .userInfo h2 {
  color: #fafafa;
}
.darkTheme .adPatntTabs button,
.darkTheme .userInfo h3,
.darkTheme .profileInfoIner h5 {
  color: #bfc1c1 !important;
}
.darkTheme .extSetting h1 {
  color: #fafafa;
}
.darkTheme .bilingTabUpr {
  background-color: #404646;
}
.darkTheme .billTabs button {
  color: #fafafa !important;
}
.darkTheme .billtabIner.Mui-selected {
  background-color: transparent !important;
}
.darkTheme .billInfoData {
  background-color: #404646;
}
.darkTheme .billInfoData th {
  color: #fafafa;
}
.darkTheme .billInfoData td {
  color: #bfc1c1;
}
.darkTheme .billSeting a {
  background-color: #192020;
}
.darkTheme .billDots button {
  border: 1px solid #737373;
  background-color: #192020;
}
.darkTheme .billPaginationLft p,
.darkTheme .billPaginationRght p {
  color: #fafafa;
}
.darkTheme .tskOverView {
  background-color: #4046467a;
}
.darkTheme .tskOverView h1 {
  color: #00abaf;
}
.darkTheme .taskNum {
  background-color: #404646;
}
.darkTheme .taskNum p {
  color: #fafafa;
}
.darkTheme .taskNum label {
  color: #bfc1c1;
}
.darkTheme .taskTabs button {
  background-color: #efefef;
  opacity: initial;
}
.darkTheme .taskCntntMng {
  background-color: #404646;
}
.darkTheme .allInerTabs {
  background-color: #404646;
}
.darkTheme .taskSort a {
  background-color: #404646;
}
.darkTheme .taskSort a {
  background-color: transparent;
  border: 1px solid #939393;
}
.darkTheme .allInfo img {
  border-radius: 22px;
}
.darkTheme .revwFiles label {
  color: #bfc1c1;
}
.darkTheme .allInfo p {
  color: #bfc1c1;
}
.darkTheme .allInfo label {
  color: #fafafa;
}
.darkTheme .attchOpen button:hover {
  background: transparent;
}
.darkTheme .attchNote label {
  color: #bfc1c1;
}
.darkTheme .allTabCntnt {
  border: 1px solid #8e8e8e;
}
.darkTheme .userDots button {
  border: 1px solid #808080;
  background-color: #192020;
}
.darkTheme .detailInfo {
  background-color: #404646;
}
.darkTheme .flowInfo {
  background-color: #192020;
}
.darkTheme .flowProfil label {
  color: #fafafa;
}
.darkTheme .flowProfil p {
  color: #bfc1c1;
}
.darkTheme .dtlCount a,
.darkTheme .dtlCount span {
  color: #bfc1c1 !important;
}
.darkTheme .nwPatentAdd button {
  color: #00abaf;
  background-color: #192020 !important;
}
.darkTheme a.lineSort {
  border: 1px solid #8c8c8c;
}
.darkTheme .flowInfoInr {
  border-bottom: 1px solid #4e4e4e;
}
.darkTheme .bgGreyTxt label {
  color: #bfc1c1;
}
.darkTheme .appTabs button {
  background-color: #404646;
  color: #e0e0e0 !important;
}
.darkTheme .appTabIner.Mui-selected {
  background-color: #404646 !important;
}
.darkTheme .srchPatient2 {
  background-color: #404646;
}
.darkTheme .receptn {
  background: #404646;
}
.darkTheme .showOnly p {
  color: #e0e0e0;
}
.darkTheme .receptnLink label {
  color: #c5c5c5;
}
.darkTheme .movPopup {
  background-color: #404646;
  border: 1px solid #6b6a6a;
}
.darkTheme .movHead {
  border: 1px solid #6b6a6a;
}
.darkTheme .allTimeSel div {
  background: #192020 !important;
  background-color: #192020 !important;
  color: #c5c5c5;
}
.darkTheme .dischargeSec {
  background-color: #404646;
}
.darkTheme .dischargeHead h5 {
  color: #bfc1c1;
}
.darkTheme .dischargeInfo p {
  color: #bfc1c1;
}
.darkTheme .dischargeInfo label {
  color: #bfc1c1;
}
.darkTheme .dischargeInfo div button:last-child {
  color: #00abaf;
}
.darkTheme .movHeadMid label {
  color: #bfc1c1;
}
.darkTheme .dischargeInfo p {
  color: #bfc1c1;
}
.darkTheme .cardioUpr {
  background-color: #192020;
}
.darkTheme .cardioNumRght label {
  color: #c5c5c5;
}
.darkTheme .cardioNumRght p {
  color: #c5c5c5;
}
.darkTheme .showOnly label span {
  color: #e0e0e0;
}
.darkTheme .deleteStepInfo label {
  color: #bfc1c1;
}
.darkTheme .deleteStepInfo p {
  color: #bfc1c1;
}
.darkTheme .deleteStepInfo button:last-child {
  color: #00abaf;
}
.darkTheme .deleteStep {
  background-color: #404646;
}
.darkTheme .setDate p {
  color: #e0e0e0;
}
.darkTheme a.lineSort2 {
  border: 1px solid #808080;
}
.darkTheme .movPopup2 {
  background-color: #192020;
  border: 1px solid #565656;
}
.darkTheme .receptn2 {
  background-color: #404646;
}
.darkTheme .movHeadMid2 label {
  color: #bfc1c1;
}
.darkTheme .roomWardSel div,
.darkTheme .bedNumberSel div {
  background-color: #404646;
  border-color: #848484;
  color: #bfc1c1;
}
.darkTheme .receptnLink2 label {
  color: #bfc1c1;
}
.darkTheme .adultWardSel div {
  background-color: #404646;
  border-color: #848484;
  color: #bfc1c1;
}
.darkTheme .movHead2 {
  border-bottom: 1px solid #3c3c3c;
}
.darkTheme .usrAction {
  background-color: #192020;
  border: 1px solid #565656;
}
.darkTheme .usrAction li {
  color: #bfc1c1;
}
.darkTheme .dupAction {
  background-color: #192020;
  border: 1px solid #565656;
}
.darkTheme .dupAction li {
  color: #bfc1c1;
}
.darkTheme .statsDrft {
  border: 1px solid #6b6767;
  background-color: #404646;
}
.darkTheme .statsDrftHead {
  border-bottom: 1px solid #404646;
}
.darkTheme .colorDots li {
  color: #bfc1c1;
}
.darkTheme .cntryDropTop div {
  background: #404646 !important;
}

@media (max-width: 468px) {
  .darkTheme .allTabCntnt {
    background: #192020;
    border: 1px solid #696969;
  }
  .darkTheme .taskTabs {
    background-color: #192020 !important;
  }
  .darkTheme .taskTabs button {
    background-color: transparent;
    opacity: initial;
    color: #fafafa !important;
  }
  .darkTheme .userDots button {
    border: 1px solid #828282;
    background-color: #404646;
  }
}
.darkTheme .darkTheme .enterSpcl input {
  background: #192020;
  border: 1px solid #767676;
}
.darkTheme .homeBgIner {
  background: #192020;
}
.darkTheme .cardioGrupBtn button span {
  color: #7f8383 !important;
}
.darkTheme .cardioGrupBtn button:hover {
  background-color: #eccaff !important;
  color: #8c00db !important;
}
.darkTheme .spacemanageDel {
  border: 1px solid #afb1b1;
}
.darkTheme .scndOptionIner ul {
  border: 1px solid #f2f2f2 !important;
  background-color: #192020 !important;
}
.darkTheme .scndOptionIner ul li {
  color: #bfc1c1 !important;
  background-color: transparent !important;
}
.darkTheme .scndOptionIner ul li a {
  color: #bfc1c1 !important;
  background-color: transparent !important;
}
.darkTheme .profilMenuList,
.darkTheme .moreMenuList {
  border: 1px solid #dfdfdf !important;
  background-color: #404646 !important;
}
.darkTheme .profilMenuList li a,
.darkTheme .moreMenuList li a {
  color: #bfc1c1 !important;
}
.darkTheme .attchOpen button span {
  color: #c58904 !important;
}
.darkTheme .taskTabs button span {
  color: #7f8383;
}
.darkTheme .revwFiles button span,
.darkTheme .rvwCadio button {
  color: #ee5253 !important;
}
.darkTheme .RadiologyClr button span {
  color: #0076db;
}
.darkTheme .dragdrop-horizontal ul,
.darkTheme .setDate button {
  background: #2f3535 !important;
}
/* .darkTheme .tskTabs button span{
    background-color: #404646 !important;;
    border: 1px solid #00686bad;
} */
.darkTheme .dragdrop-horizontal ul {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 0px;
}
@media (max-width: 959px) {
  .detailInfo .MuiGrid-grid-sm-6 {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.darkTheme .tskTabs button span {
  color: #fafafa;
}
.nwEntrCntnt.darkTheme {
  background-color: #404646;
}
.darkTheme .nwEntrCourse {
  border-bottom: 1px solid #6d6d6d;
}
.darkTheme .nwEntrCourse label {
  color: #f7f7f7;
}
.darkTheme .nwEntrCourse p {
  color: #c3c3c3;
}
.darkTheme .spclQuesIner label {
  color: #c3c3c3;
}
.darkTheme .spclQuesIner .css-yk16xz-control {
  background-color: #202323 !important;
  border: 1px solid #7d7d7d !important;
}
.darkTheme .spclQuesIner .css-yk16xz-control {
  background-color: transparent !important;
}
.darkTheme .emlCreatSub input {
  border: 1px solid #7d7d7d !important;
  background-color: #202323 !important;
  color: #f7f7f7;
}
.darkTheme .flag-select ul.flag-select__options {
  background-color: #202323 !important;
}
.darkTheme .HospitalAdd .passInst {
  background-color: #202323;
}
.darkTheme .passInstIner:after {
  border-right: 13px solid #00abaf;
  left: -25px;
}
.darkTheme .passInst {
  background-color: #202323;
  border: 1px solid #00abaf;
}
.darkTheme .passInst a {
  color: #c3c3c3;
}
.darkTheme .emlCreatSub label {
  color: #c3c3c3;
}
.darkTheme .passInst p {
  color: #f7f7f7;
}
.darkTheme .twoWayAuth h2 {
  color: #f7f7f7;
}
.darkTheme .twoWayAuthChk span {
  color: #c3c3c3;
}
.darkTheme .twoWayAuth p {
  color: #c3c3c3;
}
/* model css */
.darkTheme .LanguageBoxMain {
  background: #404646;
}
.darkTheme .LanguageBoxMain label {
  color: #f7f7f7;
}
.darkTheme .nwPresCourse {
  border-bottom: 1px solid #797979;
}
.darkTheme .infoShwHidBrdr2 {
  border-top: 1px solid #797979;
}
.darkTheme .presBoxCntnt {
  background: #404646;
}
.darkTheme .presCourse {
  border-bottom: 1px solid #868484;
}
.darkTheme .presCourse p {
  color: #f7f7f7;
}
.darkTheme .prespBoxCntnt {
  background: #404646;
}
.darkTheme .prespCourse p {
  color: #00abaf;
}
.darkTheme .stndQues p,
.darkTheme .pointThis {
  color: #e0e0e0;
}
.darkTheme .stndQues label {
  color: #00abaf;
}
.darkTheme .stndQues span {
  color: #00abaf;
}
.darkTheme .prespCourse label {
  color: #f7f7f7;
}
.darkTheme .prespCourse {
  border-bottom: 1px solid #8e8e8e;
}
.darkTheme .react-confirm-alert-body {
  background: #404646;
  color: #c3c3c3;
}
.darkTheme .react-confirm-alert-body > h1 {
  color: #f7f7f7;
}
.darkTheme .rrSysto div {
  background-color: #192020;
}
.darkTheme .rrSysto div input[type="text"] {
  background-color: transparent !important;
}
.darkTheme .allFormSection {
  background-color: #404646;
}
.darkTheme .rrSysto {
  color: #f7f7f7;
}
/* .addSpeclContnt {
    background: #404646 !important;
    background-color: #404646 !important;
} */
.darkTheme .addSpeclLbl {
  border-bottom: 1px solid #696969 !important;
}
/* .addSpeclLbl label {
    color: #f7f7f7 !important;
} */
.darkTheme .enterSpcl .MuiGrid-root.MuiGrid-item {
  color: #c3c3c3;
}
.darkTheme .rrInput,
.darkTheme .rrInput div {
  background-color: transparent !important;
}
/* .enterSpcl input {
    border: 1px solid #BFC1C1 !important;
    background-color: #404646 !important;
} */
/* .roomRmv a {
    border: 1px solid #BFC1C1 !important;
    background-color: #404646 !important;
} */
.darkTheme .adminMenuLeftUpr {
  background: #404646;
}
.darkTheme .archvOpinionIner {
  background: #404646;
}
.darkTheme .archvSrchInput input {
  background-color: #404646;
  color: #c3c3c3;
}
.darkTheme .archvOpinionIner thead {
  border-bottom: 1px solid #888888;
}
.darkTheme .archvOpinionIner tbody tr {
  border-bottom: 1px solid #888888;
}
.darkTheme .archvOpinionIner thead th {
  color: #f7f7f7;
}
.darkTheme .archvOpinionIner td.pivoted {
  color: #c3c3c3;
}
.darkTheme .menuItems ul li a img {
  background-color: #404646;
  border: 1px solid #828282;
}
.darkTheme a.addNewPlus {
  background-color: #404646;
  /* border: 1px solid #abaeae; */
}
.adminmenuItems .menuActv a img {
  background-color: #00abaf !important;
}
.darkTheme .archvOpinLbl label {
  color: #f7f7f7;
}
.darkTheme .homeBgIner {
  background: #192020 !important;
  min-height: 100vh;
}
.darkTheme .MenuMob {
  background: #404646;
}
.darkTheme .bm-menu nav {
  background: #404646;
}
.darkTheme .profilMenuList {
  border: 1px solid #464646;
  background-color: #192020;
}
.darkTheme .bm-menu {
  background-color: #404646 !important;
}
.darkTheme .menuItems ul li a span {
  color: #dadada;
}
.darkTheme .profilMenuList li a {
  color: #dadada !important;
}
.darkTheme .MenuLeftUpr {
  border-right: 2px solid #8a8a8a;
}
.darkTheme a.academy_ul ul {
  border: 1px solid #5d5d5d;
  background-color: #192020;
}
.darkTheme a.academy_ul ul li a {
  color: #c3c3c3 !important;
}
.darkTheme .profilMenuList ul li:last-child {
  border-top: 1px solid #6f6f6f;
}
.darkTheme .profilePkg {
  background-color: #404646;
}
.darkTheme .profileInfo {
  margin-bottom: 24px;
}
.darkTheme .profileInfoIner input {
  color: #c3c3c3 !important;
  /* border: 1px solid #5d5d5d; */
  border: 1px solid #dfdfdf;
  background-color: #192020;
}
.darkTheme .presOpinionIner {
  background: #404646;
}
.darkTheme .presOpinionIner thead th {
  color: #f7f7f7;
}
.darkTheme .presOpinionIner td.pivoted {
  color: #c3c3c3;
}
.darkTheme .scndOptionIner ul {
  border: 1px solid #505050;
  background-color: #192020;
}
.darkTheme .scndOptionIner ul li a {
  color: #c3c3c3 !important;
}
.darkTheme .scndOptionIner ul li a:hover {
  background-color: #404646 !important;
}
.darkTheme .presOpinionIner tbody tr {
  border-bottom: 1px solid #888888;
}
.darkTheme .enterWrnMain label {
  color: #d9dada !important;
}
.darkTheme .confirmActn p {
  color: #976901 !important;
}
.darkTheme .drListRght2 button span {
  color: #00abaf !important;
}
.darkTheme .roomNum2 button span {
  color: #8c00db !important;
}
.darkTheme .flowProfil label {
  color: #7f8383 !important;
}
.darkTheme .rrSysto div .css-2613qy-menu {
  background: #0c1010 !important;
}
.addComit1 {
  /* margin-top: 32px; */
  margin-bottom: 25px;
  position: relative;
  margin-right: 30px;
  padding: 18px 20px;
}
.addComit1 textarea {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  min-height: 102px;
  resize: none;
  outline: none;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 12px;
}
.patientTask {
  padding-top: 5px;
  padding-right: 30px;
  padding-bottom: 0px;
  padding-left: 0px;
}
@media (max-width: 452px) {
  .attchOpen {
    margin: 25px 0px 10px;
  }
  .specialuty-more {
    float: initial !important;
  }
  a.openScndhrf {
    margin-top: 20px;
  }
  .attchNoteMain {
    display: block !important;
    align-items: initial !important;
  }
}
@media (max-width: 768px) {
  .creatTaskModel {
    width: 98% !important;
  }
}
@media screen and (max-width: 1400px) {
  .taskDetailMob .allTabCntnt .allInfo {
    display: flex;
  }
}
@media screen and (min-width: 961px) and (max-width: 1270px) {
  .revwFilesRght,
  .allInfoRght {
    margin-left: 5px;
  }
  .revwFiles {
    margin-right: 18px;
  }
  .allTabCntnt {
    padding: 12px 7px;
  }
  .allInfo label {
    font-size: 12px;
  }
  .tasklistName img {
    width: 20px !important;
    height: 20px !important;
  }
  .revwFiles img {
    height: 18px !important;
    width: 18px !important;
  }
  /* .revwFiles1 {
    max-width: unset;
    min-width: unset;
  } */
}
a.taskHover span {
  font-weight: 700 !important;
  text-align: center;
  width: 75px;
  top: -25px;
  position: absolute;
  left: -30px;
  display: none;
  padding: 6px 7px;
  color: #e5e6e6;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  border-radius: 8px;
  background-color: rgba(0, 8, 8, 0.9);
  box-shadow: 0 8px 24px 0 rgb(0 8 8 / 8%);
}
a.taskHover {
  position: relative;
}
a.taskHover:hover span {
  display: block;
}
.cardioGrupBtn button:first-child {
  margin-left: 0px;
}
@media (max-width: 452px) {
  .attchOpen {
    margin: 25px 0px 10px;
  }
  .specialuty-more {
    float: initial !important;
  }
  a.openScndhrf {
    margin-top: 20px;
  }
  .attchNoteMain {
    display: block !important;
    align-items: initial !important;
  }
}
@media (max-width: 768px) {
  .creatTaskModel {
    width: 98% !important;
  }
}
.darkTheme .fillDia div {
  background: transparent !important;
}
.darkTheme a.addNwPatnt {
  background-color: #2f3535;
  border: 1px solid #9b9b9b;
  color: #c3c3c3 !important;
}
.darkTheme .taskDescp textarea::-webkit-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .taskDescp textarea::-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .taskDescp textarea:-ms-input-placeholder {
  color: #bfc1c1;
}
.darkTheme .taskDescp textarea:-moz-placeholder {
  color: #bfc1c1;
}
.darkTheme .sevicessection div {
  background-color: #192020 !important;
}
.dragdrop-horizontal .nwPatentAdd button {
  margin-bottom: 18px;
}
.dragdrop-horizontal a.academy_ul ul li a {
  border: none !important;
  background-color: transparent !important;
}
.addPatent a {
  margin-bottom: 15px;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    background-color: transparent !important;
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-moz-autofill,
input:-moz-autofill:hover, 
input:-moz-autofill:focus, 
input:-moz-autofill:active{
    background-color: transparent !important;
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
} */

/* css by naveen start here */
.buttonStyle {
  padding-top: 15px;
}
.buttonStyle a {
  display: flex !important;
  justify-content: center;
  border-radius: 8px;
  background-color: #00abaf;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  font-family: Muli;
  text-align: center;
  padding: 10px 10px;
  min-width: 156px;
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;
}

.headingFormat label {
  color: #000808;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  font-family: Muli;
}
.modAppTabs {
  background-color: #f2f2f2 !important;
  box-shadow: initial !important;
  margin-bottom: 15px;
}
.modAppTabs button {
  margin-left: 20px;
  color: #7f8383 !important;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  text-transform: capitalize;
  width: initial !important;
  margin-right: 26px;
  min-height: 50px;
  border-bottom: 4px solid;
  border-color: transparent;
  background-color: #f2f2f2;
  min-width: 66px;
  border-radius: 12px;
}
.pattern-main-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 70px;
  height: 100px;
  width: auto;
}

.printPreview {
  padding: 15px;
}
.printPreviewText {
  font-family: Muli;
  font-size: 25px !important;
  font-weight: 600;
  letter-spacing: 10;
}
.printPreviewlink .relativeCSS1 {
  display: none !important;
  margin-left: 15px !important;
}
.printPreviewlink .relativeCSS {
  display: none !important;
}
.printPreviewlink table {
  display: none !important;
}
.nextPart {
  text-align: center;
}
.more-change-staff-img2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #404646 !important;
}
.more-change-staff-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.allSpeces {
  width: 100% !important;
}
.allSpeces .css-g1d714-ValueContainer {
  padding: 0px 8px !important;
}

.invoiceForm .MuiGrid-align-items-xs-center,
.addCstmField .MuiGrid-align-items-xs-center {
  align-items: flex-start !important;
}
/* .addStafSelect input[type="text"], .cstmSelect input[type="text"]{
    min-height: 35px!important;
} */
.enterPricePart1 {
  position: relative;
}
.enterPricePart2 {
  position: absolute;
  top: 47px;
  right: 30px;
  color: #404646;
  font-weight: 600;
}
.setStatusNxtPart {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.servSaveBtn {
  margin: 0px 30px;
}
@media (max-width: 959px) {
  /* .ptntFlowSpc ul.manageDragDrop {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
 } */
}
@media (max-width: 580px) {
  .addServContnt {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 468px) {
  .taskSort input[type="text"] {
    width: 120px;
  }
  .attchNotePart {
    display: flex;
  }
  .taskSort {
    margin-left: 3px;
   }
}
@media (max-width: 599px) {
  .addFlowRght, .spcMgntH1 h1 {
    text-align: center !important;
  }
  .addFlowRght {
    margin-top: 24px !important;
  }
  .vwTaskSelectTp {
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: initial !important;
    justify-content: center !important;
  }
}
@media (max-width: 468px) {
  .topLeftSpc {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 40px !important;
  }
  .viewTaskfilter {
    display: block;
  }
  .vwTaskSelect {
    margin-bottom: 3px;
    cursor: pointer;
  }
}
@media (max-width: 452px) {
  a.openScndhrf {
    margin-top: 0px !important;
  }
}
.darkTheme .setStatus li:first-child {
  border: none !important;
}
@media (max-width: 410px) {
  .react-confirm-alert-body {
    width: 300px;
  }
}
@media (max-width: 959px) {
  .mainMenuAllSec {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prfilHghtLft,
  .prfilHghtRght {
    padding-left: 9% !important;
  }
  .specialitybutton {
    margin: 4px 0px;
  }
}
.addSpeclContntIner {
  overflow: auto;
  height: 100%;
  padding-bottom: 5px;
}
/* Add by tanya */

.colorBtnUpr button {
  border-radius: 8px;
  background-color: lightgrey;
  color: black !important;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  padding: 16px 10px;
  min-width: 156px;
  display: inline-block;
  margin-left: 5px;
}

/* Added by ankit */
.colorbtn button {
  border-radius: 8px;
  background-color: lightgrey;
  color: black !important;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  padding: 16px 10px;
  min-width: 220px;
  display: inline-block;
  margin-left: 0px;
}

.tskOverView2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.demOverView h1 {
  text-align: center;
  margin-bottom: 15px;
}

.demOverView {
  text-align: center;
  margin-top: 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
  min-height: 30vh;
  padding: 26px 16px;
  border-radius: 15px;
  font-weight: bold;
  margin: 0px 20px;
}

.MedicalSer {
  color: white;
  margin-top: 20px;
  padding: 10px 20px !important;
  width: 100%;
  background-color: black;
}
.maintabPart {
  width: 100%;
}

.tabL2 {
  border-bottom: 1px solid red;
  padding-bottom: 20px;
  margin-top: 30px;
}

.tabL2Col {
  font-size: 14px;
  padding-bottom: 10px;
  width: 70%;
}

.tabL2Col1 {
  font-size: 14px;
  padding-bottom: 10px;
  width: 30%;
}

.tabL2Col2 {
  margin-top: 30px !important;
}

.tabL2Col3 {
  font-size: 14px;
  padding-bottom: 10px;
  width: 5%;
}

.tabL2Col4 {
  font-size: 14px;
  padding-bottom: 10px;
}

.tabL3a {
  color: white;
  margin-top: 20px;
  padding: 10px 20px;
}
.tabL3a > tr th {
  padding: 10px !important;
  font-size: 20px !important;
}
.tabL3Col1 {
  padding: 10px 20px;
  border-bottom: 2px solid red;
}

.tabL3 {
  text-align: right;
  border-bottom: 2px solid red;
  padding: 10px 20px 20px 20px !important;
  margin: 10px 0 20px 0 !important;
}
.tabL3 p,
strong {
  font-size: 20px !important;
  font-weight: 600;
  margin-bottom: 8px !important;
}

.tabL3Col1 p {
  margin-bottom: 10px !important;
}

/* .tabL3Col1{font-size:14px; padding-bottom: 10px; width:10%; width:100%} */

.tabL3Col2 {
  font-size: 14px;
  padding-bottom: 10px;
  width: 20%;
}

.tabL4 {
  font-size: 18px !important;
  margin-top: 10px;
}

.tabL4 p {
  font-size: 21px !important;
}

.tabL5 {
  font-size: 14px;
  margin-top: 10px;
}

.ThnkYU {
  font-size: 21px !important;
}

.tabL6Col1 {
  font-size: 14px;
  padding-bottom: 10px;
  width: 30%;
}

.tabL7 {
  color: white;
  margin-top: 20px;
  padding: 10px 20px;
}

.tabL8 {
  padding: 10px 20px;
  border-bottom: 2px solid red;
  margin-top: 5px !important;
}

.tabL9 {
  font-size: 14px;
  margin-top: 10px;
}

.tabL10 {
  border-bottom: 1px solid red;
  padding-bottom: 20px;
  margin-top: 30px;
}

.tabL10Col1 {
  font-size: 14px;
  padding-bottom: 10px;
  width: 70%;
}

.tabL10Col2 {
  font-size: 14px;
  padding-bottom: 10px;
  width: 30%;
}

.tabL11 {
  color: white;
  margin-top: 20px;
  padding: 10px 20px;
}

.tab12 {
  padding: 10px 20px;
  border-bottom: 2px solid red;
}

.tab13 {
  font-size: 14px;
  margin-top: 10px;
}

.maintabPart a {
  color: #ffffff;
  text-decoration: none;
}
.maintabPart p {
  padding: 0 !important;
  margin: 0 !important;
}

.maintabPart .img {
  -ms-interpolation-mode: bicubic;
}
.termCond {
  font-size: 1.6rem;
  font-weight: 800;
}
.tabL5 h1 {
  margin-bottom: 20px !important;
}

@media (min-width: 768px) and (max-width: 1150px) {
  .maintabPart {
    padding: 20px 100px;
  }
}

@media (max-width: 599px) {
  .firsttabhead,
  .secsttabhead {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-device-width: 768px),
  only screen and (max-width: 768px) {
  .maintabPart {
    padding: 20px 20px;
  }
}
.tabL5 h1 {
  margin-bottom: 20px !important;
}
.WlcmAimds {
  font-size: 40px !important;
  font-weight: 700;
}

.tabL2 p,
strong,
.tabL2Col2 p,
.TblPG2 p,
.TblPG2 strong,
.tabLLa th {
  font-size: 20px !important;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.tabL2Col3 {
  padding-top: 0px !important;
}

.tabL3Col2 {
  padding-top: 7.5px;
}

.MedicalSer1 > tr th {
  padding: 10px !important;
  font-size: 20px !important;
}

.printprv1 {
  padding-top: 80px !important;
}

.termCond1 {
  margin-top: 15px !important;
}

.tabL3Col1 {
  display: flex;
  justify-content: flex-end;
}

.tabL3Col1 p {
  width: 33%;
  text-align: right;
}

.tabDatPart {
  padding-right: 40px !important;
}
.secsttabhead {
  border-bottom: 1px solid red;
}

.tab1PG3 {
  margin-top: 20px !important;
}

.tabAligPro {
  text-align: center;
}
.tabDatPart {
  text-align: right;
  padding-right: 20px !important;
}

.pattern-main-foot {
  margin-top: 55%;
  height: 70px;
}

.pattern-main-foot2 {
  margin-top: 60%;
  height: 70px;
}

.pattern-main-foot1 {
  margin-top: 70%;
  height: 70px;
}
.invoiceidtxt {
  font-weight: 20px !important;
  font-size: 20px !important;
}
.darkTheme a.taskHover {
  color: #d9dada !important;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: #000808 !important;
} */

.darkTheme input:-webkit-autofill,
.darkTheme .docHlthMain input:-webkit-autofill,
.darkTheme .docHlthMain input:-webkit-autofill:hover,
.darkTheme .docHlthMain input:-webkit-autofill:focus,
.darkTheme .docHlthMain input:-webkit-autofill:active,
.darkTheme .docHlthMain input:-moz-autofill,
.darkTheme .docHlthMain input:-moz-autofill:hover,
.darkTheme .docHlthMain input:-moz-autofill:focus,
.darkTheme .docHlthMain input:-moz-autofill:active,
.darkTheme .emlCreatSub input:-webkit-autofill,
.darkTheme .emlCreatSub input:-webkit-autofill:hover,
.darkTheme .emlCreatSub input:-webkit-autofill:focus,
.darkTheme .emlCreatSub input:-webkit-autofill:active,
.darkTheme .emlCreatSub input:-moz-autofill,
.darkTheme .emlCreatSub input:-moz-autofill:hover,
.darkTheme .emlCreatSub input:-moz-autofill:focus,
.darkTheme .emlCreatSub input:-moz-autofill:active,
.darkTheme input:-webkit-autofill:hover,
.darkTheme input:-webkit-autofill:focus,
.darkTheme input:-webkit-autofill:active,
.darkTheme input:-moz-autofill,
.darkTheme input:-moz-autofill:hover,
.darkTheme input:-moz-autofill:focus,
.darkTheme input:-moz-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #202323 inset !important;
  -webkit-text-fill-color: #bfc1c1 !important;
}

.academy_ul.stepTdotupper ul {
  height: auto;
  /* height: 250px; */
  overflow-y: auto;
}

.house-design {
  border-style: solid;
}

.house-design td {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* @media screen and (min-width: 1368px){ */
/* .dragdrop-vertical .manageDragDrop {
    display: flex;
    width: 100%;
}
} */
.dragdrop-vertical .manageDragDrop {
  /* display: flex;
    width: 100%; */
  /* overflow-x: auto; */
}
.TabCSS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
}
.TabCSS img {
  margin-right: 12px;
  height: 20px;
  width: auto;
}

.darkTheme .infoSubInp input {
  background-color: #00abaf !important;
}

/* ADD by Tanya */
.enterPricePart3 {
  position: absolute;
  top: 32px;
  right: 30px;
  color: #404646;
  font-weight: 600;
}
.assignHos {
  font-size: 18px !important;
  font-weight: 700 !important;
  padding-bottom: 10px !important;
}

.darkTheme .assignHos {
  color: #c3c3c3;
}

.infoIconCol {
  color: #00abaf;
}

.noHosAss {
  color: red;
  text-align: center !important;
}

.assHosList {
  padding-bottom: 10px;
}
.setAssignedTo span:after {
  z-index: 1;
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000808e6;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -4px;
  content: "";
  margin: 0 auto;
}
.invoiceForm input[type="text"] {
  min-height: 45px !important;
}
.darkTheme .invoiceForm .addStafSelect div {
  border-color: #565656 !important;
}
@media (max-width: 1268px) {
  .vh-section .presOpinionIner td.pivoted {
    font-size: 12px;
  }
  /* .vh-section .presOpinionIner td.pivoted {
        padding: auto !important;
    } */
  /* .presOpinionIner td.pivoted {
        padding: 12px 2px !important;
    } */
}
@media screen and (min-width: 960px) and (max-width: 1268px) {
  .mainMenuAllSec .MuiGrid-grid-md-11 {
    max-width: 98.666667%;
    flex-basis: 98.666667%;
  }
  .mainMenuAllSec .MuiGrid-grid-md-7 {
    max-width: 61.333333% !important;
    flex-basis: 61.333333% !important;
  }
  .mainMenuAllSec .MuiGrid-grid-md-4 {
    max-width: 30.333333% !important;
    flex-basis: 30.333333% !important;
  }
  .vh-section .presOpinionIner thead th {
    padding: 16px 10px !important;
  }
  .dateAdd:after {
    height: 11px;
    width: 7px;
    right: 1px;
    top: 15px;
  }
  img.openScnd {
    height: 19px !important;
    width: 19px !important;
  }
}

.darkTheme .othbackcol {
  background-color: #404646;
}

.assiHospDet {
  margin-bottom: 7px;
}

.pateintInfoUser {
  margin: 10px;
}

.pateintInfoUser p {
  margin: 5px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}

.pateintInfoUser a:hover {
  color: #0088a9;
}

.patentTnfoBtn1 button {
  margin-left: 10px;
  width: 95%;
}

.headerCountTxt label {
  display: block;
  margin-bottom: 0px;
  color: #404646;
  font-family: Muli;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-break: anywhere;
  /* line-height: 12px; */
}
.headerCountTxt input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 7px 6px;
  outline: none;
  width: 100%;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
}
.headerCountTxt {
  margin-bottom: 20px;
}

.infoSubInpSection {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.allheadercont {
  margin-left: 10px;
  margin-right: 10px;
}
.infoSubInpSection input {
  background-color: #00abaf !important;
  color: #ffffff;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 8px 10px;
}

.profileInfoSection h1 {
  color: #000808;
  font-family: Muli;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 42px;
  margin-bottom: 20px;
}
.printSec {
  display: flex;
}

.printSec p:last-child,
.printSec p:nth-child(2) {
  margin-left: 10px;
}
.editorSec {
  font-size: 18px;
  margin-top: 20px;
  font-weight: 600;
}
.allInfoSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.headercontSec {
  margin-top: 30px;
}

.profileInfoSection h1 {
  color: #000808;
  font-family: Muli;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 42px;
  margin-bottom: 20px;
}
.printSec {
  display: flex;
}
.printSec p {
  font-size: 20px;
  font-weight: 600;
}
.printSec p:last-child,
.printSec p:nth-child(2) {
  margin-left: 10px;
}

.allInfoSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.switchBtn {
  margin-top: 30px;
}

.dateFormateSec .ant-picker {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
@media (max-width: 959px) {
  .infoSubInpSection {
    margin-top: 0px;
  }
}
.allPrintPart {
  margin-top: 50px;
}
.allPhrintSec p {
  font-size: 35px;
}
.allPhrintSec {
  margin-bottom: 10px;
}
.editorPhrintSec {
  margin-top: 40px;
}
.darkTheme .headerCountTxt input {
  background-color: #404646;
  color: #d9dada;
}
.darkTheme .ql-toolbar.ql-snow {
  background-color: #949494;
}

.menuCheckBox {
  text-align: center;
  margin-bottom: 30px;
}
.menuCheckBox p {
  color: #7f8383;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
}
.menuCheckBox .MuiSvgIcon-root {
  width: 45px;
  height: 45px;
}
.menuCheckBox .MuiCheckbox-colorSecondary.Mui-checked {
  color: #00abaf !important;
}
.menuCheckBox .PrivateSwitchBase-root-110 {
  padding: 5px;
}
.menuCheckBox .MuiButtonBase-root {
  background-color: transparent !important;
}

@media screen and (min-width: 960px) and (max-width: 1092px) {
  .menuCheckBox p {
    font-size: 10px;
  }
}

.darkTheme .stepchange-input {
  background-color: #404646;
}

.darkTheme .forBackgroundClr {
  background-color: #00abaf;
  border-radius: 8px;
}

.questionNew {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.fatiqueQues1 {
  margin-top: 20px;
  background-color: white;
  margin-left: 20px;
}

.attchForms1 label {
  color: #404646;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  line-break: anywhere !important;
}

.dateSet label {
  margin-right: 70px;
}

.textFieldArea {
  outline: none;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bfc1c1;
  margin-top: 10px;
}

.textFieldArea1 input {
  margin-bottom: 5px;
  height: 35px;
  outline: none;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bfc1c1;
  margin-top: 10px;
}

.fatiqueQuess1 {
  margin-top: 10px;
}

.fillDia .attchImg {
  text-align: center !important;
}

.newrole {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .newrole1 label{
    display: block;
} */
label.newrole {
  display: block;
}
.newrole p {
  margin-left: 100px;
}
.newrole input {
  margin: 10px;
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  width: 40%;
  padding: 6px 12px;
  outline: none;
  margin-right: 30px;
}

.newrole label {
  font-size: 18px;
}

.headsize {
  font-size: 20px;
}
.heading p {
  font-size: 15px;
}

.selectEntry {
  margin: 10px;
  width: 100%;
}

.line {
  flex-flow: column nowrap;
}

.inputBorField input {
  border: 1px solid #bfc1c1;
  border-radius: 4px;
  background-color: #ffffff;
  /* width: 96.5%; */
  width: 100%;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 12px 12px;
  box-sizing: border-box !important;
  min-height: 44px;
}

.cnfrmDiaMain .fillDia .errChrMsg {
  color: #ff0000 !important;
}

.stndQues1 h2 {
  font-weight: 800;
  margin-top: 10px;
}
.stndQues1 h3 {
  font-weight: 900;
  margin-top: 10px;
}
.stndQues1 h1 {
  font-weight: 700;
  margin-top: 10px;
}

.stndQues1 label {
  margin-top: 12px;
}
.imageEvalSize img {
  /* height: 90px; */
  width: 50%;
  border-radius: 15px;
  margin-right: 8px;
}
.BookingCont3 .BookingCont {
  font-family: Muli;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: inherit;
  color: #ffffff;
  background-color: #00abaf;
  height: 48px;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}
.BookingCont3 .BookingCont:hover {
  background-color: #00abaf !important;
}

.imageEvalSize img {
  width: 50%;
  border-radius: 15px;
  margin-right: 8px;
}
.dataCommentBor {
  padding: 10px;
  background: #ddd;
  margin: 10px 0px;
  border-radius: 5px;
  color: #000 !important;
}

.attchDecline button {
  border-radius: 4px;
  background-color: #ffdab99e;
  color: #ff0000;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  text-transform: capitalize;
  padding: 7px 8px;
}

.attchDecline button label {
  height: 8px;
  width: 8px;
  background-color: #ff0000;
  border-radius: 12px;
  margin-right: 7px;
  margin-bottom: 0px;
  font-family: Muli;
}
.certificateBg {
  background: #fffac7;
  padding-top: 55px;
  padding-bottom: 132px;
}
.certifyForm {
  border: 2px solid #a12f219c;
  padding: 26px;
  border-radius: 6px;
  width: 100%;
  margin-left: 20px;
}

.insrnceCmp input {
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7f8383;
  border-radius: 4px;
  width: 100%;
  min-height: 40px;
  border: 1px solid #bebebe;
  padding: 10px 8px 10px;
  font-family: "Mulish", sans-serif;
}
.insrnceCmp2 {
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7f8383;
  border-radius: 4px;
  width: 100%;
  min-height: 40px;
  border: 1px solid #bebebe;
  padding: 10px 8px 10px;
  font-family: "Mulish", sans-serif;
}
.cmnSpc {
  margin-bottom: 18px;
}

.insrnceLbl1 label {
  display: block;
  color: #a12f21;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: initial;
  font-family: Mulish, sans-serif;
}
.insrnceLbl2 label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #404646;
  margin-bottom: 7px;
  font-family: Mulish, sans-serif;
}
.certifyCheck {
  margin-top: 60px;
  margin-bottom: 45px;
}
.frstCertify h3 {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #404646;
  margin-bottom: 18px;
  font-family: Mulish, sans-serif;
  margin-left: 20px;
}

.formGroupChk input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  /* margin-left: 20px; */
}
.formGroupChk label {
  position: relative;
  cursor: pointer;
  padding-left: 50px;
}
.formGroupChk label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #a12f21;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 15px 16px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0px;
  position: absolute;
  top: 6px;
  left: 0px;
  border-radius: 4px;
}
.formGroupChk input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  left: 14px;
  width: 8px;
  height: 17px;
  border: solid #a12f21;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  font-family: "Mulish", sans-serif;
  margin-bottom: 10px;
}
.formGroupChk span {
  display: block;
  /* color: black; */
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
  line-height: initial;
  font-family: Mulish, sans-serif;
  margin-top: 14px;
}
.formGroupChk p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #404646;
  margin-bottom: 0px;
  font-family: Mulish, sans-serif;
}
.btmSpc {
  margin-bottom: 16px;
}
.wrkSince label {
  display: block;
  color: #a12f21;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 5px;
  line-height: initial;
  font-family: Mulish, sans-serif;
}

.wrkInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.wrkInput input {
  width: 50%;
  height: 55px;
  border: 1.5px solid #a12f21;
  border-right: none;
  outline: none;
  padding: 6px;
  text-align: center;
  font-family: Mulish, sans-serif;
}
.wrkInput input:first-child {
  border-radius: 4px 0px 0px 4px;
}
.wrkInput input:last-child {
  border-right: 1.5px solid #a12f21;
  border-radius: 0px 4px 4px 0px;
}

.wrkSince p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #404646;
  margin-bottom: 0px;
  font-family: Mulish, sans-serif;
  margin-left: 20px;
}
.setArbtUpr {
  position: relative;
  min-height: 100%;
}
.setArbt label {
  display: block;
  color: #a12f21;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0px;
  font-family: Mulish, sans-serif;
}

.setArbtSign {
  border: 2px solid #a12f219c;
  padding: 18px;
  /* padding-top: 124px; */
  border-radius: 6px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  background: #ffffff;
  /* width:100%; */
  position: absolute;
}

.setArbtSign label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: initial;
  color: #404646;
  margin-bottom: 8px;
  font-family: Mulish, sans-serif;
}
.setArbtSign p {
  color: #a12f21;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  margin: 0px;
  line-height: initial;
  font-family: Mulish, sans-serif;
}

.attchDecline {
  margin: 0px 38px;
}

.darkTheme .attchDecline button span {
  color: #ff0000 !important;
}
.darkTheme .attchDecline button {
  background-color: #ffdab9;
}

.menuCheckBox1 {
  text-align: left !important;
}

.menuCheckBox1 p {
  display: inline;
}
.min_section .css-yk16xz-control {
  min-height: 30px !important;
  min-height: initial !important;
}
.min_section .css-b8ldur-Input {
  margin: 0px !important;
  padding: 0px !important;
}
.darkTheme .minall_sec .css-12jo7m5 {
  color: #d9dada;
}
.darkTheme .serviceallSec {
  color: #d9dada;
}

.Painbegin {
  position: "absolute";
  width: "6px";
  height: "6px";
  background: "red";
  /* borderRadius: "50%"; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000808 !important;
}
.srchFilter .comonSel div {
  border: none !important;
}
.creatTaskModel2 {
  width: 80%;
  /* overflow-y: scroll; */
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-right: 2px;
  padding-top: 18px;
  padding-bottom: 16px;
  border-radius: 12px;
}
.hhh {
  margin-top: 10px;
}
.setColorRed label {
  color: #a12f21 !important;
  font-size: 15px;
}
.setColorBlack label {
  font-size: 15px;
}
.setColorRed p {
  color: #a12f21 !important;
  font-size: 15px;
}
.setColorBlack {
  font-size: 15px;
}
.setColorRed2 label {
  color: #a12f21 !important;
  font-size: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.setColorRed2 span {
  color: #a12f21 !important;
  font-size: 15px;
}
.setColorRed3 label {
  color: #a12f21 !important;
  font-size: 15px;
  margin-left: 20px;
}
.setColorRed3 p {
  color: #a12f21 !important;
  font-size: 15px;
}
.setColorBlack2 label {
  font-size: 15px;
  margin-left: 20px;
}

/* .fillDia{
  width:100%;
  height: 100px;
} */
.browsInput2 {
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  display: flex;
  color: #7f8383;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
} 
.browsInput2 a {
  color: #00abaf !important;
  margin: 0px 4px;
}
.browsInput2 input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 45px;
  z-index: 999;
  width: 100%;
}
.browsInput2 {
  position: relative;
}
.SubmitCertificate button {
  background-color: rgb(0 171 175) !important;
  color: #ffffff;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  width: 50%;
  text-transform: inherit;
  min-height: 40px;
  margin-top: 40px;
  margin-left: 20px;
}
.infoShwSave3 input {
  border-radius: 8px;
  background-color: #00abaf;
  border: 2px solid #00abaf;
  width: 50%;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px 8px;
  margin-top: 15px;
  margin-bottom: 2px;
  outline: none;
}
.infoShwSave3 input:hover {
  background: transparent;
  background-color: #00abaf;
}
.noteCertificate {
  border: 2px solid #a12f219c;
  padding: 30px;
  border-radius: 6px;
  text-align: center;
  background: #ffffff;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 250px;
  margin-right: 20px;
  /* width: 200px;; */
  /* position: absolute;  */
}
.darkTheme .noteCertificate {
  background: #192020;
}
.darkTheme .noteCertificate2 {
  background: #192020;
  color: #bfc1c1;
}
.noteCertificate2 {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
}
.workUntil {
  margin-top: 5px !important;
} 
/* @media (max-width: 600px) {
  .setArbtSign {
    margin-top:100% !important;
  }
} */
.uploader {
  border: none;
}
.browsInput2 {
  /* border: 2px dashed #BFC1C1; */
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  display: flex;
  color: #7f8383;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}
a.joinmeetingtab {
  display: inline !important;
  padding: 0px !important;
}
.infoShwSave2 button {
  border-radius: 8px;
  background-color: #00abaf;
  border: 2px solid #00abaf;
  width: 100%;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px 8px;
  margin-top: 15px;
  margin-bottom: 2px;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
}
.infoShwSave2 button:hover {
  /* background: transparent; */
  background-color: #00abaf;
}
.infoShwSave3 button {
  border-radius: 8px;
  background-color: red;
  border: 2px solid red;
  width: 100%;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px 8px;
  outline: none;
  cursor: pointer;
  text-transform: capitalize;
  margin-left: 20px;
  margin-top: 10px;
}
.infoShwSave3 button:hover {
  /* background: transparent; */
  background-color: red;
}
.infoShwSave3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uplodedFile {
  line-break: anywhere;
}
.stampDoctor {
  border: 2px solid #a12f219c;
  background: #ffffff;
  text-align: center;
  border-radius: 6px;
  height: 100%;
  padding: 30px;
  margin-right: 20px;
  margin-left: 20px;
}
.darkTheme .stampDoctor {
  background: #192020;
}
.darkTheme .browsInput2 {
  background: #192020;
}

.stampDoctor label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: initial;
  /* line-break: anywhere; */
  margin-bottom: 8px;
  font-family: Mulish, sans-serif;
  font-size: 15px;
  text-align: center;
  /* margin-right: 2px; */
}

.spacedistance {
  margin-top: 20px;
}
@media (max-width: 600px) {
  .stampDoctor {
    margin-top: 20px !important;
  }
}
@media (max-width: 600px) {
  .wrkInput {
    margin-bottom: 10px !important;
  }
}
/* @media (max-width: 600px) {
  .certifyCheck2{
    margin-top:70%!important;
  }
} */
.diablcertificate {
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

@media (max-width: 600px) {
  .MuiGrid-spacing-xs-5 {
    width: calc(100% + 0px) !important;
    /* margin: -20px; */
  }
  .noteCertificate {
    width: 100%;
    margin-left: 20px;
  }
  .certifyCheck {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .wrkInput {
    margin-right: 20px;
  }
  .allDisableSec {
    display: none;
  }
  .diablcertificate {
    margin-left: 20px;
  }
}
.darkTheme .cnfrmDiaMain2 {
  background-color: #404646;
}
.darkTheme .certificateBg {
  background: #a0a0871c;
}
.darkTheme .stndQues {
  color: white;
}
.certificatePrice input {
  margin-left: 20px;
  padding: 6px;
  /* text-align: center; */
  font-family: Mulish, sans-serif;
  border-radius: 6px;
  border: 1px solid grey;
  width: 100%;
}
.certificatePrice label {
  font-family: Mulish, sans-serif;
  color: #7f8383;
  font-size: 14px;
  font-weight: bold;
}
.certificatePrice button {
  border-radius: 8px;
  background-color: #00abaf;
  border: 2px solid #00abaf;
  min-width: 160px;
  color: #ffffff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 12px 8px;
  margin-bottom: 2px;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
  text-transform: capitalize;
}
.certificatePrice button:hover {
  /* background: transparent; */
  background-color: #00abaf;
}
.pionter {
  cursor: pointer;
}
.certificatePrice img {
  margin-left: 30px;
}
.darkTheme .certificatePrice input {
  background: #192020 !important;
}
.darkTheme input[type="date"] {
  background-color: #192020 !important;
}
.euroamount {
  position: absolute;
  top: 8px;
  /* left: 186px; */
  color: grey;
  font-weight: 600;
  right: -10px; 
}
@media (max-width: 500px) {
  .deleteStep {
    width: 100% !important;
  }
  .MuiGrid-root.srvcTable3 {
    overflow-x: scroll;
  }
}
.infoShwSave3 input {
  width: 100%;
}
@media screen and (min-width: 1425px) and (max-width: 1470px) {
  .revwFiles {
    margin-right: 30px;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1470px) {
  .attchNoteUpr {
    margin-left: 20%;
  }
  .allInfoRght {
    margin-right: 50px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .allArchvSection2 td.pivoted {
    font-size: 9px !important;
    padding: 8px !important;
  }
  .allArchvSection2 thead th {
    padding: 16px 16px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
  .allArchvSection2 td.pivoted {
    font-size: 10px !important;
    padding: 10px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .allArchvSection2 td.pivoted {
    font-size: 11px !important;
    padding: 10px !important;
  }
}

@media (max-width: 958px) {
  .backFlow1 {
    margin: 20px 20px !important;
  }
}
.fixedEuro {
  position: relative;
}
/* @media (max-width: 430px) {
  .euroamount {
    position: absolute;
    top: 38px;
    left: 140px;
  }
} */
@media (max-width: 500px) {
  .openScnd {
    margin-top: 10px;
  }
}
@media (max-width: 350px) {
  .openScnd {
    margin-left: 20px;
  }
  .colorspace input {
    width: 90%;
  }
}
@media screen and (min-width: 960px) and (max-width: 1200px) {
  .colorspace input {
    width: 90%;
  }
}
.dobCertSec .ant-picker {
  border: none;
  background-color: transparent;
  margin-bottom: 0px;
  padding: 0px 0px;
}
.dobCertSec .ant-picker-suffix {
  margin-left: 0px;
}
.dobCertSec .ant-picker-clear {
  right: 8px;
}
.dobCertSec1 input:first-child {
  border: 1px solid #a12f21;
  border-radius: 4px;
  text-align: left;
  padding: 0px 0px 0px 10px;
}
.dobCertSec2 input:first-child {
  border: 1px solid #bebebe;
}
.dobCertSec .ant-picker-focused {
  box-shadow: none;
}

.darkTheme .dobCertSec2 input:first-child {
  border: none;
}

.darkTheme .formGroupChk label:before {
  border: 2px solid #00b0de;
}
.darkTheme .formGroupChk input:checked + label:after {
  border-top-color: #00b0de;
  border-top-style: solid;
  border-top-width: 0px;
  border-right-color: #00b0de;
  border-right-style: solid;
  border-right-width: 2px;
  border-bottom-color: #00b0de;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: #00b0de;
  border-left-style: solid;
}
.darkTheme .certifyForm,
.darkTheme .noteCertificate,
.darkTheme .stampDoctor {
  border: 2px solid #00b0de;
}
.darkTheme .dobCertSec1 input:first-child {
  border: 1px solid #00b0de;
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  .stampDoctor {
    padding: 30px 15px;
  }
}
.allDrList3 {
  height: 680px;
  overflow-y: scroll;
  padding: 10px 0px;
}
.nwDiaCntnt2 {
  max-width: 480px !important;
  padding: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
}
.allCreInfSec {
  margin-left: 10px;
}
.allCreInfSec2 {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.allCreInfSec, .allCreInfSec2 {
  margin-top: 10px;
}
.creatInfoTwo input {
  min-height: 40px;
}
.allCreInfSec2 button {
  margin: 0px;
  width: 100%;
  padding: 8px 8px;
}
.allCertSec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.certificatePrice label {
  margin-bottom: 0px;
}
.InfoOutLinees{
  color: red;
  font-size: 20px !important;
  margin-right: 4px;
}
.InfoOutLinees:hover + .assignHoses.Paymentpending {
  display: block !important;
}
.InfoOutLinees:hover + .assignHoses.appointmentTime {
  display: block !important;
}
.Paymentpending {
  color: red;
  background-color: #ffffff;
  text-align: center;
  padding: 7px;
  position: absolute;
  right: -20px;
  border-radius: 10px;
  width: 135px;
  border: 1px #cccc solid;
  z-index: 99999999;
}
.assignHoses.Paymentpending {
  display: none;
}
.darktheme .assignHoses .Paymentpending {
  background: #192020 !important;
}
.assignHoses {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-bottom: 10px !important;
}
.appointmentTime {
  color: red;
  background-color: #ffffff;
  padding: 7px;
  position: absolute;
  right: -20px;
  border-radius: 10px;
  width: 135px;
  border: 1px #cccc solid;
  z-index: 99999999;
}

.darkTheme .appointmentTime {
  background: #192020 !important;
  color: red !important;
}
.darkTheme .Paymentpending {
  background: #192020 !important;
  color: red !important;
}

.assignHoses.appointmentTime {
  display: none;
}

.allCertSec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.certificatePrice label {
  margin-bottom: 0px;
}
.addAssignedBtn button {
  min-width: 215px;
}
.addAssignBtn1 button {
  margin-left: 13px;
}
.addAssignBtn1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.newServicAllSec {
  margin-bottom: 0px;
}
.newServc.newServicAllSec {
  margin-bottom: 20px;
}
.darkTheme .cstmSelect2 div {
  background-color: #192020 !important;
  color: #bfc1c1;
  box-shadow: initial !important;
  outline: none !important;
}
.addSpeclContntIner2 {
  height: 537px;
  overflow-y: scroll;
  padding-bottom: 18px;
}
.addSpeclContnt2 {
  width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 3px;
}
.MuiGrid-root.dueOn.creatInfoIner {
  padding: 0px;
}
.MuiGrid-root.sevicessection.serviceallSec {
  margin-bottom: 0px;
}
.enterSpcl input, .addStafSelect {
  margin-bottom: 10px;
}
.darkTheme .addSpeclContnt2 {
  background: #404646;
}
@media (max-width: 599px) {
  .addSpeclContnt2 {
    width: 96% !important;
  }
}
.openAssser {
  display: flex;
  /* align-items:center; */
  justify-content: flex-end;
}

/* .openAssser button:first-child {
  margin-right: 20px;
} */


.fixedEuroSec input {
  border: 1px solid red;
}

@media screen and (min-width: 960px) and (max-width: 1012px) {
  .addFlowBtnSec a {
    margin: 5px 10px !important;
  }
}

.allTaskView h1{
  text-align: center;
  line-height: 20px;
}
.allOpenAsser{
  margin-right: 20px;
}
.allAddSpeclLbl{
  padding: 0px 28px 20px 28px;
}

 .MuiRadio-colorSecondary.Mui-checked.radioPat{
  color: #00ABAF;  
}
.MuiTypography-body1.radioPat2 .label{
  color: #404646;
  font-family: Muli;
  font-size: 12px!important;
  font-weight: 600!important;
  letter-spacing: 0;
  line-height: 12px;
  margin-bottom: 8px;
}
.radioPat1{
     margin-top: 27px
}

a.hoverTopCss span{
top: -37px!important;
width: 90px!important;
}

.taskHover1{
  margin-right: 0 !important;
}
.fltrClearIner2 {
 overflow-y: auto;
 padding: 14px 0px;
}

.darkTheme .grpah{
  color:white
}

.scndOptionInerPart ul li{
  margin-bottom: 0px;
}

@media screen and (max-width:399px) {
  .euroamount {
    position: absolute;
    top: 37px;
    left: 155px;
  }
}
@media screen and (min-width:400px) and (max-width:430px){
  .euroamount {
    position: absolute;
    top: 7px;
    left: 315px;
  }
}
.InfoOutLinees {
  color: red;
  font-size: 20px!important;
  margin-right: 4px;
}
 .InfoOutLinees:hover + .assignHoses.Paymentpending {
  display: block !important;
}
.InfoOutLinees:hover + .assignHoses.appointmentTime {
  display: block !important;
} 
.Paymentpending {
  color: red;
  background-color: #ffffff;
  text-align: center;
  padding: 7px;
  position: absolute;
  right: -20px;
  border-radius: 10px;
  width: 135px;
  border: 1px #cccc solid;
  z-index: 99999999;
}
.assignHoses.Paymentpending {
  display: none;
}
.darktheme .assignHoses .Paymentpending {
  background: #192020 !important;
}
.assignHoses {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-bottom: 10px !important;
}
.appointmentTime {
  color: red;
  background-color: #ffffff;
  padding: 7px;
  position: absolute;
  right: -20px;
  border-radius: 10px;
  width: 135px;
  border: 1px #cccc solid;
  z-index: 99999999;
}
.darkTheme .appointmentTime {
  background: #192020 !important;
  color:red!important
}
.darkTheme .Paymentpending {
  background: #192020 !important;
  color:red!important
}
.assignHoses.appointmentTime {
  display: none;
}
.allCertSec {
   display: flex;
   align-items: center;
   justify-content: flex-start;
 }
 .certificatePrice label {
   margin-bottom: 0px;
 }
 .wardsGrupUpr1 {
  padding:0px 10px;
 }
 .dtlCountRm1 {
  font-weight: 600;
 }
 .darkTheme {
  color:white
 }
 .MuiRadio-colorSecondary.Mui-checked.radioPat {
  color: #00ABAF;
 }
.radioPat1 .label {
  color: #404646;
  font-size: 12px;
  font-weight: 600s;
  font-family: Muli;
}
 .openAssser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }
 .openAssser button:first-child {
  margin-right: 0px;
 }
 .cstmSelect2 {
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: Muli;
}
.enterSpcl .cstmSelect2 div {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: initial !important;
  border-radius: 4px; 
}
.darkTheme .cstmSelect2 div {
  background-color: #192020 !important;
  color: #bfc1c1;
  box-shadow: initial !important;
  outline: none !important;
}
.fixedEuroSec input {
  border: 1px solid red;
}
.addSpeclContnt2 {
  width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 35px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 3px;
}
.darkTheme .addSpeclContnt2 {
  background: #404646;
}
.selectOptionCmn label#main-topic-counted,
.selectOptionCmn label#Chair-Bed-Transfers,
.selectOptionCmn label#Radio-Ambulation,
.selectOptionCmn label#Radio-Wheelchair,
.selectOptionCmn label#Radio-Stairs,
.selectOptionCmn label#Radio-OnOff,
.selectOptionCmn label#Radio-Bladder {
  font-size: 19px;
  color: #00abaf !important;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: Muli !important;
}
.selectOptionCmn .MuiRadio-colorSecondary.Mui-checked {
  color: #00abaf;
}
.selectOptionCmn {
  margin-top: 20px;
}
.selectOptionCmn label span {
  font-weight: 500;
  line-height: 21px;
  font-size: 15px;
  margin-right: 10px;
  font-family: Muli !important;
}
.selectOption {
  margin-bottom: 22px !important;
}
.wheelChr span {
  font-size: 13px;
  margin-left: 13px;
}
.selectOption .MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.selectOption span.MuiButtonBase-root.MuiIconButton-root {
  padding: 0px;
  margin-right: 8px;
}
.anamneSecUpr {
  padding: 0px 45px;
  margin-bottom: 25px;
}
.anamneSecMid p {
  font-size: 19px;
  color: #00abaf !important;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 12px;
  font-family: Muli !important;
}
.anamneSecDbl {
  margin-bottom: 6px;
}
.anamneSec .MuiRadio-colorSecondary.Mui-checked {
  color: #00abaf;
}
.MuiFormControl-root.careQuesCheck {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.anamneSec label {
  font-weight: 500;
  line-height: 21px;
  font-size: 15px;
  display: block;
  margin-bottom: 3px;
  font-family: Muli !important;
}
.anamneSec .MuiGrid-root {
  margin-bottom: 10px;
}
.anamneSec .MuiGrid-root .MuiGrid-root {
  margin-bottom: 8px;
}
.anamneSec input[type="text"] {
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 6px 12px;
  outline: none !important;
}
.anamneSec .MuiCheckbox-colorSecondary.Mui-checked {
  color: #00abaf !important;
}
.allInerTabs .MuiGrid-root .allTabCntnt {
  margin-bottom: 16px;
}
.allInerTabs .MuiGrid-root:last-child .allTabCntnt {
  margin-bottom: 0px;
}
.careQuesCheckTp .MuiRadio-colorSecondary.Mui-checked {
  color: #00abaf !important;
}
.darkTheme .anamneSec input[type="text"] {
  background: transparent !important;
}
.darkTheme .anamneSec label span.MuiIconButton-label {
  color: #00abaf !important;
}
.allFormSection {
  margin: 40px 40px;
  padding: 30px 30px;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
}
.wrapperdnd {
  padding: 0px !important;
}
.buttonStyle.fltrInput {
  margin-bottom: 0px;
  padding-top: 0px;
}
.newServc {
  text-align: right;
  margin-top: 0px;
  margin-bottom: 0px;
}
.TabContainerWeb .topLeftSpc {
  padding-left: 0px;
}
@media (max-width: 599px) {
  .addSpeclContnt2 {
    width: 96% !important;
  }
  .MuiFormControl-root.careQuesCheck {
    display: block;
    flex-direction: initial;
  }
  .allFormSection {
    margin: 20px 20px;
    padding: 25px 25px;
  }
  .attchNoteMain {
    justify-content: space-between;
  }
  .srvcTable {
    margin-bottom: 0px !important;
  }
  .enterSpcl input, .addStafSelect {
    margin-bottom: 2px !important;
  }
  .srvcActiv {
    text-align: center;
  }
  .acvtTogle {
    text-align: center !important;
  }
}
.measureInput input{    
  border: 1px solid #BFC1C1;
  border-radius: 4px;
  background-color: #FFFFFF;
  width: 100%;
  padding: 8px 9px;
  outline: none;
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
}

.darkTheme .measureInput input{
      background-color: transparent !important;
      color: #BFC1C1 !important;
    }
    
    .bloodPrseure label{
      font-weight: 500;
    line-height: 21px;
    font-size: 15px;
    display: block;
    margin-bottom: 3px;
    font-family: Muli !important;
    }
    

    .allQuestionShow{
      margin-left: 21px;
    }
    .allQuestionShow1{
      margin-bottom: 15px;
    }

    .allQuestionShow1 h1{
      margin-bottom: 5px;
    }
    
    .allQuestionShow1 p{
      color: #7F8383;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    }

    /* .creatTaskModel11{
      margin-top:21px!important
    } */

    .MainclassQues h1{
      margin-top:20px!important
    }

    .SetImagesOn img{
      margin-bottom: 15px;
      border-radius: 8px;
    }

    .MainclassQues1 h1{
      font-size: 16px;
      font-weight: bold;
      color: #00abaf;
    }
    .MainclassQues1 h3{
      margin-top: 0px;
      font-weight: bold;
      font-size: 15px;
      margin-top: 5px;
      font-family: Muli;
    }
    .MainclassQues1 label{
      margin-top: 0px;
      color: #7F8383;
      font-family: Muli;
      font-size: 14px;
      line-height: 16px;
    }
    .createLSet{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0px;
    }
    .createLSet label{
      margin-bottom: 0px;
    }
    .creatTaskCntnt1{
      padding-top: 0px;
    }    
    .homeBgDrk .MainclassQues h1{
      color: #F2F2F2 !important;
    }

    .homeBgDrk .FullInfoSet a{
      color: #F2F2F2 !important;
    }

    .homeBgDrk .MainclassQues1 h3{
      color: #F2F2F2 !important;
    }
    .homeBgDrk .stndQues1 h3, 
    .homeBgDrk .bp_graph.FullInfoSet a {
        color: #00B0DB !important;
    }
    .nurse-filter-sec {
      width: 203px;
      background-color: #01abaf;
      padding: 8px 4px;
      text-align: center;
      color: #ffffff;
      margin-right: 5px;
      cursor: pointer;
  }
  .totalamount label{
    color: #000808;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 12px;
    display: block;
  }
  .totalamount p{
    color: #000808;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .darkTheme .totalamount{
    margin-top: 10px;
  }
  .services-head{
    font-weight: bold;
  }
  .stndQues1 span{
    color:unset;
    margin-bottom: 0px;
  }
  .mainQueLab{
    line-height: 20px!important;
  }
  .creatTskCnt{
    padding:0px;
  }
  
  .downloadButton {
    margin-top: 10px;
    margin-left: 14px;
  }
  .showDetail .cancelAppoint a {
    margin-bottom: 0px;
  }
  .creatTaskModel.creatTaskModel11 .MuiGrid-root.creatLbl {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
  }
  .creatTaskModel.creatTaskModel11 {
    padding: 0px;
  }
  .darkTheme > .apontBoxCntnt .apontDragCntnt textarea {
    background-color: transparent;
  }
  .dataBoxUpr.patietnRegister .regCrtAc {
    margin-top: 12px;
    margin-bottom: 2px;
  }
  /* .dataBoxUpr.patietnRegister .registerRow {
    margin-bottom: 0px;
  } */
  .infoCourse .shPatientProfile .userDetail {
    margin-top: 0px;
  }
  .infoBoxCntnt .infoCourse .userAdresInfo { 
    margin-top: 12px;
  }
  .nwPresCntnt .nwPresCntntIner .infoShwHidBrdr2 {
    border-top: none;
    margin-bottom: 0px;
  }

  .allFormSection1
  {    margin: 40px 40px 0px 0px!important;
}


.nurseImagrProf img{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 4px 7px 0px 0px
}

.allFormSection1 h2 {
  font-size: 15px;
  font-family: Muli;
  font-weight: 700;
  color: #01abb0;
  margin: 5px 0px;
}
.allFormSection1 label {
  font-size: 13px;
  font-family: Muli;
  font-weight: 700;
  color: #7F8383;
  margin: 5px 0px 0px 0px;
}
.allFormSection1 p {
  font-size: 13px;
}

  .nurseImagrProf{
    border: 2px solid #00abaf;
    border-radius: 5px;
    margin: 1px 0px 18px 0px;
  }

  .nurseImagrProf1{
    padding: 9px;
  }

  .careQuestionTabUpr {
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 0 rgb(0 8 8 / 4%);
    padding: 20px 20px;
    margin: 34px 0px 30px;
  }
  .darkTheme .careQuestionTabUpr {
    background-color: #404646;
  }
  .RportCss h1{
    margin-bottom: 5px;
    font-family: Muli;
    font-size:15px;
  }
  .RportCss label{
   font-family: Muli;
    font-size:12px;
  }



  .darkTheme .stndQues span {
    color: unset;
  }
  .darkTheme .MainclassQues1 h1{
    color: #00abaf!important;
  }
  .darkTheme .recaddDocUpr {
    background-color: #303737 !important;
  }
  .taskSortDtr {
    position: initial !important;
  }
  .taskSortDtr input.TaskSearch {
    width: 230px;
  }
  .opinBoxCntnt .opinBoxCntntIner .infoShwHidBrdr {
    border: none !important;
    margin: 0px !important;
  }
  .opinBoxCntnt .opinBoxCntntIner .infoShwHidIner {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .opinBoxCntnt .opinBoxCntntIner .stndrdQues {
    margin-top: 5px;
  }
  .opinBoxModel .opinBoxCntnt {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .opinBoxCntnt .shrHlthMain .infoShwHidIner input.approvBtn, 
  .opinBoxCntnt .shrHlthMain .infoShwHidIner input.rejectBtn {
    margin-top: 5px;
  }
  .spcMgntRght7.presEditDot.scndOptionIner {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .allowAvailList .allowAvailListIner:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
  .nwPresModel .nwPresCntnt .nwPresCntntIner .MuiGrid-root.docHlthMain {
    padding-bottom: 0px;
  }
  .nwPresModel .nwPresCntnt .nwPresCntntIner .MuiGrid-root.infoShwHidIner2 {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .homeBgDrk .flag-select ul.flag-select__options {
    background: #192020 !important;
  }
  .homeBgDrk .flag-select__option__label {
    color: #FAFAFA !important;
  }
  .homeBgDrk .payment_sec_extra_ser .StripeElement {
    background: #00a7ab73;
    padding: 6px 10px;
  }
  .donarForm .OrganMobile .flag-select ul.flag-select__options {
    padding-top: 12px !important;
  }
  .docArundUpr .sbu_button button:first-child {
    margin-right: 0px !important;
  }
  .extraSrvcUpr .actvMain h2 {
    margin-bottom: 0px;
  }
  .extraSrvcUpr .actvMain {
    margin-bottom: 32px !important;
  }
.homeiconcolor{
  color:#7F8383;
  margin-bottom: 5px!important;
  font-size: 17px!important;
}

.checkHelth2{
  overflow-y: auto;
  height: 362px !important;
}
.creatTaskModel3{
  width: 580px;
  background: #fff;
  margin: 0 auto;
  border: none;
  outline: none;
  background-color: #ffffff;
  box-shadow: 0 24px 32px 0 rgb(0 8 8 / 8%);
  margin-top: 20px;
  padding-right: 2px;
  padding-top: 18px;
  padding-bottom: 16px;
  border-radius: 12px;
}
.darkTheme .creatTaskModel3 {
  background-color: #404646 !important;
}

@media (max-width: 768px) {
  .creatTaskModel3 {
    width: 98% !important;
  }
}
.selectOptionCmn label{
  color:grey;
}
/* .uuu label{
     color: black
} */
 .selectdcolchange label{
  color:#00abaf!important
}
.homeBgDrk .valuecolor label{
  color: white!important;
}
.darkTheme .yearSecBg:hover {
  background-color: #404646;
}
.scrollvalue {
  height: 537px;
  overflow-y: scroll;
}
.getAllCalSec .rbc-calendar{
  min-height: 1055px!important;
}
.profileIdRghtSec{
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width:959px) {
  .profileIdRghtSec{
    padding-top: 10px;
    padding-bottom: 8px;
  }
}
.profileIdRghtPart a {
  color: #00ABAF !important;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  cursor: pointer;
}
.profileIdRghtPart {
  text-align: right;
  margin-bottom: 14px;
}
.darkTheme .profileIdRghtPart a {
  color: #00ABAF;
}
@media(max-width: 959px){
  .profileIdRghtPart {
    justify-content: flex-start;
    text-align: left;
  }
}
.editCourse1 label{
  font-size: 16px;
  line-height: 25px;
}
@media (max-width: 959px){
.editCourse1 label {
  font-size: 14px !important;
  line-height: 20px !important;
}}

.homeBgDrk .allTimeSelnw .css-1uccc91-singleValue{
  margin-left: 4px!important;
}
.patientRegSec label{
  margin-bottom: 0px;
}
.passInstSec{
  top: -25px;
}

.profileIdRght{
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancelAppointSec{
  min-width: 185px;
}

@media screen and (max-width:959px) {
  .profileIdRght{
    padding-top: 10px;
    padding-bottom: 8px;
  }
}

.DetailHead label {
  color: #000808;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
  padding: 25px;
}
.homeBgDrk .DetailHead label{
  color : #fff;
}
.DetailHead {
    border-bottom: 1px solid #cfcfcf;
}
.DetailHead-close{
  padding: 25px;
}  

/* Added by Ankit */

.msgSectionCss {
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  margin-top: 13%;
  /* margin-left: 31%; */
  color: #696969;
  width: 100%;
}

label.formviewhead{
  font-weight: 700 !important;
}

.darkTheme .accessForm {
  background: #404646
}

.cssCall .css-1yrthcx {
     /* top: 95px!important; */
     left: 11px!important;
     width: 95%;
     border-radius: 8px;
     height: 556px;
     position: relative;
}

video-page {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  gap: 10px;
  width: 100%;
  height: 516px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), url(/video-confrance/static/media/frame.5fb7c2c4.png) normal);
  background-image: url(/video-confrance/static/media/frame.5fb7c2c4.png);
  border-radius: 12px;
  background-repeat: no-repeat, repeat;
}

.call-popup {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: absolute;
  width: 287px;
  left: calc(50% - 287px/2 + 0.5px);
  top: 254.66px;
  border: 1px solid #ddd;
  background: #FFFFFF;
  border-radius: 4px;
}

.call-pop-body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.darkTheme .call-pop-body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  color: #d9dada;
}

.call-img {
  width: 100px;
  border-radius: 8px;
  margin-right: 20px;
}

.inprogress-call {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px 6px;
  width: 84px;
  height: 22px;
  background: #FBD4D4;
  border-radius: 60px;
}

.inprogress-call span{
  color:#EE5253
}

.inprogress-call-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #EE5253;
}

.call-review-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #404646;
}

.call-feedback-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 45px 0px;
  padding: 45px 10px;
  border-bottom: #f1f1f1 1px solid;
  border-top: #f1f1f1 1px solid;
}

.call-comment-patient {
  padding-top: 13px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px !important;
  color: #404646 !important;
}

.comment-callpat h5 {
  font-size: 14px;
  color: #d9dada !important;
  font-weight: 700;
  line-height: 15px;
}

.comment-callpat p {
  padding-left: 12px;
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #00ABAF !important;
}

.call-pop-title {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.darkTheme .call-pop-title {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #d9dada;
}

.top-up {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00ABAF;

}

.call-end-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;
  width: 156px;
  background: #EE5253;
  border-radius: 8px;
}

.call-out-top-up {
  width: 50%;
  text-align: center;
  border-right: 1px solid #ddd;
  color: #00ABAF;
  font-weight: 600;
  padding: 20px;
}

.call-close-btn {
  width: 50%;
  text-align: center;
  color: #ee5253;
  font-weight: 600;
  padding: 20px;
}

.heading-status-call {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.css-ohwg9z{
  width: 100%;
}
.BannerGrid .gridDiv {
  width: 47%;
  margin-left: 1%;
  margin-right: 1%;
}

.option-call-move {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.call-feadback-header {
  display: flex;
  flex-direction: row;
  /* height: 100px; */
}

.darkTheme .settingPage h5 {
  color: #ffffff;
}

@media (max-width: 959px) {
  .settingPage {
    margin: 10px 10px 10px 10px;
    padding: 10px;
  }
}

.settingPage {
  margin: 10px 10px 10px 50px;
  padding: 10px;
}

.call-page-card {
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  background-color: #FFFFFF;
}

.MuiGrid-root.logForm.form_full .card-header {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 15px;
}

.darkTheme .form_full {
  background-color: #404646;
}

.darkTheme .form_full .card-header {
  background-color: #182020;
}

.darkTheme .form_full .card-content {
  background-color: #404646;
}

.darkTheme .form_full .card-section {
  background-color: #404646;
}

.profileImagePat img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

.timerIcon {
  color: red;
  margin-bottom: 11px;
}

.timerandLabel {
  position: fixed;
  top: 12px;
  /* right: 0; */
  left: 19px;
  z-index: 9999999999;
}

.timerandLabel label {
  margin-top: 5px;
  font-weight: bold;
  font-size: 20px;
  /* padding: 5px; */
  color: red;
  margin: 0px;
  margin-left: 5px;
}

.call-feedback-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: #f1f1f1 1px solid;
  border-top: #f1f1f1 1px solid;
  padding: 10px;
}
.darkTheme .call-feedback-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: #f1f1f1 1px solid;
  border-top: #f1f1f1 1px solid;
  color: #d9dada;
  padding: 10px;
}

.call-feedback-part .call-feadback-header{
  margin-top: 12px;
}

.allWebVideoSec {
  margin: 30px 6px 30px 10px;
}

@media (max-width: 959px) {
  .manageVideoCall .css-1yrthcx {
    width: 100% !important;
    position: unset !important;
    height: 100vh !important;
  }
  .allWebVideoSec {
    margin: 30px 15px 30px 15px;
  }
  .timerandLabel{
    position: absolute;
  }
}

.allWebVideoSec .MuiGrid-justify-xs-center {
  justify-content: flex-start !important;
}

.allSickVideoSec h3 {

  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 0px;
  color: #404646;
  margin-bottom: 8px;
  margin-top: 8px;
}

.allSickVideoSec label {
  font-weight: 700;
  color: #404646;
  margin-bottom: 0px;
}
.allSickVideoSec{
height: 524px;
overflow: auto;
height: 524px;
padding: 10px;
overflow: auto
}
.darkTheme .allSickVideoSec label, .darkTheme .allSickVideoSec h3{
  color:#00abaf!important;
}

.topSickVideoSec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.topTxtVideoSec {
  margin-left: 18px;
}

.allHeadSection {
  border: 1.5px solid #00abaf;
  border-radius: 10px;
  padding: 10px;
}

.BannerGrid .gridDiv {
  width: 47%;
  margin-left: 1%;
  margin-right: 1%;
}

.darkTheme .MuiPaper-rounded{
  background: #1c2226
}

.call-feadbackimg img{
  width: 37px !important;
  height: 37px !important;
  border-radius: 50%;
  margin-right: 4px;
}

.setDataSym{
  margin-top: 4px;
  margin-left: 7px;
  }

  .adhsjs{
    margin: 16px!important;
    line-height: 0px!important;
  }



.err_message1{
  text-align: center!important;
}
.getAllCalSec .rbc-calendar{
  min-height: 990px!important;
}
.menuItemsAll ul li:last-child:not(.doctor-menu) {
  margin-bottom: 0px !important;
  margin-top: 40px;
}
.addSpeclClose {
  text-align: right;
}
@media (max-width: 568px) {
 .addSpeclClose {
    margin-bottom: 10px;
  }
}
.collap{
  border: 1px #00ABAF solid;
  background: #e2e4e6;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}
.darkTheme .collap{
background: #647484 !important;
}
.manage-authority-btn {   
  background: #00abaf !important;
  margin: 10px 0px !important;
}
.manage-authority{
  font-weight: bold;
  cursor: pointer;
  color: #00ABAF !important;
}
.innerAuthority{
  margin-left: 35px;
}
.authoirty :hover {
  background: linear-gradient(33deg, aqua, transparent);
}
.authoirty .MuiTypography-body1{
  font-size: 0.9rem !important;
  font-weight: 600;
}
.sel{
  background: #04a5a9;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 1rem;
  padding-left: 8px;
  padding-right: 0px;
}
.formscrool {
  height: 537px;
  overflow-y: scroll;
}
.headsize2 {
  font-size: 15px;
  font-family: muli;
  margin-bottom: 20px;
}
.darkTheme .headsize2{
  background-color: #192020!important;
  color:#FFFFFF
}
.darkTheme .valuesuggest2{ 
  background-color: #404646!important;
  color:#FFFFFF
}
.authorityvalue { 
  font-size: 20px;
}
.allNewReqInfo{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}
.authority{
  font-size: 18px;
  font-family: muli;
  color: red;
  text-align: center;
  line-height: normal;
  margin-bottom: 8px;
}
.archvOpinLblSec{
  justify-content: flex-start !important;
  margin-left: 0px;
}
.enterSpclMainSec{
  padding-top: 20px;
}
.enterSpclSec input{
  margin-bottom: 0px;
}
.enterSpclSec .css-g1d714-ValueContainer{
  padding: 8px 8px!important;
}
.AddEntrynwSec a:last-child {
  margin-left: 6px;
}
.speServiceSec .css-g1d714-ValueContainer{
  height: 44px;
}
.dueOnSec{
  padding: 0px;
}
.assignSection{
  margin-top: 5px !important;
}
.checkboxquesSec{
  margin-top:0px !important;
  margin-bottom:15px !important;
}
.infoSubBtn{
  margin-top: 35px;
}
.allCheckboxQue{
  margin-top:15px!important;
} 
.stndQuesPart label{
  font-size: 15px;
  font-weight: bold; 
  color:#00abaf;
  letter-spacing: 0;
  font-family: Muli;
  line-height: 12px;
}
.stndQuesPart p{
  color: #000808;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 12px;
}
.stndQuesPart span{
  font-size: 18px;
  font-weight: bold;
  color:#00abaf;
  /* margin-bottom: 8px; */
}
.allStndQues{
  margin-top: 18px;
}
.darkTheme .stndQuesPart label{
  color:#00abaf!important;
}
.allMngAutSec{
  margin:10px 0px;
}
.allEnterSpclSec{
  padding-top: 12px;
}
.enterSpclSec input{
  min-height: 38px;
}
.serviceallSec1 input{
  margin-bottom: 0px;
}
@media(max-width: 959px){
  .allFormSection1{
    margin-left: 40px!important;
  }
}
.err_message1{
  text-align: center!important;
}
.react-confirm-alert-button-group1{
  justify-content: center !important;
}
.seletDrop{
  margin-top: 10px;
}
.darkTheme .error_message,
.homeBgDrk .error_message{
  color: #ff6262 !important;
}
.error_message{
  text-align: center;
  color: red !important;;
  font-weight: 600;
}
.service-head{
  padding-bottom: 12px;
  padding-Top: 0px
}
.addSpeclContntIner1{
  height: 472px;
  overflow: auto;
  padding-bottom: 18px;
}
.setEditDelBut{
  display: flex !important;
}
.addSrvcBtn3 h3{   
  font-size: 16px;
  font-weight: 500;
  color: #00abaf
}
.darkTheme .addSrvcBtn3 h3{   
  font-size: 16px!important;
  font-weight: 500!important;
  color: #00abaf!important;
}
.specbutton1 label{
  font-size: 16px;
  font-weight: 500;
  color: #00abaf
}
.darkTheme .specbutton1 label{   
  font-size: 16px!important;
  font-weight: 500!important;
  color: #00abaf!important;
}
.addSpeclabcIner{
  overflow: auto;
  height: 510px;
}
.setFilterCss{
  margin-left: 34px;
  font-size: 10px;
}
.services-head th {    
  color: #7f8383;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 12px;
  padding-bottom: 12px;
}
.presImg11 img {
  border-radius: 27px;
  height: 25px;
  width: 25px;
  margin: 2px 5px 7px 9px;
}  
.AddMarginTo{
  margin-bottom: 10px
}
.srvcTable3656 th, .srvcTable3656 td{
  padding: 30px 24px;
}
.darkTheme .srvcTable365
{background: #192020}

.srvcTable365{
  overflow: auto;
}
.creatLbl22 label{
  font-size: 17px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  /* line-height: 32px!important; */
  font-family: "Mulish", sans-serif !important;
  margin-top: 10px !important;
}
.tasklistName1{
  margin-top: 12px;
}
.tasklistName1 img{
  margin-right: 3px;
}
.allStaffRghtSec{
  margin-left: 10px;
}
.addStaffPart{
  min-height:537px;
} 

.tskSrvsPart {
  display: inline-flex;
  margin-bottom: 10px;
}

.allCreatInfo {
  margin: 15px 0px !important;
}

.allCustomService p {
  color: #000808;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  font-family: Muli;
  display: block;
}
.allCustomService p:nth-child(2){
  margin-left: 5px;
}
.allCustomService label{
  /* margin-top:5px; */
  font-weight: 700;
  font-size: 13px;
  margin-bottom:0px;
}

.allThrapySec {
  text-align: right;
}

.allThrapySec label {
  font-weight: bold;
  color: #00abaf;
}

.addCssTherNamDes{
  padding-bottom: 15px;
  color: #7f8383;
  font-weight: 700;
}

.noNameType {
  width: 40%;
  text-align: left;
}

.noNameType1 {
  width: 20%;
  text-align: left;
}


.imgEditDelPer {
  padding: 7px;
  cursor: pointer
}


.noNameTypeEditDel1 {
  width: 20%;
  text-align: left
}

.noNameTypeEditDel2 {
  width: 30%;
  text-align: left
}

.noNameTypeEditDel3 {
  width: 40%;
  text-align: left
}

.noNameTypeEditDel4 {
  width: 10%;
  text-align: left
}



.noNameTypeEditDel5 {
  max-width: 30px
}

.noNameTypeEditDel6 {
  max-width: 50px
}

.noNameTypeEditDel7 {
  max-width: 100px
}

.noNameTypeEditDel8 {
  max-width: 40px
}


.headService11 {
  padding: 30px;
  padding-Top: 0px
}

.roomsNum3345 {
  padding-Left: 30px;
  padding-Right: 30px
}



.allEnterSpclPart label {
  font-size: 15px;
  margin-bottom: 5px;
  color: #00abaf;
  font-weight: bold;
}

.specbuttonPart {
  margin-top: 30px;
}

.darkTheme .allEnterSpclPart label {
  color: #00abaf !important;
  font-weight: bold !important;
}

.darkTheme .addCssTherNamDes {
  color: #fafafa;
}


@media only screen and (min-width: 960px) and (max-width: 1000px) {
  .addCssForTherapy {
    padding: 8px;
    margin-right: 5px;
  }
}

.allEnterSpclPart label{
  font-size: 15px;
  margin-bottom:5px;
  color: #00abaf;
  font-weight: bold;
}
.specbuttonPart{
  margin-top: 30px;
}                 
.darkTheme .allEnterSpclPart label{
  color: #00abaf!important;
  font-weight: bold!important;
}
.darkTheme .addCssTherNamDes{
  color: #fafafa;
}
.serAmntQunty{
  margin-top: 20px;
}
.allCustomService p span {
  color: #00abaf;
  font-size: 15px;
  line-height: 25px;
}
.tskSrvsPart p{
  color: #00abaf;
}
 @media only screen and (min-width: 960px) and (max-width: 1000px){
  .addCssForTherapy{
    padding: 8px;
    margin-right: 5px;
  }
}

.enterSpclSec1 label{
  margin-bottom: 0px;
}
@media screen and (max-width:600px) {
  .addTimeTaskSec button{
    width:100%;
  }
}
.selectapprove {
  width: 50%;
  margin: auto;
}

                
.edtdelservice img {
  width: 20px;
}
.edtdelservice img:first-child{
  margin-right: 5px;
}
.editDelSerBtn{
  display:flex;
  align-items: center;
  justify-content: center;
}
.clearFilterRghtSec a {
  height: 32px;
}
.clearFilterRghtSec img{
  height: 20px;
}

@media (max-width: 623px){ 
  .changeLang a.dropdown-toggle.nav-link {
    padding: 0.5rem 1rem !important;
    width: initial !important;
  }
  .changeLang li.dropdown.nav-item:last-child {
    margin-right: 0px !important;
  }
  .changeLang .react-toggle-track {
    width: 34px !important;
    height: 18px !important;
  }
  .changeLang .react-toggle-thumb {
    width: 16px !important;
    height: 16px !important;
  }
}


.allInfoRght2 label {
  color:#000;
  font-weight: 600;
  font-size: 12px;
  font-family: Muli;
}
/* .margindatright{
margin-left: 30px;
} */
.spcaetime{
  margin-top: 20px;
}


.setPointer {
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .tooltip-container {
    width: 0px !important;
  }
}
@media screen and (max-width: 640px) {
  .scndOptionIner ul {
    margin-top: 14px !important;
    /* right: auto !important; */
}
}

@media(max-width:960px){
  .academy_ul.stepTdotupper ul{
    height: 250px;
  }
}
.setAssignedPart span{
  top:-84px;
  left: -30px;
}

@media(max-width:384px){
  .fatiqueQuesSec .MuiGrid-grid-md-4{
    text-align: left !important;
  }
  .fatiqueQuesSec a{
    margin-top: 5px;
  }
}

.enterPartprice{
  position: absolute;
  top: 14px;
  right: 30px;
  color: #404646;
  font-weight: 600; 
}

.billingHeadSec p{
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
  font-family: Muli;
  color: #000808;
  font-weight: bold;
}
.darkTheme .billingHeadSec p{
  color: #fafafa;
}
.billLbl label {
  color: #00b0db;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: capitalize;
}
.darkTheme .billLbl label{
  color: #00abaf!important;
}
@media screen and (min-width: 960px) and (max-width: 1280px) {
  .billingHeadSec{
    display: none;
  }
}

.NeuroBtn123{
  margin-top: 2px!important;
}

a.dtlCountEmail{
  display: flex;
}

.dtlCount123 a{
 line-break: anywhere!important;
}

 .setstaffleft div{
  display: flex!important;
  justify-content: left!important;
}

.forSameLineIcon{
  display :flex;
}

.forSameLineIcon img{
  padding: 10px 2px;
}
@media screen and (min-width:960px) and (max-width:1200px){
  .attchNotePart{
    display: block;
  }
  .attchOpen, .attchDone{
    margin:5px 0px;
    padding: 0px;
  }
}


@media(max-width:960px){
.allTimeSlotSec{
  border:none;
  width:100%!important;
  display:inline-block;
}
.midTimeSlotSec{
  border:1px solid #BFC1C1;
  border-radius: 4px;
  margin: 8px;
}
}
.setAssignedToA span{
top: -60px !important;
}

.setAssignedToB span{
top: -18px!important;
left: -75px!important;
}

.setAssignedToC span{
top: -35px!important;
left: -38px!important;
}



.RegisterScrool {
  height: 537px;
  overflow-y: scroll;
}
@media (max-width: 710px){
 .slotBoxMain {
    width: 96%!important;
}
}