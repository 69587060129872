@import "../../../resources/mixins";
@import "../../../resources/colors";

.ccl-left-panel-footer-wrap {
  width: 100%;
  z-index: 1;

  .ccl-left-panel-nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ccl-left-panel-nav-listitem {
      display: inline-block;
      padding: 10px;

      .ccl-left-panel-nav-link {
        width: 32px;
        height: 32px;
        display: inline-block;
      }

      .ccl-left-panel-nav-link.people {
        background: url(./resources/people-grey-icon.svg) center center / 18px
          19px no-repeat;
        cursor: pointer;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/people-blue-icon.svg) center center / 18px
          19px no-repeat;
      }

      .ccl-left-panel-nav-link.call {
        background: url(./resources/call-grey-icon.svg) center center / 18px
          19px no-repeat;
        cursor: pointer;
      }
      .ccl-left-panel-nav-link.call.active {
        background: url(./resources/call-blue-icon.svg) center center / 18px
          19px no-repeat;
      }

      .ccl-left-panel-nav-link.chat {
        background: url(./resources/chat-grey-icon.svg) center center / 20px
          21px no-repeat;
        cursor: pointer;
      }
      .ccl-left-panel-nav-link.chat.active {
        background: url(./resources/chat-blue-icon.svg) center center / 20px
          21px no-repeat;
      }

      .ccl-left-panel-nav-link.grp-chat {
        background: url(./resources/group-chat-grey-icon.svg) center center /
          20px 21px no-repeat;
        cursor: pointer;
      }
      .ccl-left-panel-nav-link.grp-chat.active {
        background: url(./resources/group-chat-blue-icon.svg) center center /
          20px 21px no-repeat;
      }

      .ccl-left-panel-nav-link.more {
        background: url(./resources/more-grey-icon.svg) center center / 20px
          21px no-repeat;
        cursor: pointer;
      }
      .ccl-left-panel-nav-link.more.active {
        background: url(./resources/more-blue-icon.svg) center center / 20px
          21px no-repeat;
      }

      .ccl-left-panel-nav-link.chat {
        background: url(./resources/chat-grey-icon.svg) center center / 20px
          21px no-repeat;
        cursor: pointer;
      }
      .ccl-left-panel-nav-link.chat.active {
        background: url(./resources/chat-blue-icon.svg) center center / 20px
          21px no-repeat;
      }
    }
  }
}

.dark {
  .ccl-left-panel-footer-wrap {
    background-color: $dark-background;
  }

  .ccl-left-panel-nav-list {
    .ccl-left-panel-nav-listitem {
      .ccl-left-panel-nav-link.people {
        background: url(./resources/people-light-grey-icon.svg) center center
          no-repeat;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/people-blue-icon.svg) center center
          no-repeat;
      }

      .ccl-left-panel-nav-link.call {
        background: url(./resources/call-light-grey-icon.svg) center center
          no-repeat;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/call-blue-icon.svg) center center no-repeat;
      }

      .ccl-left-panel-nav-link.chat {
        background: url(./resources/chat-light-grey-icon.svg) center center
          no-repeat;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/chat-blue-icon.svg) center center no-repeat;
      }

      .ccl-left-panel-nav-link.grp-chat {
        background: url(./resources/group-chat-light-grey-icon.svg) center
          center no-repeat;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/group-chat-blue-icon.svg) center center
          no-repeat;
      }

      .ccl-left-panel-nav-link.more {
        background: url(./resources/more-light-grey-icon.svg) center center
          no-repeat;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/more-blue-icon.svg) center center no-repeat;
      }

      .ccl-left-panel-nav-link.chat {
        background: url(./resources/chat-light-grey-icon.svg) center center /
          20px 21px no-repeat;
      }
      .ccl-left-panel-nav-link.people.active {
        background: url(./resources/chat-blue-icon.svg) center center / 20px
          21px no-repeat;
      }
    }
  }
}
